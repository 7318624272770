import {Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PostMissionService } from 'src/app/services/post-mission.service';
import {SavedPage} from '../../models/storage/SavedPage.model';
import {StackService} from '../../services/storage/stack.service';
import {MissionTypeService} from '../../services/mission-type.service';
@Component({
  selector: 'app-interface-creation',
  templateUrl: './interface-creation.component.html',
  styleUrls: ['./interface-creation.component.css']
})
export class InterfaceCreationComponent implements OnInit, OnDestroy {
  formMission: FormGroup;
  uploadData: FormData;
  private returnClicked: boolean;
  error: string;

  constructor(private cd: ChangeDetectorRef, private missionTypeService: MissionTypeService, private router: Router, private stackService: StackService) {
    this.returnClicked = false;
  }

  ngOnInit() {
    this.formMission = new FormGroup(
      {
        libelleMissionType: new FormControl('', [Validators.required])
      }
    );
    this.uploadData = new FormData();

  }

  onSubmit() {
    if (this.formMission.valid) {
      const jsonBody = {};
      jsonBody['libelleMissionType'] = this.formMission.value.libelleMissionType;
      jsonBody['dateDebut'] = this.formatDate(new Date());
      this.missionTypeService.postWithBody(jsonBody).toPromise().then(() => {
        localStorage.removeItem('missionsTypes');
        this.router.navigate(['/mission']);
      }, err => {
        this.error = 'Impossible élement déjà existant';
      });
    } else {
      this.error = 'L\'intitulé de la mission type est requis.';
    }
  }

  formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
  ngOnDestroy(): void {
    if (!this.returnClicked) {
//        this.stackService.push(new SavedPage(null, null, '/creationMissionType', null));
    }
  }

  handleLastPage() {
    this.returnClicked = true;
    const savedPage = this.stackService.peek();
    if (savedPage !== undefined) {
      if (savedPage.lastPage !== this.router.url) {
        const path = savedPage.lastPage.slice();
        this.stackService.pop();
        this.router.navigate([path]);
      } else {
        this.stackService.pop();
      }
    }
  }
}
