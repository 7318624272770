import { MissionType } from './MissionType.model';
import { MissionTypePossible } from './MissionTypePossible.model';
import { UO } from './UO.model';
export enum Page {
  UO,
  mission,
  missionPossible
}

export class TeamUO {
  name: string;
  date: string;
  constructor(name: string, date: string) {
    this.name = name;
    this.date = date;
  }
}

export class InfoUO {
  name: string;
  type: string;
  addr: string;
  tel: string;

  constructor(name: string,
    type: string,
    addr: string,
    tel: string) {
    this.name = name;
    this.type = type;
    this.addr = addr;
    this.tel = tel;
  }
}

export class DataUO {
  erreurChargement: string;
  idx: number;
  tab: UO[];
  tabFiltre: UO[];
  constructor() {
    this.erreurChargement = null;
    this.tab = [];
    this.tabFiltre = [];
    this.idx = -1;
  }
  getTab() {
    return this.tab;
  }
}

export class DataMission {
  idx: number;
  tab: MissionType[];
  tabFiltre: MissionType[];
  constructor() {
    this.tab = [];
    this.tabFiltre = [];
    this.idx = -1;
  }
}
export class DataMissionPossible {
  idx: number;
  tab: MissionTypePossible[];
  tabFiltre: MissionTypePossible[];
  constructor() {
    this.tab = [];
    this.tabFiltre = [];
    this.idx = -1;
  }
}

