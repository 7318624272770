import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { TypologieUO } from '../models/TypologieUO.model';
import { UO } from '../models/UO.model';

@Injectable({
  providedIn: 'root'
})
export class InformationUOService extends GetAndPost {
  typologies: TypologieUO[] = [];

  constructor(httpClient: HttpClient) {
    super('uo/infos', httpClient);
  }

  newElement(e: any): any {
    if (e !== null) {
      this.typologies = [];
      e.typologies.forEach(element => {
        this.typologies.push(new TypologieUO(element.idTypologieUo, element.libelleCourtTUO, element.libelleLongTUO, element.dateDebutTUO, element.dateFinTUO));
      });
      return new UO(e.idUniteOrganisationnelle, e.nomCourt, e.nomLong, e.idStatus, e.libelleStatus, this.typologies, e.dateDebut, e.siret, e.dateFin, e.estActive, e.statutValidation);
    }
  }
}
