import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { Adresse } from '../models/Adresse.model';
@Injectable({
  providedIn: 'root'
})
export class AdresseService extends GetAndPost {

  constructor(httpClient: HttpClient) {
    super('adresse', httpClient);
  }
  newElement(e: any): any {
    if (e === null) {
      return new Adresse(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
      );
    }
    return new Adresse(
        e.libelleCourtTypologieAdresse,
        e.libelleLongTypologieAdresse,
        e.dateDebut,
        e.idLocalisation,
        e.valide,
        e.dateDebutUOA,
        e.dateFinUOA,
        e.dateDebutTA,
        e.DateFinTA,
        e.ApptEscalier,
        e.ComplementGeographique,
        e.NumVoie,
        e.CodePostal,
        e.Ville,
        e.dateFin,
        e.estConnecte,
        e.nbOrdinateur,
        e.accesHandicape,
        e.pointEau,
        e.wc,
        e.localPartage,
        e.localPrecaire,
        e.localTemporaire,
        e.surfaceTotale,
        e.dateDebutAssurance,
        e.dateFinAssurance,
        e.dateEmmenagement,
        e.dateDemenagement
    );
  }
}
