import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { MissionType } from '../models/MissionType.model';
@Injectable({
  providedIn: 'root'
})
export class MissionTypeService extends GetAndPost {

  constructor(httpClient: HttpClient) {
    super('missionType', httpClient);
  }
  newElement(e: any): any {
    return new MissionType(e.idMissionType, e.libelleMissionType, e.dateDebut, e.dateFin);
  }

  putMissionType(idMissionType: string, libelleMisionType: string, dateDebut: string, dateFin: string) {
    this.loadedData = [];
    let url = this.baseUrl + this.service + "?" + "idMissionType" + "=" + idMissionType;
    if (libelleMisionType) {
      url = url + "&" + "libelleMisionType" + "=" + libelleMisionType;
    }
    if (dateDebut) {
      url = url + "&" + "dateDebut" + "=" + dateDebut;
    }
    if (dateFin) {
      url = url + "&" + "dateFin" + "=" + dateFin;
    }
    return this.httpClient.put<any[]>(url, this.loadedData);
  }
}
