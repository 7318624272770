import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { TypologieUO } from '../models/TypologieUO.model';
import { UO } from '../models/UO.model';
import { ParentUo } from '../models/ParentUo.model';
import {map, share} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UoParentService extends GetAndPost {
  typologies: TypologieUO[] = [];

  constructor(httpClient: HttpClient) {
    super('uo/parent', httpClient);
  }

  newElement(e: any): any {
    if (e === null) { return new ParentUo(null, null, null, null, null, null); }
    return new ParentUo(e.idUO, e.nomLong, e.typeRelation, e.nomCourt, e.typo, e.idUoUo, e.dateFinOtherUO);
  }


  getActiveWithId(id: number) {
    const url = this.baseUrl + this.service + '/' + id + '?active=true';
    this.getDatas(url);
  }
}
