<div class="tableauRecherche">
  <!-- <div class="rechercher">
    <input type="text" placeholder="Rechercher" class="rechercheBar" value={{rechercherValue}} (keypressed)="
      search($event)" (focusout)="setRecherche($event)" />
    <button name="suppressButton" class="supprimer" (click)="supprimerFiltre()"><span class=" fa fa-times" [ngStyle]="{'visibility': rechercherValue === '' ? 'hidden' : 'visible' }"></span></button>
    <button name="rechercherButton" class="filtrer" (click)="doRecherche()"><span class="fa fa-search"></span>
      rechercher</button>
  </div> -->
  <div class="rechercher">
    <div *ngIf="isExistPage(); then thenBlock else elseIf"></div>
      <ng-template #thenBlock>
        <button class="btn btn-info mr-sm-2" (click)="handleLastPage()">
            <span class="fas fa-chevron-left"></span> Retour
        </button>
      </ng-template>
      <ng-template #elseIf>
        <ng-container *ngIf="!isExistPage() && rechercherValue!=='';else elseBlock">
          <button class="btn btn-info mr-sm-2" (click)="return()">
            <span class="fas fa-chevron-left"></span> Retour
        </button>
        </ng-container>
      </ng-template>
      <ng-template #elseBlock>
        <button class="btn btn-info mr-sm-2" disabled  (click)="handleLastPage()">
            <span class="fas fa-chevron-left"></span> Retour
        </button>
      </ng-template>
    <!-- 06/2020 - Blocage temporaire de la fct Recherche
    <div class="supprimer"></div>
    <input type="text" placeholder="Rechercher" id="searchField" class="form-control mr-sm-2"  
    (keypressed)="search($event)" (focusout)="setRecherche($event)"  value={{rechercherValue}}/>
    <button name="suppressButton" class="supprimer" (click)="supprimerFiltre()">
      <span class=" fa fa-times" [ngStyle]="{
          visibility: rechercherValue === '' ? 'hidden' : 'visible'
        }"></span>
    </button>
    <button name="rechercherButton" class="btn btn-primary" (click)="doRecherche()">
      Rechercher
    </button>
    <div class="CheckInactiveUO">
      <input type="checkbox" class="form-check-input" (change) = "ChangeUOInactiveVisibilite($event)"/>
      <label class="form-check-label">UO inactives</label>
    </div>
    -->
  </div>
  <!--
  <ngx-ui-loader [fgsColor]="config.fgsColor" [fgsPosition]="config.fgsPosition" [fgsSize]="config.fgsSize"
    [fgsType]="config.fgsType" [bgsColor]="config.bgsColor" [bgsOpacity]="config.bgsOpacity"
    [bgsPosition]="config.bgsPosition" [bgsSize]="config.bgsSize" [bgsType]="config.bgsType" [gap]="config.gap"
    [logoPosition]="config.logoPosition" [logoSize]="config.logoSize" [logoUrl]="config.logoUrl"
    [overlayColor]="config.overlayColor" [pbColor]="config.pbColor" [pbDirection]="config.pbDirection"
    [pbThickness]="config.pbThickness" [hasProgressBar]="config.hasProgressBar" [text]="config.text"
    [textColor]="config.textColor" [textPosition]="config.textPosition"></ngx-ui-loader>
-->
  <div class="tableau">
    <div>
      <!--
      <ng-container *ngIf="page==Page.UO">
        <div class="d-flex justify-content-center">
          <pagination-controls (pageChange)="pageChanged($event,'uo')" id="uo"></pagination-controls>
        </div>
      </ng-container>
      <ng-container *ngIf="page==Page.mission">
        <div class="d-flex justify-content-center">
          <pagination-controls (pageChange)="pageChanged($event,'mission')" id="mission"></pagination-controls>
        </div>
      </ng-container>
      <ng-container *ngIf="page==Page.missionPossible">
        <div class="d-flex justify-content-center">
          <pagination-controls (pageChange)="pageChanged($event,'missionPossible')" id="missionPossible"></pagination-controls>
        </div>
      </ng-container>
      -->
      <table class="fixed_headers">
        <!-- header of our page -->
        <thead *ngIf="page == Page.UO">
          <tr id="entete">
            <!-- 06/2020 - Blocage temporaire des fonctions de Tri sur tableau UO
            <th (click)="sortByIdUO()" [ngClass]="{ sorted: clickPairIdUO || lastSort === 'IDUO' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairIdUO,
                  'fa-angle-up': !clickPairIdUO
                }">
              </span> ID
            </th>
            <th (click)="sortByTypoUO()" [ngClass]="{ sorted: clickTypoUO || lastSort === 'TYPOUO' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickTypoUO,
                  'fa-angle-up': !clickTypoUO
                }">
              </span> Type
            </th>
            <th (click)="sortByNomCourtUO()" [ngClass]="{
                sorted: clickPairNomCourtUO || lastSort === 'NOMCOURTUO'
              }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairNomCourtUO,
                  'fa-angle-up': !clickPairNomCourtUO
                }">
              </span> Nom court
            </th>
            <th (click)="sortByNomLongUO()" [ngClass]="{
                sorted: clickPairNomLongUO || lastSort === 'NOMLONGUO'
              }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairNomLongUO,
                  'fa-angle-up': !clickPairNomLongUO
                }">
              </span> Nom long
            </th>
            -->
            <th>ID</th><th>Type</th><th>Nom court</th><th>Nom Long</th>
          </tr>
        </thead>
        <thead *ngIf="page == Page.mission">
          <tr>
            <th (click)="sortByIdMT()" [ngClass]="{ sorted: clickPairIdMT || lastSort === 'IDMT' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairIdMT,
                  'fa-angle-up': !clickPairIdMT
                }">
              </span> ID
            </th>
            <th (click)="sortByMTNom()" [ngClass]="{ sorted: clickPairMTNom || lastSort === 'MTNOM' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairMTNom,
                  'fa-angle-up': !clickPairMTNom
                }">
              </span> Nom
            </th>
            <th (click)="sortByMTDD()" [ngClass]="{ sorted: clickPairMTDD || lastSort === 'MTDD' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairMTDD,
                  'fa-angle-up': !clickPairMTDD
                }">
              </span> Début
            </th>
            <th (click)="sortByMTDF()" [ngClass]="{ sorted: clickPairMTDF || lastSort === 'MTDF' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairMTDF,
                  'fa-angle-up': !clickPairMTDF
                }">
              </span> Fin
            </th>
          </tr>
        </thead>
        <thead *ngIf="page == Page.missionPossible">
          <tr>
            <th (click)="sortByID()" [ngClass]="{ sorted: clickPairId || lastSort === 'ID' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairId,
                  'fa-angle-up': !clickPairId
                }">
              </span> ID
            </th>
            <th (click)="sortByMP()" [ngClass]="{ sorted: clickPairMP || lastSort === 'MP' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairMP,
                  'fa-angle-up': !clickPairMP
                }">
              </span> Mission Possible
            </th>
            <th (click)="sortByMT()" [ngClass]="{ sorted: clickPairMT || lastSort === 'MT' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairMT,
                  'fa-angle-up': !clickPairMT
                }">
              </span> Mission Type
            </th>
            <th (click)="sortByTypology()" [ngClass]="{
                sorted: clickPairTypology || lastSort === 'TYPOLOGY'
              }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairTypology,
                  'fa-angle-up': !clickPairTypology
                }">
              </span> Typologie UO
            </th>
            <th (click)="sortByActivite()" [ngClass]="{
                sorted: clickPairActivite || lastSort === 'ACTIVITE'
              }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairActivite,
                  'fa-angle-up': !clickPairActivite
                }">
              </span> Activité
            </th>
            <th (click)="sortByDD()" [ngClass]="{ sorted: clickPairDD || lastSort === 'DD' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairDD,
                  'fa-angle-up': !clickPairDD
                }"></span> Début
            </th>
            <th (click)="sortByMPDF()" [ngClass]="{ sorted: clickPairDF || lastSort === 'DF' }">
              <span [ngClass]="{
                  fa: true,
                  'fa-angle-down': clickPairDF,
                  'fa-angle-up': !clickPairDF
                }">
              </span> Fin
            </th>
          </tr>
        </thead>

        <!-- body of our page -->

        <tbody *ngIf="page == Page.UO">
          <!--  | paginate: this.configPaginationUO -->
          <tr *ngFor="let item of dataUO.tabFiltre; index as i" (click)="onClicTab(i)" [class.active]="i == dataUO.idx"
            id="idUO{{ item.idUniteOrganisationnelle }}"  [ngClass]="{
              isOdd: i % 2 == 1,
              invalidDate: checkEndDate(item.dateFin)
            }">

        <ng-container >
        <td>{{ item.idUniteOrganisationnelle }}</td>
              <td>{{ item.typologies[0].libelleCourtTUO }}</td>
              <td>{{ item.nomCourt }}</td>
              <td>{{ item.nomLong }}</td>
            </ng-container>
          </tr>
        </tbody>
        <tbody *ngIf="page == Page.mission">
          <!-- | paginate: this.configPaginationM; -->
          <tr *ngFor="let mission of dataMission.tabFiltre; index as i" (click)="onClicTab(i)"
            id="idM{{ mission.idMissionType }}" [class.active]="i == dataMission.idx" [ngClass]="{
              isOdd: i % 2 == 1,
              invalidDate: checkEndDate(mission.dateFin)
            }">
            <!-- [ngClass] = {"invalidDate" : checkEndDate(mission.dateFin) }  -->
            <td>{{ mission.idMissionType }}</td>
            <td>{{ mission.libelleMissionType }}</td>
            <td>{{ mission.dateDebut | date: "dd/MM/yyyy" }}</td>
            <td>{{ mission.dateFin | date: "dd/MM/yyyy" }}</td>
          </tr>
        </tbody>

        <tbody *ngIf="page == Page.missionPossible">
          <!-- | paginate: this.configPaginationMP;  -->
          <tr *ngFor="let mission of dataMissionPossible.tabFiltre; index as i" (click)="onClicTab(i)"
            id="idMP{{ mission.idMissionPossible }}" [class.active]="i == dataMissionPossible.idx" [ngClass]="{
              isOdd: i % 2 == 1,
              invalidDate: checkEndDate(mission.dateFin)
            }">
            <td>{{ mission.idMissionPossible }}</td>
            <td>{{ mission.libelleMissionPossible }}</td>
            <td>{{ mission.libelleMissionType }}</td>
            <td>{{ mission.libelleTypologieUOCourt }}</td>
            <td>{{ mission.libelleActivite }}</td>
            <td>{{ mission.dateDebut | date: "dd/MM/yyyy" }}</td>
            <td>{{ mission.dateFin | date: "dd/MM/yyyy" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
