import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { TypologieUO } from '../models/TypologieUO.model';

@Injectable({
  providedIn: 'root'
})
export class TypologieUoService extends GetAndPost {
  /**
   * Constructor of our service
   * @param httpClient
   */
  constructor(httpClient: HttpClient) {
    super('TypologieUO', httpClient);
  }

  /**
   * Methode qui sert à ??????????
   * @param e
   */
  newElement(e: any) {
    if (e === null) {
      return new TypologieUO(
      null,
      null,
      null,
      null,
      null);
    }
      return new TypologieUO(
        e.idTypologieUO,
        e.libelleCourt,
        e.libelleLong,
        e.dateDebut,
        e.dateFin);
  }
}
