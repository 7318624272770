import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import {ConnexionDroitService} from '../../../services/connexion/connexion-droit.service';
import { ToastService } from 'src/app/services/toast.service';
import { ERoles } from 'src/app/models/ERoles.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  private toastConfig: any = {};

  constructor(private router: Router,
    private connexionDroitService: ConnexionDroitService,
    private toastService: ToastService) { }

  ngOnInit() {
        // Toast configuration
        this.toastConfig = {
          classname: 'bg-warning text-light',
          autohide: true,
          headertext: 'Alert',
          delay: 3000
        };
  }

  isActive(lien: string) {
    return this.router.url.startsWith ('/' + lien + '/') || this.router.url === '/' + lien;
  }

  isRefVisible(lien: string) {
    const roles = localStorage.getItem('roles').split(',');
    switch (lien) {
      case 'mission':
        return(roles != null && (roles.some( ro => [ERoles.REF_MISSION_RW.toString()].includes(ro)))) ||
        this.connexionDroitService.isAdmin();
      case 'missionPossible':
        return(roles != null && (roles.some( ro => [ERoles.REF_MISSION_RW.toString()].includes(ro)))) ||
        this.connexionDroitService.isAdmin();
      case 'uo':
          return(roles != null && (roles.some( ro => [ERoles.REF_UO_RO, ERoles.REF_UO_RW.toString()].includes(ro)))) ||
        this.connexionDroitService.isAdmin();
      case 'referentiel' :
      case 'demande':
        return this.connexionDroitService.isAdmin();
    }
  }


  missionIsActive(lien: string) {
    const roles = localStorage.getItem('roles').split(',');
    return ( (roles.includes(ERoles.REF_MISSION_RW) && ['ALL', 'AD', 'OTHER'].includes(this.connexionDroitService.getTypeUO()))
    || this.connexionDroitService.isAdmin() );
  }

  noRight() {
     this.toastService.show('Vous ne disposez pas des droits pour accéder à cette partie', this.toastConfig);
  }
}
