<!--<ngx-ui-loader [fgsColor]="config.fgsColor" [fgsPosition]="config.fgsPosition" [fgsSize]="config.fgsSize"
               [fgsType]="config.fgsType" [bgsColor]="config.bgsColor" [bgsOpacity]="config.bgsOpacity"
               [bgsPosition]="config.bgsPosition" [bgsSize]="config.bgsSize" [bgsType]="config.bgsType" [gap]="config.gap"
               [logoPosition]="config.logoPosition" [logoSize]="config.logoSize" [logoUrl]="config.logoUrl"
               [overlayColor]="config.overlayColor" [pbColor]="config.pbColor" [pbDirection]="config.pbDirection"
               [pbThickness]="config.pbThickness" [hasProgressBar]="config.hasProgressBar" [text]="config.text"
               [textColor]="config.textColor" [textPosition]="config.textPosition"></ngx-ui-loader>-->

<div class="detailsPanel" ngxUiLoaderBlurred>
    <div class="createBox">
        <div *ngIf="page==Page.missionPossible">
            <button class="btn btn-outline-success" routerLink="/creationMissionPossible" routerLinkActive="on"><span
                    class="fa fa-plus"></span>
                Créer</button>
        </div>
        <div *ngIf="page==Page.mission">
            <button class="btn btn-outline-success" routerLink="/creationMissionType" routerLinkActive="on"><span
                    class="fa fa-plus"></span>
                Créer</button>
        </div>

        <div *ngIf="page==Page.UO && checkCreationRights()">
            <button class="btn btn-outline-success" routerLink="/creationUO" routerLinkActive="on"><span
                    class="fa fa-plus"></span>
                Créer</button>
        </div>

    </div>
    <div class="detailsBox">
        <div class="details">
            <h2>Informations détaillées</h2>
            <dl *ngIf="page==Page.UO && dataUO.idx != -1">
                <dt>Nom :</dt>
                <dd>
                    {{(dataUO.idx!=-1 && dataUO.tab[dataUO.idx] !== undefined && dataUO.tab[dataUO.idx].nomCourt !== null && dataUO.tab[dataUO.idx].nomCourt + ' - ') || ""}}{{(dataUO.idx!=-1 && dataUO.tab[dataUO.idx].nomLong) || ""}}
                </dd>
                <dt>Statut :</dt>
                <dd>
                    {{ (dataUO.idx!=-1 && dateIsValid(dataUO.tab[dataUO.idx].dateDebut, dataUO.tab[dataUO.idx].dateFin) && dataUO.tab[dataUO.idx].libelleStatus) || dataUO.idx == -1 && ' '|| "Inactive" }}
                </dd>
                <dt *ngIf="dataUO.tab[dataUO.idx] !== undefined && dataUO.tab[dataUO.idx].siret !== null">
                    Siret :
                </dt>
                <dd>{{(dataUO.idx!=-1 && dataUO.tab[dataUO.idx].siret) || ""}}</dd>
                <div *ngIf="dataUO.idx!=-1 && dataUO.tab[dataUO.idx] !== undefined">
                    <dt>Typologies: </dt>
                    <dd *ngFor="let typo of dataUO.tab[dataUO.idx].typologies">
                        {{(dataUO.idx!=-1 && typo.libelleLongTUO) || ""}}
                    </dd>
                </div>
                <dt>Activités dispensées :</dt>
                <dd>
                    <dl class="equipes listElt">
                        <tr *ngFor="let activite of activitesDetails">
                            <td>{{activite.libelleLong + ' : ' }}</td>
                            <td class="statutEquipe">{{getMessage(activite.dateFinActivite)}}</td>
                        </tr>
                    </dl>
                </dd>
                <dt>Entité de rattachement :</dt>
                <dd *ngIf="!checkUoParent() else affParent">Pas d'entité de rattachement</dd>
                <ng-template #affParent>
                    <dl class="listElt">
                        <tr *ngFor="let UO of uoParentsDetails">
                            <td class="col-8" *ngIf="UO.typeRelation === 'Organisationnelle'">
                                <button class="btn btn-link" (click)="getCorrectUO(UO.idUO)" [disabled]="uoAutorises !== null && uoAutorises.indexOf(UO.idUO) === -1">
                                    {{(dataUO.idx!=-1 && UO.nomCourt !== undefined && UO.nomCourt !== null && UO.nomCourt + ", ") || ""}}
                                    {{(dataUO.idx!=-1 && UO.nomLong) || ""}}
                                    , {{dataUO.idx != -1 && UO.typo !== undefined && UO.typo || ""}}
                                </button>
                                <button routerLink="/affichageUO/{{UO.idUO}}" routerLinkActive="active" id="modifierDetailFilleButton" class="float-right" ><span class="fa fa-info"></span> Détails
                                </button>
                            </td>
                            <td class="btnUpdate col-4" *ngIf="UO.typeRelation === 'Organisationnelle'">
                                <button *ngIf="checkRights() && dateIsValid(null, UO.dateFinOtherUO) && uoAutorises !== null && uoAutorises.indexOf(UO.idUO) !== -1 "
                                    id="modifierDetailFilleButton" routerLink="/modificationUO/{{UO.idUO}}"
                                    routerLinkActive="on"><span class="fa fa-pencil"></span>
                                    Modifier</button>
                            </td>
                        </tr>
                    </dl>
                </ng-template>

                <dt>Entités rattachées :</dt>
                <ng-container *ngIf="!checkUoEnfant() else affEnfant">
                    <span> Pas d'entité rattachée</span>
                    <dl class="listElt">
                        <tr>
                            <td class="col-8"></td>
                            <td class="btnUpdate col-4">
                                <button *ngIf="checkRights()" id="createButtonUoEmptyAR" routerLink="/creationUO"
                                    [queryParams]="{'parentUo':dataUO.tab[dataUO.idx].idUniteOrganisationnelle,'relation':'Organisationnelle'}"
                                    routerLinkActive="on" [ngClass]="{hiddenContent: connexionDroitService.isUORW()}">
                                    <span class="fa fa-plus"></span> Créer</button>
                            </td>
                        </tr>
                    </dl>
                </ng-container>
                <ng-template #affEnfant>
                    <dl class="listElt">
                        <tr *ngFor="let UO of uoFilles; let index = index;">
                            <td class="col-8"
                                *ngIf="UO.typeRelation !== undefined && UO.typeRelation === 'Organisationnelle' ">
                                <button routerLink="/uo/details/{{UO.idUO}}" class="btn btn-link"><!-- (click)="getCorrectUO(UO.idUO)" Modification 06/2020 -->
                                    {{(dataUO.idx!=-1  && UO.nomCourt !== undefined && UO.nomCourt !== null && UO.nomCourt + ", ") || ""}}
                                    {{(dataUO.idx!=-1 && UO.nomLong) || ""}}
                                    , {{dataUO.idx != -1 && UO.typo !== undefined && UO.typo || ""}}
                                </button>
                                <button routerLink="/affichageUO/{{UO.idUO}}" routerLinkActive="active" id="modifierDetailFilleButton" class="float-right" ><span class="fa fa-info"></span> Détails
                                </button>
                            </td>
                            <td class="btnUpdate col-4"
                                *ngIf="UO.typeRelation !== undefined && UO.typeRelation === 'Organisationnelle' && checkRights() && dateIsValid( null, UO.dateFinOtherUO)">
                                <button *ngIf="!isUO_AD()" id="modifierDetailFilleButton"
                                    routerLink="/modificationUO/{{UO.idUO}}" routerLinkActive="on"><span
                                        class="fa fa-pencil"></span>
                                    Modifier</button>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-8"></td>
                            <td class="btnUpdate col-4">
                                <button *ngIf="dataUO.idx!=-1 && checkRights() && dataUO.tab[dataUO.idx] !== undefined" id="createButtonUoEmptyAR"
                                    routerLink="/creationUO"
                                    [queryParams]="{'parentUo':dataUO.tab[dataUO.idx].idUniteOrganisationnelle,'relation':'Organisationnelle'}"
                                    [ngClass]="{hiddenContent: connexionDroitService.isUORW()}"
                                    routerLinkActive="on">
                                    <span class="fa fa-plus"></span> Créer</button>
                            </td>
                        </tr>
                    </dl>
                </ng-template>

                <dt>Autres relations :</dt>

                <ng-container *ngIf="!checkOtherRelations() else affOtherElt">
                    <span>Pas d'autres relations</span>
                    <dl class="listElt">
                        <tr>
                            <td class="col-8"></td>
                            <td class="btnUpdate col-4">
                                <button *ngIf="dataUO.idx!=-1 && checkRights()" id="createButtonUoEmptyAR"
                                    routerLink="/creationUO"
                                    [queryParams]="{'parentUo':dataUO.tab[dataUO.idx].idUniteOrganisationnelle}"
                                    [ngClass]="{hiddenContent: connexionDroitService.isUORW()}"
                                    routerLinkActive="on">
                                    <span class="fa fa-plus"></span> Créer</button>
                            </td>
                        </tr>
                    </dl>
                </ng-container>
                <ng-template #affOtherElt>
                    <dl class="listElt">
                        <tr *ngFor="let UO of uoParentsDetails">
                            <td class="col-8" *ngIf="UO.typeRelation !== 'Organisationnelle'">
                                <button class="btn btn-link" (click)="getCorrectUO(UO.idUO)" [disabled]="uoAutorises !== null && uoAutorises.indexOf(UO.idUO) === -1">
                                    {{(dataUO.idx!=-1  && UO.nomCourt !== undefined && UO.nomCourt !== null && UO.nomCourt + ", ") || ""}}
                                    {{(dataUO.idx!=-1 && UO.nomLong) || ""}}
                                    , {{dataUO.idx != -1 && UO.typo !== undefined && UO.typo || ""}}:
                                    {{dataUO.idx != -1  && UO.typeRelation || ""}}
                                </button>
                                <button routerLink="/affichageUO/{{UO.idUO}}" routerLinkActive="active" id="modifierDetailFilleButton" class="float-right" ><span class="fa fa-info"></span> Détails
                                </button>
                            </td>
                            <td class="btnUpdate col-4" *ngIf="UO.typeRelation !== 'Organisationnelle'">
                                <button *ngIf="checkRights() && dateIsValid( null, UO.dateFinOtherUO) && uoAutorises !== null && uoAutorises.indexOf(UO.idUO) !== -1"
                                    id="modifierDetailFilleButton" routerLink="/modificationUO/{{UO.idUO}}"
                                    routerLinkActive="on"><span class="fa fa-pencil"></span>
                                    Modifier</button>
                            </td>
                        <tr *ngFor="let UO of uoFilles; let index = index;">
                            <td class="col-8"
                                *ngIf="UO.typeRelation !== undefined && UO.typeRelation !== 'Organisationnelle' ">
                                <button class="btn btn-link" (click)="getCorrectUO(UO.idUO)">
                                    {{(dataUO.idx!=-1  && UO.nomCourt !== undefined && UO.nomCourt !== null && UO.nomCourt + ", ") || ""}}
                                    {{(dataUO.idx!=-1 && UO.nomLong) || ""}}
                                    {{dataUO.idx != -1 && UO.typo !== undefined && ', '+ UO.typo + ' : ' || ""}}{{dataUO.idx != -1  && UO.typeRelation || ""}}
                                </button>
                                <button routerLink="/affichageUO/{{UO.idUO}}" routerLinkActive="active" id="modifierDetailFilleButton" class="float-right" ><span class="fa fa-info"></span> Détails
                                </button>
                            </td>
                            <td class="btnUpdate col-4"
                                *ngIf="UO.typeRelation !== undefined && UO.typeRelation !== 'Organisationnelle'">
                                <button *ngIf="checkRights() && dateIsValid( null, UO.dateFinOtherUO) && !isAdmin()"
                                    id="modifierDetailFilleButton" routerLink="/modificationUO/{{UO.idUO}}"
                                    routerLinkActive="on"><span class="fa fa-pencil"></span>
                                    Modifier</button>
                            </td>
                        </tr>
                        <tr>
                            <!--  <div *ngIf="dataUO.idx!=-1">-->
                            <td class="col-8"></td>
                            <td class="btnUpdate col-4">
                                <button *ngIf="dataUO.idx!=-1 && checkRights() && dataUO.tab[dataUO.idx] !== undefined" id="createButtonUoEmptyAR"
                                    routerLink="/creationUO"
                                    [queryParams]="{'parentUo':dataUO.tab[dataUO.idx].idUniteOrganisationnelle}"
                                    [ngClass]="{hiddenContent: connexionDroitService.isUORW()}"
                                    routerLinkActive="on">
                                    <span class="fa fa-plus"></span> Créer
                                </button>
                            </td>

                            <!--  </div>-->
                        </tr>
                    </dl>
                </ng-template>

                <dt>Adresses : </dt>
                <dd
                    *ngIf="adresses !== undefined && (adresses.length === 0 || (adresses.length === 1 && adresses[0].idLocalisation === null))">
                    Pas d'adresse référencée</dd>
                <tr *ngFor="let adresse of adresses;">
                    <div class="adresse" *ngIf="adresse !== null">
                        <dt>{{adresse.libelleLongTypologieAdresse}}</dt>
                        <div class="adresse">
                            <dd>{{adresse.ApptEscalier}}</dd>
                            <dd>{{adresse.ComplementGeographique}}</dd>
                            <dd>{{adresse.numVoie}}</dd>
                            <dd> {{adresse.CodePostal}} {{adresse.Ville}}</dd>
                        </div>
                    </div>
                </tr>

                <dt>Contacts : </dt>
                <dd
                    *ngIf="contacts !== undefined && (contacts.length === 0 || (contacts.length === 1 && contacts[0].idUOContact === null))">
                    Pas de contact référencé</dd>
                <tr *ngFor="let contact of contacts">
                    <div class="contacts" *ngIf="contact !== null">
                        <dd *ngIf="contact.libelleLongTypologieMoyenContact !== null">
                            {{contact.libelleLongTypologieMoyenContact + ' : '}} {{contact.valeur}}
                        </dd>
                    </div>
                </tr>

            </dl>
            <dl *ngIf="page==Page.mission && dataMission.idx != -1">

                <dt>Id Mission Type :</dt>
                <dd>{{(dataMission.idx!=-1 && dataMission.tab[dataMission.idx].idMissionType) || ""}}</dd>
                <dt>Mission Type :</dt>
                <dd>{{(dataMission.idx!=-1 && dataMission.tab[dataMission.idx].libelleMissionType) || ""}}</dd>
                <dt>Date de début :</dt>
                <dd
                    *ngIf="dataMission.idx!=-1 && dataMission.tab[dataMission.idx].dateDebut != null; else nothing">
                    {{(dataMission.idx!=-1 && dataMission.tab[dataMission.idx].dateDebut | date:"dd/MM/yyyy") || ""}}
                </dd>
                <ng-template #nothing>
                    <dd></dd>
                </ng-template>
                <dt *ngIf="dataMission.idx!=-1 && dataMission.tab[dataMission.idx].dateFin != null; else nothing">Date de fin :</dt>
                <dd *ngIf="dataMission.idx!=-1 && dataMission.tab[dataMission.idx].dateFin != null; else nothing">
                    {{(dataMission.idx!=-1 && dataMission.tab[dataMission.idx].dateFin | date:"dd/MM/yyyy") || ""}}
                </dd>
            </dl>
            <dl *ngIf="page==Page.missionPossible && dataMissionPossible.idx != -1 && dataMissionPossible.tab[dataMissionPossible.idx] !== undefined">
                <dt>Id Mission Possible :</dt>
                <dd>{{(dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx].idMissionPossible) || ""}}
                </dd>
                <dt>Mission Type :</dt>
                <dd>{{(dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx].libelleMissionType) || ""}}
                </dd>
                <dt>Mission :</dt>
                <dd>{{(dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx].libelleMissionPossible) || ""}}
                </dd>
                <dt>Typologie Entité :</dt>
                <dd>{{(dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx].libelleTypologieUOLong) || ""}}
                </dd>
                <dt>Activité :</dt>
                <dd>{{(dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx].libelleActivite) || ""}}
                </dd>
                <dt>Date de début :</dt>
                <dd
                    *ngIf="dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx] !== undefined && dataMissionPossible.tab[dataMissionPossible.idx].dateDebut != null; else nothing">
                    {{(dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx].dateDebut | date:"dd/MM/yyyy") || ""}}
                </dd>
                <ng-template #nothing>
                    <dd></dd>
                </ng-template>
                <dt  *ngIf="dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx] !== undefined && dataMissionPossible.tab[dataMissionPossible.idx].dateFin != null; else nothing">Date de fin : </dt>
                <dd
                    *ngIf="dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx] !== undefined && dataMissionPossible.tab[dataMissionPossible.idx].dateFin != null; else nothing">
                    {{dataMissionPossible.tab[dataMissionPossible.idx].dateFin|date:"dd/MM/yyyy"}} </dd>
            </dl>
            <div *ngIf="page==Page.mission && dataMission.idx!=-1">
                <button
                    *ngIf="checkRightsMission() && dateIsValid(dataMission.tab[dataMission.idx].dateDebut, dataMission.tab[dataMission.idx].dateFin) "
                    id="modifierDetailButton" class="btn btn-success"
                    routerLink="/modificationMissionType/{{dataMission.tab[dataMission.idx].idMissionType}}"
                    routerLinkActive="on"><span class="fa fa-pencil"></span>
                    Modifier</button>
            </div>
            <div *ngIf="page==Page.UO && dataUO.idx!=-1">
                <button
                    *ngIf="checkRights() && dateIsValid(dataUO.tab[dataUO.idx].dateDebut, dataUO.tab[dataUO.idx].dateFin)"
                    id="modifierDetailButton" class="btn btn-success"
                    routerLink="/modificationUO/{{dataUO.tab[dataUO.idx].idUniteOrganisationnelle}}"
                    routerLinkActive="on"><span class="fa fa-pencil"></span>
                    Modifier
                </button>
                <button id="detailEntityButton" class="btn btn-success" routerLinkActive="active" 
                    routerLink="/affichageUO/{{dataUO.tab[dataUO.idx].idUniteOrganisationnelle}}">
                    <span class="fa fa-info"></span> 
                    Détails
                </button>
            </div>
            <div *ngIf="page==Page.missionPossible && dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx] !== undefined">
                <button
                    *ngIf="checkRightsMission() && dateIsValid(dataMissionPossible.tab[dataMissionPossible.idx].dateDebut, dataMissionPossible.tab[dataMissionPossible.idx].dateFin)"
                    id="modifierDetailButton" class="btn btn-success"
                    routerLink="/modificationMissionPossible/{{dataMissionPossible.tab[dataMissionPossible.idx].idMissionPossible}}"
                    routerLinkActive="on"><span class="fa fa-pencil"></span>
                    Modifier</button>
            </div>
            <div *ngIf="page==Page.missionPossible && dataMissionPossible.idx!=-1 && dataMissionPossible.tab[dataMissionPossible.idx] !== undefined">
              <button
                *ngIf="checkRightsMission() && !dateIsValid(dataMissionPossible.tab[dataMissionPossible.idx].dateDebut, dataMissionPossible.tab[dataMissionPossible.idx].dateFin)"
                id="modifierDetailButton" class="btn btn-success"
                routerLink="/modificationMissionPossible/{{dataMissionPossible.tab[dataMissionPossible.idx].idMissionPossible}}"
                routerLinkActive="on"><span class="fa fa-pencil"></span>
                Réouvrir</button>
            </div>       
        </div>
    </div>
</div>
