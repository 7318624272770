import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import {Location} from '@angular/common';
import { Activite } from 'src/app/models/Activite.model';
import { ParentUo } from 'src/app/models/ParentUo.model';
import { Relation } from 'src/app/models/relation.model';
import { TypologieContact } from 'src/app/models/TypologieContact.model';
import { TypologieUO } from 'src/app/models/TypologieUO.model';
import { AdresseService } from 'src/app/services/adresse.service';
import { ContactService } from 'src/app/services/contact.service';
import { InformationUOService } from 'src/app/services/information-uo.service';
import { TypologieAdresseService } from 'src/app/services/typologie-adresse.service';
import { TypologieContactService } from 'src/app/services/typologie-contact.service';
import { TypologieUoService } from 'src/app/services/typologie-uo.service';
import { UOService } from 'src/app/services/uo.service';
import { UOAllService } from 'src/app/services/uoall.service';
import { ActivitesParUO } from '../../models/ActivitesParUO.model';
import { Adresse } from '../../models/Adresse.model';
import { Contact } from '../../models/Contact.model';
import { IdentifiantExterne } from '../../models/IdentifiantExterne.model';
import { MissionTypePossible } from '../../models/MissionTypePossible.model';
import { StatutUO } from '../../models/StatutUO.model';
import { UO } from '../../models/UO.model';
import { ActiviteService } from '../../services/activite.service';
import { AdresseFromCPService } from '../../services/adresse-from-cp.service';
import { IdentifiantExterneService } from '../../services/identifiant-externe.service';
import { MissionTypePossibleService } from '../../services/mission-type-possible.service';
import { StatutUOService } from '../../services/statut-uo.service';
import { StackService } from '../../services/storage/stack.service';
import { TypologieAdresse } from './../../models/TypologieAdresse.model';
import { RelationUOService } from './../../services/relation-uo.service';
import { UoEnfantsService } from './../../services/uo-enfant.service';
import { UoParentService } from './../../services/uo-parent.service';
import { SavedPage } from 'src/app/models/storage/SavedPage.model';
import {ConnexionDroitService} from '../../services/connexion/connexion-droit.service';
import { ToastService } from 'src/app/services/toast.service';
import { ERoles } from 'src/app/models/ERoles.model';
import {TypeUO} from "../../models/TypeUO.model";

@Component({
  selector: 'app-modification-uo',
  templateUrl: './modification-uo.component.html',
  styleUrls: ['./modification-uo.component.css']
})
export class ModificationUOComponent implements OnInit, OnDestroy {
  // this variable is used by the NgxLoadingModule for display the loading icon
  // When the loading will be set to true
  public loading = true;
  config: NgxUiLoaderConfig;
  private toastConfig: any = {};
  private _tabulationNumber: number;

  private _AAIDA_PATTERN = '[a-zA-Z0-9]{3}';
  uoInfos: UO;
  listAllUO: UO[];
  idUO: number;
  contact: Contact[];
  removedContact: Contact[];
  // For adresses
  adresses: Adresse[];
  listActivites: Activite[];
  defaultAdresses: Adresse[];
  removedAdresses: Adresse[];
  adresse: Adresse;

  typologieUO: TypologieUO[];
  listTyposAdresses: TypologieAdresse[];
  listTyposContacts: TypologieContact[];
  typo: TypologieUO;
  activite: Activite;
  parentsUo: ParentUo[];
  sonsUo: ParentUo[];
  removedParents: ParentUo[];
  removedSons: ParentUo[];
  removedActivite: Activite[];
  relations: Relation[];
  statuts: StatutUO[];
  potentialAddresses: Adresse[];

  // For contact
  telMobile: string;
  telFixe: string;
  mail: string;
  url: string;
  fax: string;
  loader: any;

  isSubmited: boolean;
  statut: any;
  dataAreLoaded: boolean;
  private uoActivities: Activite[];
  private activitesAll: ActivitesParUO[];
  private sortedActivities: Activite[];
  private defaultParents: any;
  private defaultSons: any[];
  private defaultContact: any[];
  private defaultEquipes: any[];
  private missionPossible: any[];
  creationParentUoId: string;
  creationParentUoRelation: string;
  protected identifiantExterne: IdentifiantExterne;
  UniteOrganisationnelleOnlyActive : UO[];


  public focus: string;
  public nameFocus: string;
  private missionPossibleAll: MissionTypePossible[];
  private returnClicked: boolean;
  private uoAutorises: number[];

  constructor(
    private router: Router,
    private uoService: UOService,
    private route: ActivatedRoute,
    private contactService: ContactService,
    private adresseService: AdresseService,
    private typologieUoService: TypologieUoService,
    private informationUoService: InformationUOService,
    private uoParentService: UoParentService,
    private uoEnfantsService: UoEnfantsService,
    private typologieAdresseService: TypologieAdresseService,
    private relationUOService: RelationUOService,
    private statutUOService: StatutUOService,
    private typologieContactService: TypologieContactService,
    private activiteService: ActiviteService,
    private activiteService2: ActiviteService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private adresseFromCPService: AdresseFromCPService,
    private identifiantExterneService: IdentifiantExterneService,
    private missionTypePossibleService: MissionTypePossibleService,
    private stackService: StackService,
    private toastService: ToastService,
    private uoAllService: UOAllService,
    private location: Location,
    private connexionDroitService: ConnexionDroitService) {
      this.location = location;
    this.config = {
      'bgsColor': '#47BB9C',
      'bgsOpacity': 0.5,
      'bgsPosition': 'bottom-right',
      'bgsSize': 60,
      'bgsType': 'circle',
      'blur': 7,
      'fgsColor': '#47BB9C',
      'fgsPosition': 'center-center',
      'fgsSize': 90,
      'fgsType': 'three-strings',
      'gap': 47,
      'logoPosition': 'center-center',
      'logoSize': 120,
      'logoUrl': '',
      'masterLoaderId': 'master',
      'overlayBorderRadius': '0',
      'overlayColor': 'rgba(40, 40, 40, 0.8)',
      'pbColor': '#47BB9C',
      'pbDirection': 'ltr',
      'pbThickness': 4,
      'hasProgressBar': true,
      'text': 'Chargement des informations',
      'textColor': '#47BB9C',
      'textPosition': 'center-center',
      'minTime': 500
    };
  }

  ngOnInit() {
    this.config = {
      'bgsColor': '#47BB9C',
      'bgsOpacity': 0.5,
      'bgsPosition': 'bottom-right',
      'bgsSize': 60,
      'bgsType': 'circle',
      'blur': 7,
      'fgsColor': '#47BB9C',
      'fgsPosition': 'center-center',
      'fgsSize': 90,
      'fgsType': 'three-strings',
      'gap': 47,
      'logoPosition': 'center-center',
      'logoSize': 120,
      'logoUrl': '',
      'masterLoaderId': 'master',
      'overlayBorderRadius': '0',
      'overlayColor': 'rgba(40, 40, 40, 0.8)',
      'pbColor': '#47BB9C',
      'pbDirection': 'ltr',
      'pbThickness': 4,
      'hasProgressBar': true,
      'text': 'Chargement des informations',
      'textColor': '#47BB9C',
      'textPosition': 'center-center',
      'minTime': 500
    };
    // variable of our form initialisation
    this.fax = '';
    this.telMobile = '';
    this.telFixe = '';
    this.mail = '';
    this.url = '';
    this.missionPossibleAll = [];
    this.listAllUO = [];
    this.adresses = [];
    this.defaultParents = [];
    this.defaultSons = [];
    this.defaultContact = [];
    this.defaultEquipes = [];
    this.removedAdresses = [];
    this.removedContact = [];
    this.listTyposAdresses = [];
    this.parentsUo = [];
    this.sonsUo = [];
    this.removedParents = [];
    this.activitesAll = [];
    this.removedSons = [];
    this.contact = [];
    this.relations = [];
    this.statuts = [];
    this.defaultAdresses = [];
    this.listTyposContacts = [];
    this.listActivites = [];
    this.uoActivities = [];
    this.sortedActivities = [];
    this.removedActivite = [];
    this.potentialAddresses = [];
    this.UniteOrganisationnelleOnlyActive = [];
    this._tabulationNumber = 0;
    this.idUO = Number(this.route.snapshot.paramMap.get('id'));
    this.route.queryParams.subscribe(params => {
      this.creationParentUoId = params['parentUo'];
      this.creationParentUoRelation = params['relation'];
    });

    this.uoAutorises = this.connexionDroitService.getUoAutorises();

    // Toast configuration
    this.toastConfig = {
      classname: 'bg-warning text-light',
      autohide: true,
      headertext: 'Alert',
      delay: 3000
    };

    // we set loading to true ( the page is loading)
    this.loading = true;
    this.isSubmited = false;
    this.dataAreLoaded = false;
    // we get all informations
    this.getAllInformations();
    this.returnClicked = false;

  }

  ngOnDestroy(): void {
    if (!this.returnClicked) {
      if ( this.idUO > 0) {
//        this.stackService.push(new SavedPage(null, null, '/modificationUO/' + this.idUO, null));
      } else {
//        this.stackService.push(new SavedPage(null, null, '/creationUO', null));
      }
    }
  }

  /**
   * Check if the currentTypology is good for the input
   * @param libelle
   */
  isGoodTypology(libelle: string) {
    if (libelle !== undefined && this.typo !== undefined) {
      return libelle === this.typo.libelleLongTUO;
    }
    return false;
  }

  isGoodTypologyAddr(libelle: string, libelleAddr: string) {
    if (libelle !== undefined && libelleAddr !== undefined) {
      return libelle === libelleAddr;
    }
    return false;
  }

  /* We build a promise ( foreach api call) which will help us to wait our datas */
  getPromiseFromAllUo(refreshData: boolean) {
    return new Promise((resolve) => {
      const uoCached = JSON.parse(localStorage.getItem('uo'));
      const uoError = localStorage.getItem('uoError');
      if (uoCached == null || refreshData || null != uoError) {
        localStorage.removeItem('uoError');
        this.uoAllService.dataSubject.subscribe((uoAll: UO[]) => {
          if (null != this.uoAllService.dataError) {
            localStorage.setItem('uoError', this.uoAllService.dataError);
          }

          this.listAllUO.forEach(e => {
            if(e.estActive === 1){
              this.UniteOrganisationnelleOnlyActive.push(
               new UO(
                 e.idUniteOrganisationnelle,
                 e.nomCourt,
                 e.nomLong,
                 e.idStatus,
                 e.libelleStatus,
                 e.typologies,
                 e.dateDebut,
                 e.siret,
                 e.dateFin,
                 e.estActive,
                 e.statutValidation)
              );
            }
        });

          //this.listAllUO = uoAll;
          localStorage.removeItem('uo');
          localStorage.setItem('uo', JSON.stringify(this.UniteOrganisationnelleOnlyActive));
          resolve('UO ALL OK');
         });
         this.uoAllService.getWithValueAllUO();
      }
      else {
        this.listAllUO = uoCached.slice();
        resolve('UO ALL OK');
      }
    });
  }

  getPromiseFromTypoContact() {
    return new Promise((resolve) => {

      const typoContactCached = JSON.parse(localStorage.getItem('typoContact'));
      if (typoContactCached == null) {
        // get the datas
        this.typologieContactService.dataSubject.subscribe((typoContact: TypologieContact[]) => {
          this.listTyposContacts = typoContact;
          // call to resolve with UO ALL OK
          localStorage.setItem('typoContact', JSON.stringify(this.listTyposContacts));
          resolve('UO ALL OK');
        });
        // call to my api : uo all
        this.typologieContactService.getWithValue();
        // the element exists
      } else {
        resolve('TYPO CONTACT OK');
        this.listTyposContacts = typoContactCached.slice();
      }
    });
  }

  getPromiseFromTypoAdresse() {
    return new Promise((resolve) => {

      const typoAdressesCached = JSON.parse(localStorage.getItem('typoAdresses'));
      if (typoAdressesCached == null) {
        // get the datas
        this.typologieAdresseService.dataSubject.subscribe((typoAdresses: TypologieAdresse[]) => {
          this.listTyposAdresses = typoAdresses;
          // this.updateTyposAdresses(typoAdresses);
          // call to resolve with TYPO ADRESSE OK
          localStorage.setItem('typoAdresses', JSON.stringify(this.listTyposAdresses));
          resolve('TYPO ADRESSE OK');
        });
        // call to my api : typo adresse
        this.typologieAdresseService.getWithValue();
      } else {
        resolve('TYPO CONTACT OK');
        this.listTyposAdresses = typoAdressesCached.slice();
      }
    });
  }

  getPromiseFromTypoUO() {
    return new Promise((resolve) => {
      const typoUOCached = JSON.parse(localStorage.getItem('typoUO'));
      if (typoUOCached == null) {
        this.typologieUoService.dataSubject.subscribe((typologie: TypologieUO[]) => {
          this.typologieUO = typologie;
          localStorage.setItem('typoUO', JSON.stringify(this.typologieUO));
          resolve('TYPO UO OK');
        });
        this.typologieUoService.getWithValue('sortProperty', 'libelleLong', 'sortDirection', 'asc');
      } else {
        resolve('TYPO UO OK');
        this.typologieUO = typoUOCached.slice();
      }
    });
  }

  getPromiseFromActivites() {
    return new Promise((resolve) => {
      const activitesCached = JSON.parse(localStorage.getItem('activites'));
      if (activitesCached == null) {
        this.activiteService.dataSubject.subscribe((activites: ActivitesParUO[]) => {
          activites.forEach(e => {
            if (e.idUniteOrganisationnelle === null && !this.activitesAll.some(elt => e.allActivites === elt.allActivites)) {
              this.activitesAll.push(e);
            }
          });
          localStorage.setItem('activites', JSON.stringify(this.activitesAll));
          resolve('TYPO UO OK');
        });
        this.activiteService.getWithValue();
      } else {
        resolve('TYPO UO OK');
        this.activitesAll = activitesCached.slice();
      }
    });
  }


  /* INFORMATION WITH ID */

  getPromiseFromActivitesUO() {
    return new Promise((resolve) => {
      this.activiteService2.dataSubject.subscribe((activites: ActivitesParUO[]) => {
        if (activites.length > 0) {
          this.uoActivities = activites[0].allActivites;
          this.defaultEquipes = this.uoActivities.slice();
        }
        resolve('Activité ok');
      });
      this.activiteService2.getWithValue('idUO', String(this.idUO), 'active', 'true', 'possible', 'true');
    });
  }

  getPromiseFromInfoUO() {
    return new Promise((resolve) => {
      this.informationUoService.dataSubject.subscribe((unitesOrganisationnelles: UO[]) => {
        if (unitesOrganisationnelles.length > 0) {
          this.uoInfos = unitesOrganisationnelles[unitesOrganisationnelles.length - 1];
          if (this.uoInfos !== undefined && this.uoInfos.typologies !== undefined) {
            this.typo = this.uoInfos.typologies[this.uoInfos.typologies.length - 1];
          }
        }
        resolve('INFO UO OK');
      });
      this.informationUoService.getWithId(this.idUO);
    });
  }

  getPromiseFromParentsUO() {
    return new Promise((resolve) => {
      // get uo informations
      this.uoParentService.dataSubject.subscribe((parents: ParentUo[]) => {
        this.defaultParents = parents.slice();
        this.parentsUo = parents.slice();
        resolve('PARENT UO OK');
      });
      this.uoParentService.getActiveWithId(this.idUO);
    });
  }

  getPromiseFromRelationUO() {
    return new Promise((resolve) => {
      // get uo informations
      this.relationUOService.dataSubject.subscribe((relation: Relation[]) => {
        this.relations = relation;
        resolve('Relations OK');
      });
      this.relationUOService.getWithValue();
    });
  }

  getPromiseFromStatutUO() {
    return new Promise((resolve) => {
      // get uo informations
      this.statutUOService.dataSubject.subscribe((statuts: StatutUO[]) => {
        this.statuts = statuts;
        resolve('Relations OK');
      });
      this.statutUOService.getWithValue();
    });
  }

  getPromiseFromContact() {
    return new Promise((resolve) => {
      // get contact informations
      this.contactService.dataSubject.subscribe((contacts: Contact[]) => {
        this.contact = contacts.slice();
        this.defaultContact = contacts.slice();
        resolve('CONTACT OK');
      });
      this.contactService.getWithValue('idUO', String(this.idUO), 'active', 'true');
    });
  }

  getPromiseFromAdresse() {
    return new Promise((resolve) => {
      // get adresses
      this.adresseService.dataSubject.subscribe((adresse: Adresse[]) => {
        this.adresses = adresse.slice();
        this.defaultAdresses = adresse.slice();
        resolve('ADRESSE OK');
      });
      this.adresseService.getWithValue('idUO', String(this.idUO), 'complet', 'true');
    });
  }

  getPromiseFromCPAdresse(cp: string) {
    return new Promise((resolve) => {
      // get adresses
      this.adresseFromCPService.dataSubject.subscribe((adresse: Adresse[]) => {
        this.potentialAddresses = adresse;
        resolve('ADRESSE POTENTIELLE OK');
      });
      this.adresseFromCPService.getWithValue('idUO', String(this.idUO), 'cp', cp);
    });
  }


  getPromiseFromSons() {
    return new Promise((resolve) => {
      // get sonsuo informations
      this.uoEnfantsService.dataSubject.subscribe((unitesOrganisationnelles: ParentUo[]) => {
        this.sonsUo = unitesOrganisationnelles.slice();
        this.defaultSons = unitesOrganisationnelles.slice();
        resolve('SONS OK');
      });
      this.uoEnfantsService.getActiveWithId(this.idUO);
    });
  }

  getPromiseFromIdentifiantExterne() {
    if (localStorage.getItem('roles').split(',').includes('Ref_Admin')) {
      return new Promise((resolve) => {
        // get sonsuo informations
        this.identifiantExterneService.dataSubject.subscribe((identifiant: IdentifiantExterne[]) => {
          if (identifiant[0] === undefined) {

            this.identifiantExterne = new IdentifiantExterne(0, null, null, null, null, null, null);
          } else {
            this.identifiantExterne = identifiant[0];
          }
          resolve('idExterne OK');
        });
        this.identifiantExterneService.getWithId(this.idUO);
      });
    } else {
      this.identifiantExterne = new IdentifiantExterne(0, null, null, null, null, null, null);
      return new Promise((resolve) => {
        resolve('idExterne OK');
      });
    }
  }

  getPromiseFromMissionPossible() {
    return new Promise((resolve) => {
      this.missionTypePossibleService.dataSubject.subscribe((missionPossibles: MissionTypePossible[]) => {
        this.missionPossibleAll = missionPossibles.slice();
        resolve('MP OK');
      });
      this.missionTypePossibleService.getWithValue();

    });
  }

  /**
   * Get all informations for the form
   */
  getAllInformations() {
    /*
      get all informations where id is not required  ( for post and put )
      Promise.all will wait the end of treatment of all our promises
    */
    Promise.all([this.getPromiseFromAllUo(false), this.getPromiseFromTypoAdresse(), this.getPromiseFromTypoUO(),
    this.getPromiseFromRelationUO(), this.getPromiseFromStatutUO(), this.getPromiseFromTypoContact(),
    this.getPromiseFromActivites(), this.getPromiseFromMissionPossible()])
      .then(() => {
        this.listAllUO = this.listAllUO.sort((a, b) => {
          if (a.nomLong < b.nomLong) {
            return -1;
          }
          if (a.nomLong > b.nomLong) {
            return 1;
          }
          return 0;
        });
        this.sortedActivities = this.buildActivitiesArray();
        /* get all topologies */
        if (this.idUO !== 0) {
          this.getAllInformationsWithId();
        } else {
          this.loading = false;
          this.dataAreLoaded = true;
          this.identifiantExterne = new IdentifiantExterne(null, null, null, null, null, null, null);
          this.typo = new TypologieUO(null, null, null, null);
          this.uoInfos = new UO(null, null, null, null, null, null, null, null, null);
          this.uoInfos.estActive = 1;
          if (!this.parentsUo.some(e => e.idUO === null)) {
            const uoParent: UO = this.listAllUO.find(uo => uo.idUniteOrganisationnelle === Number(this.creationParentUoId));
            const parentUO: ParentUo = new ParentUo(uoParent.idUniteOrganisationnelle, uoParent.nomLong, null, null, null, null);
            parentUO.isNewFather = true;
            if (this.creationParentUoRelation != null) {
              parentUO.typeRelation = this.creationParentUoRelation;
              parentUO.isNewRelation = true;
            }
            this.parentsUo.push(parentUO);
          }
          if (!this.adresses.some(e => e.idLocalisation === null)) {
            this.adresses.push(
              new Adresse(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null));
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  sortArrayByNomLong(array: any): any {
    array = array.sort((s1, s2) => {
      const name1 = s1.nomLong.toLowerCase();
      const name2 = s2.nomLong.toLowerCase();
      if (name1 > name2) {
        return 1;
      }
      if (name1 < name2) {
        return -1;
      }
      return 0;
    });
    return array;
  }

  getAllInformationsWithId() {
    /* get all topologies */
    Promise.all([this.getPromiseFromInfoUO(),
    this.getPromiseFromParentsUO(),
    this.getPromiseFromContact(),
    this.getPromiseFromAdresse(),
    this.getPromiseFromSons(),
    this.getPromiseFromActivitesUO(),
    this.getPromiseFromIdentifiantExterne()])
      .then(() => {
        if (this.missionPossibleAll !== undefined) {
          this.filterMissionPossibleArray();
        }

        this.statuts.forEach(e => {
          if (e.id === this.uoInfos.idStatus) {
            this.statut = e.libelleStatusUo;
          }
        });
        if (this.uoInfos.dateFin !== null && new Date(this.uoInfos.dateFin).getTime() < Date.now()) {
          this.toastService.show('Uo fermée. Redirection vers le référentiel', this.toastConfig);
          setTimeout(() => {
            this.router.navigate(['uo']);
          }, 3000);

        }
        this.dataAreLoaded = true;


      })
      .catch(error => {
        console.log(error);
      });
  }

  /* Build json element */
  buildQuery() {
    /* We have to build the answer before put element */
    const jsonBody = {};
    if (this.uoInfos.dateDebut !== undefined) {
      jsonBody['dateDebut'] = this.uoInfos.dateDebut;
    }
    if (this.uoInfos.dateFin !== undefined) {
      jsonBody['dateFin'] = this.uoInfos.dateFin;
    }

    if (this.uoInfos.estActive !== undefined) {
      jsonBody['estActive'] = this.uoInfos.estActive;
    }

    if (this.uoInfos.idStatus !== undefined) {
      jsonBody['statusValidation'] = this.uoInfos.statutValidation;
    }
    if (this.typo.idTypologieUo !== undefined) {
      jsonBody['idTypologieUo'] = this.typo.idTypologieUo;
    }
    if (this.uoInfos.nomCourt !== undefined) {
      jsonBody['nomCourt'] = this.uoInfos.nomCourt;
    }
    if (this.uoInfos.nomLong !== undefined) {
      jsonBody['nomLong'] = this.uoInfos.nomLong;
    }
    if (this.uoInfos.siret !== undefined) {
      jsonBody['siret'] = this.uoInfos.siret;
    }
    /* build the adresse json */
    jsonBody['tableauLocalisations'] = this.buildAdressesJson();
    jsonBody['tableauContact'] = this.buildContactsJson();

    jsonBody['idStatusUo'] = this.uoInfos.idStatus;

    // json Body
    jsonBody['parents'] = this.buildParentsJson();
    if (this.sonsUo.length !== 0) {
      jsonBody['enfants'] = this.buildSonsJson();
    }
    if (this.idUO !== 0) {
      jsonBody['supprParents'] = this.buildSupprParentsJson();
      jsonBody['supprEnfants'] = this.buildSupprSonsJson();
    }
    jsonBody['idUniteOrganisationnelleP'] = this.parentsUo[0].idUO;
    jsonBody['typeRelation'] = this.parentsUo[0].typeRelation;

    if (this.uoActivities.length !== 0 || this.removedActivite.length !== 0) {
      jsonBody['equipes'] = this.buildEquipesJSON();
    }
    // handling of external ids
    if (this.isAdmin()) {
      jsonBody['idAppros'] = this.identifiantExterne.idAppros;
      jsonBody['idUlysse'] = this.identifiantExterne.idUlysse;
      jsonBody['idIntranet'] = this.identifiantExterne.idIntranet;
      jsonBody['idAssurance'] = this.identifiantExterne.idAssurance;
      jsonBody['idParCoeur'] = this.identifiantExterne.idParCoeur;
      jsonBody['idAAIDA'] = this.identifiantExterne.idAAIDA;
    }
    return jsonBody;
  }

  buildSupprParentsJson(): any {
    const returnedArray = [];
    this.parentsUo.forEach(elt => {
      // elt.isDeleted
      if (elt.dateFin !== null) {
        returnedArray.push({ 'idUoUo': elt.idUoUo, 'dateFin': elt.dateFin });
      }
    });
    return returnedArray;
  }

  buildSupprSonsJson(): any {
    const returnedArray = [];
    this.sonsUo.forEach(elt => {
      // elt.isDeleted
      if (elt.dateFin !== null) {
        returnedArray.push({ 'idUoUo': elt.idUoUo, 'dateFin': elt.dateFin });
      }
    });
    return returnedArray;
  }

  buildParentsJson(): any {
    const parentsArray = [];
    this.parentsUo.forEach(parent => {
      if (parent.dateFin === null) {
        const jsonElt = { 'idUO': parent.idUO, 'typeRelation': parent.typeRelation };
        if (parent.idUoUo !== undefined || parent.idUoUo !== null) {
          jsonElt['idUoUo'] = parent.idUoUo;
        }
        parentsArray.push(jsonElt);
      }
    });
    return parentsArray;
  }

  buildSonsJson(): any {
    const sonsArray = [];
    this.sonsUo.forEach(son => {
      if (son.dateFin === null) {
        const jsonElt = { 'idUO': son.idUO, 'typeRelation': son.typeRelation };
        if (son.idUoUo !== undefined || son.idUoUo !== null) {
          jsonElt['idUoUo'] = son.idUoUo;
        }
        sonsArray.push(jsonElt);
      }
    });
    return sonsArray;
  }

  buildAdressesJson(): any {
    const returnedArray = [];
    // update or creation
    this.adresses.forEach(currentAddress => {
      if (!currentAddress.isDeleted) {
        returnedArray.push(this.getJsonFromAddress(currentAddress, 'update'));
      } else {
        returnedArray.push(this.getJsonFromAddress(currentAddress, 'delete'));
      }
    });
    return returnedArray;
  }

  // build contact json
  buildContactsJson(): any {
    const returnedArray = [];
    this.contact.forEach(e => {
      if (!e.isDeleted) {
        returnedArray.push(this.getJSONFromContact(e, false));
      } else {
        returnedArray.push(this.getJSONFromContact(e, true));
      }
    });
    return returnedArray;
  }

  // get json from contact
  getJSONFromContact(elt: Contact, deletedElt: boolean): any {
    const jsonReturned = {
      'dateDebutContact': elt.dateDebut,
      'dateFinContact': elt.dateFin,
      'idContact': elt.idUOContact,
      'idTypologieMoyenContact': elt.idTypoContact,
      'valeur': elt.valeur
    };
    if (elt.idUOContact !== null) {
      jsonReturned['idContact'] = elt.idUOContact;
      jsonReturned['operationType'] = 'update';
      if (elt.dateFin !== null) {
        jsonReturned['operationType'] = 'delete';
      }
    } else {
      jsonReturned['operationType'] = 'create';
      jsonReturned['dateDebutContact'] = new Date();
    }

    return jsonReturned;
  }

  getJsonFromAddress(currentAddress: Adresse, operationType: string) {
    const jsonReturned = {};
    jsonReturned['accesHandicape'] = Number(currentAddress.accesHandicape);
    jsonReturned['apptEscalier'] = currentAddress.ApptEscalier;
    jsonReturned['codePostal'] = currentAddress.CodePostal;
    jsonReturned['complementGeographique'] = currentAddress.ComplementGeographique;
    if (currentAddress.dateDebut === null) {
      currentAddress.dateDebut = new Date();
    }
    jsonReturned['dateDebutAdresse'] = currentAddress.dateDebut;
    jsonReturned['dateFinAdresse'] = currentAddress.dateFin;
    jsonReturned['dateDebutAssurance'] = currentAddress.dateDebutAssurance;
    jsonReturned['dateFinAssurance'] = currentAddress.dateFinAssurance;
    jsonReturned['idLocalisation'] = currentAddress.idLocalisation;
    // A CHANGER
    if (currentAddress.dateDebut === null) {
      if (operationType === 'creation') {
        jsonReturned['dateDebutLocalisation'] = this.formatDate(new Date());
      }
    } else {
      jsonReturned['dateDebutLocalisation'] = currentAddress.dateDebut;
    }
    if (operationType === 'delete') {
      jsonReturned['dateFinLocalisation'] = this.formatDate(new Date());
    }
    // FIN A CHANGER
    jsonReturned['dateEmmenagement'] = currentAddress.dateEmmenagement;
    jsonReturned['dateDemenagement'] = currentAddress.dateDemenagement;
    if (currentAddress.estConnecte !== null) {
      jsonReturned['estConnecte'] = Number(currentAddress.estConnecte);
    }
    // a voir
    jsonReturned['idTypologieAdresse'] = this.getIdTypoAdresse(currentAddress.libelleLongTypologieAdresse);
    if (currentAddress.localPartage !== null) {
      jsonReturned['localPartage'] = Number(currentAddress.localPartage);
    }
    if (currentAddress.localTemporaire !== null) {
      jsonReturned['localTemporaire'] = Number(currentAddress.localTemporaire);
    }
    // oublié dans l'api rest
    if (currentAddress.localPrecaire !== null) {
      jsonReturned['localPrecaire'] = Number(currentAddress.localPrecaire);
    }
    jsonReturned['nbOrdinateurs'] = currentAddress.nbOrdinateur;
    jsonReturned['numVoie'] = currentAddress.numVoie;
    jsonReturned['operationType'] = operationType;
    if (currentAddress.pointEau !== null) {
      jsonReturned['pointEau'] = Number(currentAddress.pointEau);
    }
    jsonReturned['surfaceTotale'] = currentAddress.surfaceTotale;
    // ???
    if (currentAddress.valide) {
      jsonReturned['valide'] = 1;
    }
    jsonReturned['ville'] = currentAddress.Ville;
    if (currentAddress.wc !== null) {
      jsonReturned['wc'] = Number(currentAddress.wc);
    }
    jsonReturned['idIdentifiantExterne'] = 0;
    return jsonReturned;
  }

  getIdTypoAdresse(libelleLongTypologieAdresse: string): any {
    let id = null;
    this.listTyposAdresses.forEach(e => {
      if (libelleLongTypologieAdresse === e.libelleLong) {
        id = e.idTypologieAdresse;
      }
    });
    return id;
  }

  // get id topology by nom court
  getTypologyByNomCourt(libelleCourt: string) {
    this.typologieUO.forEach(elt => {
      if (elt.libelleCourtTUO === libelleCourt) {
        return elt.idTypologieUo;
      }
    });
    return null;
  }

  // init new contacts
  initOtherContacts() {
    if (!this.contact.some(e => e.libelleCourtTypologieMoyenContact === 'Tél. fixe') && this.telFixe !== '') {
      this.contact.push(
        new Contact(this.telFixe, new Date(), 'Tél. fixe', 'Téléphone fixe', null, 16));
    }
    if (!this.contact.some(e => e.libelleCourtTypologieMoyenContact === 'Tél. mobile') && this.telMobile !== '') {
      this.contact.push(
        new Contact(this.telFixe, new Date(), 'Tél. mobile', 'Téléphone mobile', null, 17));
    }
    if (!this.contact.some(e => e.libelleCourtTypologieMoyenContact === 'Fax') && this.fax !== '') {
      this.contact.push(
        new Contact(this.telFixe, new Date(), 'Fax', 'Fax', null, 18));
    }
    if (!this.contact.some(e => e.libelleCourtTypologieMoyenContact === 'Mel') && this.mail !== '') {
      this.contact.push(
        new Contact(this.telFixe, new Date(), 'Mel', 'Adresse de messagerie', null, 19));
    }
    if (!this.contact.some(e => e.libelleCourtTypologieMoyenContact === 'URL') && this.fax) {
      this.contact.push(
        new Contact(this.telFixe, new Date(), 'URL', 'Adresse Internet', null, 20));
    }
  }

  // get the new Parent id
  changeIdSon(previousId: any) {
    this.sonsUo.forEach(son => {
      if (son.idUO === previousId) {
        this.listAllUO.forEach(uo => {
          if (son.nomLong === uo.nomLong) {
            son.idUO = uo.idUniteOrganisationnelle;
            return;
          }
        });
      }
    });
  }

  // get the new Parent id
  changeIdParent(previousId: any) {
    this.parentsUo.forEach(parent => {
      if (parent.idUO === previousId) {
        this.listAllUO.forEach(uo => {
          if (parent.nomLong === uo.nomLong) {
            parent.idUO = uo.idUniteOrganisationnelle;
            return;
          }
        });
      }
    });
  }

  // get the new Parent id
  changeIdTypology(previousElt: any) {
    if (previousElt.idTypologieUo == null || this.uoActivities.length === 0 ||
      confirm('Le changement d\'une typologie d\'uo peut générer des erreurs au niveau des équipes. Êtes-vous sur de vouloir continuer ?')) {
      this.typologieUO.forEach(typ => {
        if (typ.libelleLongTUO === previousElt.libelleLongTUO) {
          previousElt.idTypologieUo = typ.idTypologieUo;
          previousElt.libelleCourtTUO = typ.libelleCourtTUO;
          this.filterMissionPossibleArray();
          return;
        }
      });
    } else {
      this.typologieUO.forEach(typ => {
        if (typ.idTypologieUo === previousElt.idTypologieUo) {
          this.typo.libelleCourtTUO = typ.libelleCourtTUO;
          const elt = <HTMLSelectElement>document.getElementById('typoUO');
          // display value property of select list (from selected option)
          for (let i = 0; i < elt.options.length; i++) {
            if (elt.options[i].value === this.typo.libelleCourtTUO) {
              elt.options[i].selected = true;
            }
          }
        }
      });
    }
    this.filterMissionPossibleArray();
  }

  formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  /* add or remove an element on our array */

  removeLocalisation(adresse: Adresse) {
    adresse.isDeleted = true;
    this.adresses.forEach((item, index) => {
      if (item === adresse) {
        this.adresses.splice(index, 1);
        this.removedAdresses.push(item);
      }
    });
  }

  addEmptyLocalisation() {
    const emptyAdr = new Adresse(null, null, null);
    emptyAdr.idLocalisation = null;
    emptyAdr.numVoie = null;
    emptyAdr.CodePostal = null;
    emptyAdr.Ville = null;
    this.adresses.push(emptyAdr);
    // Modification du focus suite a l'ajout d'une nouvelle adresse
    this.setFocusFirstElementOfArray(this.adresses, 'apptEscalier');

  }

  addEmptySon() {
    this.sonsUo.push(new ParentUo(null, null, null, null, null, null));

    // Modification du focus suite a l'ajout d'un nouveau contact
    this.setFocusFirstElementOfArray(this.sonsUo, 'nomLong');
  }

  removeCurrentParent(parent: ParentUo) {
    parent.isDeleted = true;
  }

  addEmptyParent() {
    this.parentsUo.push(new ParentUo(null, null, null, null, null, null));

    // Modification du focus suite a l'ajout d'un nouveau UO Parent
    this.setFocusFirstElementOfArray(this.parentsUo, 'nomLongP');
  }

  // action called by the form
  saveData() {
    if (this.idUO !== 0) {
      this.put();
    } else {
      this.post();
    }
  }

  reset() {
    this.loading = true;
    this.isSubmited = false;
    this.dataAreLoaded = false;
    // we get all informations
    this.getAllInformations();
    this.returnClicked = false;
    window.history.back();
  }

  post() {
    if (this.checkDatas()) {
      this.isSubmited = false;
      this.uoService.postWithJSON(this.buildQuery(), this.idUO).subscribe(() => {
        this.toastService.show('Opération Réussie !',
          Object.assign(this.toastConfig, {classname: 'bg-success text-light', headertext: 'Info'}));
        Promise.all([this.getPromiseFromAllUo(true)]).then(
            resolve =>  {
              this.router.navigate(['/uo']);
            }
          );
      });
    } else {
      this.errorTreatment();
    }
  }

  put() {
    if (this.checkDatas()) {
      this.isSubmited = false;
      this.uoService.putWithJSON(this.buildQuery(), this.idUO).subscribe(() => {
        this.toastService.show('Opération Réussie !',
          Object.assign(this.toastConfig, {classname: 'bg-success text-light', headertext: 'Info'}));
        Promise.all([this.getPromiseFromAllUo(true)]).then(
          resolve =>  {
            this.router.navigate(['/uo']);
          }
        );
      });
    } else {
      this.errorTreatment();
    }
  }

  errorTreatment(): void {
    if (this.uoInfos.nomLong === null) {
      this.toastService.show('Le champ "nom long" est manqant', this.toastConfig);
    }
    if (this.uoInfos.idStatus === null) {
      this.toastService.show('Le champ "Statut de l\'UO" est manqant', this.toastConfig);
    }
    if (this.typo.idTypologieUo === null) {
      this.toastService.show('Le champ "Structure" est manqant', this.toastConfig);
    }
    if (this.uoInfos.dateDebut === null) {
       this.toastService.show('Le champ "Date de début" est manqant', this.toastConfig);
    } else if (this.adresses.length === 0) {
      this.toastService.show('Adresse manquante', this.toastConfig);
    } else if (this.adresses.some(
        e => (e.libelleLongTypologieAdresse === null))) {
      this.toastService.show('Le champ "Typologie Adresse" est manqant', this.toastConfig);
    } else if (this.adresses.some(e => (e.numVoie === null))) {
      this.toastService.show('Le champ "Numéro et voie" est manqant', this.toastConfig);
    } else if (this.adresses.some(e => (e.CodePostal === null))) {
      this.toastService.show('Le champ "Code Postal" est manqant', this.toastConfig);
    } else if (this.adresses.some(e => (e.Ville === null))) {
      this.toastService.show('Le champ "Ville" est manqant', this.toastConfig);
    } else if (this.countNumberOfMainAddress() > 1) {
      this.toastService.show('Il ne doit pas y avoir plus d\'une adresse principale.', this.toastConfig);
    } else if (this.parentsUo.length === 0) {
      this.toastService.show('Cette UO doit avoir au moins un parent.', this.toastConfig);
    } else if ((this.parentsUo.filter(e => (e.typeRelation === 'Organisationnelle'  && !e.isDeleted))).length > 1) {
      this.toastService.show('Il doit y avoir une seule relation organisationnelle pour les parents', this.toastConfig);
    } else if ((this.parentsUo.filter(e => (e.typeRelation === 'Organisationnelle'  && !e.isDeleted))).length === 0) {
      this.toastService.show('Il doit y avoir au moins une relation organisationnelle pour les parents', this.toastConfig);
    } else if (this.parentsUo.some(e => (e.typeRelation === 'Organisationnelle'))
            && (this.typo.libelleLongTUO === 'Association départementale' || this.typo.libelleLongTUO === 'Association nationale')) {
      this.toastService.show('Cette typologie ne peut avoir de relation organisationnelle', this.toastConfig);
    } else if (this.typo.libelleLongTUO === 'Association départementale' &&
            !(this.parentsUo.filter(e => (e.typeRelation === 'Fonctionnelle' && !e.isDeleted)).length < 2)) {
      this.toastService.show('Cette typologie ne peut avoir plus de 2 relation Fonctionnelles', this.toastConfig);
    } else if (this.identifiantExterne.idAAIDA != null && !this.identifiantExterne.idAAIDA.match(this._AAIDA_PATTERN)) {
      this.toastService.show('id AAIDA invalide', this.toastConfig);
    }  else {
      this.toastService.show('Champs manquants', this.toastConfig);
    }
  }


  countNumberOfMainAddress(): number {
    let i = 0;
    this.adresses.forEach(e => {
      if (e.libelleLongTypologieAdresse === 'Adresse principale' && e.dateDemenagement == null && e.isDeleted === false) {
        i++;
      }
    });
    return i;
  }

  checkDatas(): boolean {
    this.isSubmited = true;
    // check datas for the uo infos
    if (
      this.uoInfos.dateDebut === null ||
      this.uoInfos.nomLong === null ||
      this.uoInfos.idStatus === null ||
      this.typo.idTypologieUo === null) {
      return false;
    }
    if (this.identifiantExterne.idAAIDA != null && !this.identifiantExterne.idAAIDA.match(this._AAIDA_PATTERN)) {
      return false;
    }
    if (
      this.adresses.some(
        e => (e.numVoie === null || e.Ville === null ||
           e.CodePostal === null || e.libelleLongTypologieAdresse === null)
          && !e.isDeleted)) {
      return false;
    }

    if (this.countNumberOfMainAddress() > 1) {
      return false;
    }

    if (this.typo.libelleLongTUO !== 'Association départementale' && this.typo.libelleLongTUO !== 'Association nationale') {
      if (
        (this.parentsUo.some(e => (e.idUO === null || e.typeRelation === null || (e.isDeleted && e.dateFin === null))) ||
            !this.parentsUo.some(e => (e.typeRelation === 'Organisationnelle' && !e.isDeleted)))
        ||
        this.sonsUo.some(e => e.idUO === null || e.typeRelation === null || (e.isDeleted && e.dateFin === null))) {
      return false;
      }

        const parentElements = this.parentsUo.filter(e => (e.typeRelation === 'Organisationnelle' && !e.isDeleted)).length;
        if ( 0 >= parentElements || parentElements  >= 2) {
          return false;
        }
    } else {
      if (this.parentsUo.some(e => (e.typeRelation === 'Organisationnelle' && !e.isDeleted))) {
      return false;
      }

      if ((this.parentsUo.some(e => (e.idUO === null || e.typeRelation === null || (e.isDeleted && e.dateFin === null))) ||
      !(this.parentsUo.filter(e => (e.typeRelation === 'Fonctionnelle' && !e.isDeleted)).length < 2))) {
        return false;
      }
    }

    if (this.uoActivities.some(e => (e.isDeleted && e.dateFinActivite === null))) {
      return false;
    }

    if (this.contact.some(e => (e.isDeleted && e.dateFin === null))) {
      return false;
    }

    if (this.parentsUo.length === 0 || this.adresses.length === 0) {
      return false;
    }

    // every fields required are ok
    return true;
  }

  changeIdStatut() {
    this.statuts.forEach(e => {
      if (e.libelleStatusUo === this.statut) {
        this.uoInfos.idStatus = e.id;
      }
    });
  }

  idGoodStatusUO(currentStatut: StatutUO) {
    if (this.uoInfos.idStatus !== undefined && currentStatut !== undefined) {
      return this.uoInfos.idStatus === currentStatut.id;
    }
    return false;
  }

  addEmptyContact() {
    this.contact.push(new Contact(null, null, null, null, null, null));

    // Modification du focus suite a l'ajout d'un nouveau contact
    this.setFocusFirstElementOfArray(this.contact, 'valueContact');
  }

  removeCurrentContact(currentContact: number) {
    this.removedContact.push(this.contact[currentContact]);
    this.contact.splice(currentContact, 1);
  }

  isGoodValue(currentInformation: any, information: any) {
    return information !== undefined && currentInformation !== undefined && information === currentInformation;
  }

  removeCurrentElement(id: number, array: any, removedArray: any) {
    removedArray.push(this.contact[id]);
    array.splice(id, 1);
  }

  addEmptyActivite() {
    this.uoActivities.push(new Activite(null, null, null, null, null));
    // Modification du focus suite a l'ajout d'un nouveau UO Parent
    this.setFocusFirstElementOfArray(this.uoActivities, 'libelleLongAct');
  }

  private buildEquipesJSON() {
    const equipesArray = [];
    this.uoActivities.forEach(act => {
      const jsonElt = {};
      let idActivite = 0;
      this.activitesAll.forEach(e => {
        e.allActivites.forEach(elt => {
          if (elt.libelleLong === act.libelleLong) {
            idActivite = elt.idActivite;
          }
        });
      });
      jsonElt['idActivite'] = idActivite;
      if (act.dateDebutActivite === null) {
        act.dateDebutActivite = new Date();
      }
      jsonElt['dateDebutActivite'] = this.formatDate(act.dateDebutActivite);
      if (act.dateFinActivite === null) {
        jsonElt['operationType'] = 'update';
      } else {
        jsonElt['dateFinActivite'] = act.dateFinActivite;
        jsonElt['operationType'] = 'delete';
      }
      equipesArray.push(jsonElt);
    });
    return equipesArray;
  }

  changeIdTypoContact(index: number) {
    this.listTyposContacts.forEach(e => {
      if (this.contact[index].libelleLongTypologieMoyenContact === e.libelle) {
        this.contact[index].idTypoContact = e.idTypoContact;
      }
    });

  }

  changeStateActivite(index: number) {
    this.ngxUiLoaderService.startBackgroundLoader(this.config.masterLoaderId, '1');
    if (this.defaultEquipes === [] || !this.defaultEquipes.some(e => e.idActivite === this.uoActivities[index].idActivite)
      || (this.defaultEquipes[index].idActivite == null && this.defaultEquipes[index].isDeleted)) {
      this.uoActivities.splice(index, 1);
    } else {
      this.uoActivities[index].isDeleted = !this.uoActivities[index].isDeleted;
      if (this.uoActivities[index].isDeleted) {
        document.getElementById('boutonSupprAct' + index).innerText = 'Annuler la suppression';
      } else {
        document.getElementById('boutonSupprAct' + index).innerText = 'Supprimer l\'activité';
      }
    }
    this.ngxUiLoaderService.stopBackgroundLoader(this.config.masterLoaderId, '1');
  }

  changeStateSon(index: number) {
    this.ngxUiLoaderService.startBackgroundLoader(this.config.masterLoaderId, '1');
    if (this.defaultSons === [] || !this.defaultSons.some(e => e.idUoUo === this.sonsUo[index].idUoUo)) {
      this.sonsUo.splice(index, 1);
    } else {
      this.sonsUo[index].isDeleted = !this.sonsUo[index].isDeleted;
      if (this.sonsUo[index].isDeleted) {
        document.getElementById('boutonSupprSon' + index).innerText = 'Annuler la suppression';
      } else {
        document.getElementById('boutonSupprSon' + index).innerText = 'Supprimer un enfant';
      }
    }
    this.ngxUiLoaderService.stopBackgroundLoader(this.config.masterLoaderId, '1');
  }

  changeStateParent(index: number) {
    this.ngxUiLoaderService.startBackgroundLoader(this.config.masterLoaderId, '1');
    if (this.defaultParents === [] || !this.defaultParents.some(e => e.idUoUo === this.parentsUo[index].idUoUo)) {
      this.parentsUo.splice(index, 1);
    } else {
      this.parentsUo[index].isDeleted = !this.parentsUo[index].isDeleted;
      if (this.parentsUo[index].isDeleted) {
        document.getElementById('boutonSupprParent' + index).innerText = 'Annuler la suppression';
      } else {
        document.getElementById('boutonSupprParent' + index).innerText = 'Supprimer un parent';
      }
    }
    this.ngxUiLoaderService.stopBackgroundLoader(this.config.masterLoaderId, '1');
  }

  changeStateContact(index: number) {
    this.ngxUiLoaderService.startBackgroundLoader(this.config.masterLoaderId, '1');
    if (this.defaultContact === [] || !this.defaultContact.some(e => e.idUOContact === this.contact[index].idUOContact)) {
      this.contact.splice(index, 1);
    } else {
      this.contact[index].isDeleted = !this.contact[index].isDeleted;
      if (this.contact[index].isDeleted) {
        document.getElementById('boutonSupprContact' + index).innerText = 'Annuler la suppression';
      } else {
        document.getElementById('boutonSupprContact' + index).innerText = 'Supprimer un contact';
      }
    }
    this.ngxUiLoaderService.stopBackgroundLoader(this.config.masterLoaderId, '1');
  }

  changeStateAddress(index: number) {
    this.ngxUiLoaderService.startBackgroundLoader(this.config.masterLoaderId, '1');
    if (this.defaultAdresses === [] || !this.defaultAdresses.some(e => e.idLocalisation === this.adresses[index].idLocalisation)) {
      this.adresses.splice(index, 1);
    } else {
      this.adresses[index].isDeleted = !this.adresses[index].isDeleted;
      if (this.adresses[index].isDeleted) {
        document.getElementById('boutonSupprAddress' + index).innerText = 'Annuler la suppression';
      } else {
        document.getElementById('boutonSupprAddress' + index).innerText = 'Supprimer une adresse';
      }
    }
    this.ngxUiLoaderService.stopBackgroundLoader(this.config.masterLoaderId, '1');
  }

  // lors de l'ajout d'un nouveau bloc de champs, on focus le premier champ de ce bloc
  public setFocusFirstElementOfArray(arrayObject: any[], nameField: string) {
    const index = this.adresses.length - 1;
    // On recupere le nouveau groupe ainsi que le premier champ de celui-ci pour effectuer un focus
    this.nameFocus = nameField + index;
    // On modifie le focus sur le premier champs de ce nouveau groupe de champs
    this.setFocus(this.nameFocus);
  }

  // On modifie le focus sur le nouveau champs dont le nom est passe en paramettre
  public setFocus(fieldToFocus: string): void {
    this.focus = fieldToFocus;
  }

  updatePotentialLocalisationList(index: number) {
      if (this.adresses[index].numVoie == null
          && this.adresses[index].Ville == null) {
          this.getPromiseFromCPAdresse(String(this.adresses[index].CodePostal)).then(() => {
              let htmlText = '<table><tr>';
              htmlText += '<td><select class="form-control" id="localisationTmp">';
              htmlText += '<option selected value="null"> - </option>';
              this.potentialAddresses.forEach(elt => {
                  if (elt.numVoie != null && elt.Ville != null) {
                      htmlText += '<option value="'
                          + elt.numVoie + '::' + elt.Ville
                          + '">' + elt.numVoie + ' - ' + elt.Ville + '</option>';
                  }
              });
              htmlText += '</select></td>';
              htmlText += '</tr></table>';
              if (this.potentialAddresses.length  === 0) {
                  htmlText = '<p>Aucune adresse pour ce code postale</p>';
              }
              document.getElementById('listeLocalisation' + index).innerHTML = htmlText;
              const listeAP = <HTMLSelectElement>document.getElementById('localisationTmp');
              if (listeAP != null) {
                  listeAP.addEventListener('change', () => this.setLocalisation(index, listeAP.options[listeAP.selectedIndex].value));
              }
          });
      }
  }

    setLocalisation(index: number, valeur: string) {
      const wordsAdr = valeur.split('::');
      if (wordsAdr.length < 2) {
          this.adresses[index].Ville = wordsAdr[1];
      } else {
          this.adresses[index].numVoie = wordsAdr[0];
          this.adresses[index].Ville = wordsAdr[1];
      }
      document.getElementById('listeLocalisation' + index).innerHTML = '';
  }

  isAdmin() {
    return localStorage.getItem('roles').split(',').includes('Ref_Admin');
  }

  isPossible(a: Activite): boolean {
    let response = false || this.containsSonActivity(a);
    this.missionPossible.forEach(e => {
      if (a.idActivite === e.idActivite && a.idTypologieUo === e.idTypologieUO && e.idTypologieUO === this.typo.idTypologieUo) {
        response = true;
      }
    });
    return response;
  }

  filterMissionPossibleArray() {
    const array = [];
    this.missionPossibleAll.forEach(e => {
      if (e.idTypologieUO === this.typo.idTypologieUo && !array.some(elt => elt.idActivite === e.idActivite)) {
        array.push(e);
      }
    });
    this.missionPossible = array;
  }

  /**
   *  this function will be used to build the activities array
   *  from the activitesAll array
   *  */
  activityIsContained(elt: Activite, array: Activite[]): boolean {
    let response = false;
    array.forEach(e => {
      response = e.idActivite === elt.idActivite || response;
      if (response) {
        return;
      } else {
        response = this.activityIsContained(elt, e.sonsActivity);
        if (response) {
          return;
        }
      }
    });
    return response;
  }

  buildActivitiesArray(): Activite[] {
    let array: Activite[] = [];
    this.activitesAll.forEach(e => {
      e.allActivites.forEach(elt => {
        if (!this.activityIsContained(elt, array)) {
          // if the parent is 0 then we don't have any parent
          if (!array.some(element => element.idActivite === elt.idActivite)) {
            if (elt.parent === 0) {
              // we add the element to the array
              array.push(elt);
            } else {
              // recursive function which will search and insert the element at the good position
              array = this.pushElt(elt, array);
            }
          }
        }
      });
    });
    return array;
  }

  private pushElt(elt: Activite, array: Activite[]): Activite[] {

    if (!array.some(element => element.idActivite === elt.idActivite)) {
      // for each element of our array
      array.forEach(e => {
        // if the parent is the current element
        if (e.idActivite === elt.parent && !array.some(elt2 => elt2.idActivite === elt.idActivite)) {
          // we add it
          e.sonsActivity.push(elt);
        } else {
          // if the sons array is empty we stop the recursivity and go back to the previous call
          if (e.sonsActivity === []) {
            return;
          } else {
            // recursivity on the sons array
            e.sonsActivity = this.pushElt(elt, e.sonsActivity);
          }
        }
      });

    }
    return array;
  }

  private containsActivity(activity: Activite): boolean {
    let response = false;
    this.missionPossible.forEach(e => {
      if (activity.idActivite === e.idActivite) {
        response = true;
      }
    });
    return response;
  }

  private containsSonActivity(activity: Activite): boolean {
    let response = false;
    if (this.missionPossible === undefined) {
      return response;
    }
    this.missionPossible.forEach(e => {
      if (activity.idActivite === e.idActivite) {
        response = true;
      }
    });
    if (response === false) {
      activity.sonsActivity.forEach(e => {
        response = response || this.containsSonActivity(e);
      });
    }
    return response;
  }

  private getTab(tabNumber: number) {
    let str = '';
    for (let i = 0; i < tabNumber; i++) {
      str += '\u00A0\u00A0\u00A0';
    }
    return str;
  }

  addTab() {
    this._tabulationNumber++;
    return true;
  }

  removeTab() {
    this._tabulationNumber--;
    return true;
  }

  handleLastPage() {
    this.returnClicked = true;
    const savedPage = this.stackService.peek();
    if (savedPage !== undefined) {
      if (savedPage.lastPage !== this.router.url) {
        const path = savedPage.lastPage.slice();
        this.stackService.pop();
        this.router.navigate([path]);
      } else {
        this.stackService.pop();
      }
    }
  }

  disableFieldUpdateRW() {
    return this.connexionDroitService.isUORW() && this.idUO !== 0;
  }

  disableFieldUpdateAttachedUO() {
    const missionId = localStorage.getItem('missionId');
    return this.disableFieldUpdateRW() &&
        JSON.parse(localStorage.getItem('missions'))[missionId]['UO'] === this.uoInfos.nomLong;
  }


  isUO_AD(): boolean {
    return this.connexionDroitService.isUO_AD();
  }

  isUO_ANT(): boolean {
    return this.connexionDroitService.isUO_ANT();
  }

  isUO_AN(): boolean {
    return this.connexionDroitService.isUO_AN();
  }



  isVisible() {
    const missionId = localStorage.getItem('missionId');
    return !(this.disableFieldUpdateRW() &&
    JSON.parse(localStorage.getItem('missions'))[missionId]['UO'] === this.uoInfos.nomLong);
  }
}
