import { Injectable } from '@angular/core';
import {SavedPage} from '../../models/storage/SavedPage.model';

@Injectable({
  providedIn: 'root'
})
export class StackService {
  // list of our saved page
  private _savedPageList: SavedPage[];
  private _poppedSavedPage: SavedPage;
  private PAGE_NUMBER = 10;
  // constructor of our service
  constructor() {
    if ( this._savedPageList === undefined) {
      this._savedPageList = [];
      this._poppedSavedPage = null;
    }
  }
  // push a new element into our list
  push(savedPage: SavedPage): void {
    if (this.PAGE_NUMBER > this._savedPageList.length) {
      this._savedPageList.push(savedPage);
    } else {
      this._savedPageList.splice(0, 1);
      this._savedPageList.push(savedPage);
    }
  }

  // pop the last element of our list
  pop(): void {
    this._poppedSavedPage = this._savedPageList.pop();
  }

  peek(): SavedPage {
    return this._savedPageList[this._savedPageList.length - 1];
  }

  get poppedSavedPage(): SavedPage {
    return this._poppedSavedPage;
  }

  clearPoppedSavedPage() {
    this._poppedSavedPage = null;
  }

  nbPageSave() {
    if ( this._savedPageList === undefined) {
      return 0;
    }
    return this._savedPageList.length;
  }
}
