import { Component, OnInit } from '@angular/core';
import {UO} from '../../models/UO.model';
import {Relation} from '../../models/relation.model';
import {StatutUO} from '../../models/StatutUO.model';
import {TypologieUO} from '../../models/TypologieUO.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UOService} from '../../services/uo.service';
import {ContactService} from '../../services/contact.service';
import {AdresseService} from '../../services/adresse.service';
import {TypologieUoService} from '../../services/typologie-uo.service';
import {InformationUOService} from '../../services/information-uo.service';
import {UOAllService} from '../../services/uoall.service';
import {UoParentService} from '../../services/uo-parent.service';
import {UoEnfantsService} from '../../services/uo-enfant.service';
import {TypologieAdresseService} from '../../services/typologie-adresse.service';
import {RelationUOService} from '../../services/relation-uo.service';
import {StatutUOService} from '../../services/statut-uo.service';
import {TypologieContactService} from '../../services/typologie-contact.service';
import {ParentUo} from '../../models/ParentUo.model';
import {Contact} from '../../models/Contact.model';
import {Adresse} from '../../models/Adresse.model';
import {TypologieAdresse} from '../../models/TypologieAdresse.model';
import {TypologieContact} from '../../models/TypologieContact.model';
import {ActivitesParUO} from '../../models/ActivitesParUO.model';
import {ActiviteService} from '../../services/activite.service';
import {Activite} from '../../models/Activite.model';
import { Location } from '@angular/common';
@Component({
  selector: 'app-affichage-uo',
  templateUrl: './affichage-uo.component.html',
  styleUrls: ['./affichage-uo.component.css']
})
export class AffichageUOComponent implements OnInit {

  uoInfos: UO;
  typo: TypologieUO;
  idUO: number;
  relations: Relation[];
  statuts: StatutUO[];
  dataAreLoaded: boolean;
  adresses: Adresse[];
  listTyposAdresses: TypologieAdresse[];
  contact: Contact[];
  private listTyposContacts: TypologieContact[];
  parentsUo: ParentUo[];
  sonsUo: ParentUo[];
  private _uoActivities: Activite[];

  constructor(    private router: Router,
                  private uoService: UOService,
                  private route: ActivatedRoute,
                  private contactService: ContactService,
                  private adresseService: AdresseService,
                  private typologieUoService: TypologieUoService,
                  private informationUoService: InformationUOService,
                  private uOAllService: UOAllService,
                  private uoParentService: UoParentService,
                  private uoEnfantsService: UoEnfantsService,
                  private typologieAdresseService: TypologieAdresseService,
                  private relationUOService: RelationUOService,
                  private statutUOService: StatutUOService,
                  private typologieContactService: TypologieContactService,
                  private activiteService: ActiviteService,
                  private location: Location) { }

  ngOnInit() {
    this.idUO = Number(this.route.snapshot.paramMap.get('id'));
    this.statuts = [];
    this.adresses = [];
    this.listTyposAdresses = [];
    this.listTyposContacts = [];
    this.contact = [];
    this.parentsUo = [];
    this.sonsUo = [];

    Promise.all([this.getPromiseFromInfoUO(), this.getPromiseFromStatutUO(),
      this.getPromiseFromAdresse(), this.getPromiseFromTypoContact(),
      this.getPromiseFromContact(), this.getPromiseFromParentsUO(),
      this.getPromiseFromSons(), this.getPromiseFromActivitesUO()])
        .then( () => {})
        .catch(error => { console.log(error);
        });
  }
  getPromiseFromActivitesUO() {
    return new Promise((resolve, reject) => {
      this.activiteService.dataSubject.subscribe((activites: ActivitesParUO[]) => {
        if (activites.length > 0) {
          this._uoActivities = activites[0].allActivites.slice();
        }
        resolve('Activité ok');
      });
      this.activiteService.getWithValue('idUO', String(this.idUO));
    });
  }
  getPromiseFromInfoUO() {
    return new Promise((resolve) => {
      this.informationUoService.dataSubject.subscribe((unitesOrganisationnelles: UO[]) => {
        if (unitesOrganisationnelles.length > 0) {
          this.uoInfos = unitesOrganisationnelles[unitesOrganisationnelles.length - 1];
          if (this.uoInfos !== undefined && this.uoInfos.typologies !== undefined) {
            this.typo = this.uoInfos.typologies[unitesOrganisationnelles.length - 1];
          }
          resolve('INFO UO OK');
        }
      });
      this.informationUoService.getWithId(this.idUO);
    });
  }

  getPromiseFromParentsUO() {
    this.parentsUo = [];
    return new Promise((resolve) => {
      // get uo informations
      this.uoParentService.dataSubject.subscribe((parents: ParentUo[]) => {
        parents.forEach(e => {
          if (!this.parentsUo.some(son => son.idUO === e.idUO && son.typeRelation === e.typeRelation)) {
            this.parentsUo.push(e);
          }
        });
        resolve('PARENT UO OK');
      });
      this.uoParentService.getActiveWithId(this.idUO);
    });
  }

  getPromiseFromStatutUO() {
    return new Promise((resolve) => {
      // get uo informations
      this.statutUOService.dataSubject.subscribe((statuts: StatutUO[]) => {
        this.statuts = statuts;
        resolve('Relations OK');
      });
      this.statutUOService.getWithValue();
    });
  }

  getPromiseFromTypoContact() {
    return new Promise((resolve) => {
      // get the datas
      this.typologieContactService.dataSubject.subscribe((typoContact: TypologieContact[]) => {
        this.listTyposContacts = typoContact;
        // call to resolve with UO ALL OK
        resolve('UO ALL OK');
      });
      // call to my api : uo all
      this.typologieContactService.getWithValue();
    });
  }

  getPromiseFromContact() {
    this.contact = [];
    this.contactService.getWithValue('idUO', String(this.idUO), 'active', 'true');
    return new Promise((resolve) => {
      // get contact informations
      this.contactService.dataSubject.subscribe((contacts: Contact[]) => {
        contacts.forEach(e => {
          if (!this.contact.some(contactElt => contactElt.idUOContact === e.idUOContact)) {
            this.contact.push(e);
          }
        });
        resolve('CONTACT OK');
      });
    });
  }

  getPromiseFromAdresse() {
    this.adresses = [];
    return new Promise((resolve) => {
      // get adresses
      this.adresseService.dataSubject.subscribe((adresse: Adresse[]) => {
        adresse.forEach(e => {
          if (!this.adresses.some(elt => elt.idLocalisation === e.idLocalisation)) {
            this.adresses.push(e);
          }
        });
        resolve('ADRESSE OK');
      });
      this.adresseService.getWithValue('idUO', String(this.idUO), 'complet', 'true');
    });
  }

  getPromiseFromSons() {
    return new Promise((resolve) => {
      // get sonsuo informations
      this.uoEnfantsService.dataSubject.subscribe((unitesOrganisationnelles: ParentUo[]) => {
        this.sonsUo = unitesOrganisationnelles.slice();
        resolve('SONS OK');
      });
      this.uoEnfantsService.getActiveWithId(this.idUO);
    });
  }

  getGoodStatus(idStatus: number): string {
    let i = 0;
    let elt = new StatutUO(null, null, null, null);
    while (i < this.statuts.length && elt.id !== idStatus) {
      elt = this.statuts[i];
      i++;
    }
    if (elt.id === idStatus) {
      return elt.libelleStatusUo;
    } else {
      return '';
    }
  }

  getGoodTypoContact(id: number) {
    let i = 0;

    let elt = new TypologieContact(null, null);
    while (i < this.listTyposContacts.length && elt.idTypoContact !== id) {
      elt = this.listTyposContacts[i];
      i++;
    }
    if (elt.idTypoContact === id) {
      return elt.libelle;
    } else {
      return '';
    }
  }

  // update contact array
  updateContacts(contacts: Contact[]) {
    contacts.forEach(elt => {
      if (elt.idUOContact !== null && !this.contact.some(e => e.idUOContact === elt.idUOContact)) {
        this.contact.push(elt);
      }
    });
  }

  // update parent array
  updateParent(parents: ParentUo[]) {
    parents.forEach(elt => {
      if (elt.idUO !== null && !this.parentsUo.some(e => e.idUO === elt.idUO)) {
        this.parentsUo.push(elt);
      }
    });
  }

  // update sons array
  updateSons(unitesOrganisationnelles: ParentUo[]) {
    unitesOrganisationnelles.forEach(uo => {
      if (uo.idUO !== null && !this.sonsUo.some(e => e.idUO === uo.idUO)
          && this.idUO !== uo.idUO) {
        this.sonsUo.push(uo);
      }
    });
  }

  get uoActivities(): Activite[] {
    return this._uoActivities;
  }

  set uoActivities(value: Activite[]) {
    this._uoActivities = value;
  }

  getMessageAct(dateFinActivite: any): any {
    return dateFinActivite === null ? 'Active' : 'Inactive ('+dateFinActivite+')';
  }
  return(){
    this.location.back();
  }
}
