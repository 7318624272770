<div class="retour">
        <button class="btn btn-info mr-sm-2" (click)="return()">
            <span class="fas fa-chevron-left"></span> Retour
        </button>
  </div>
<div class="container-fluid margin-left padding">
    <div class="static">
        <!-- uo informations-->
        <fieldset *ngIf="uoInfos !== undefined">
            <legend [ngStyle]="{'color':'#47BB9C'}">Informations de l'entité</legend>
            <!-- information of the UO-->
            <div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Nom Court :</label>
                            <div class="mr-sm-2">{{uoInfos.nomCourt}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Nom Long :</label>
                            <div class="mr-sm-2">{{uoInfos.nomLong}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-3">Statut de l'entité :</label>
                            <div class="mr-sm-2">{{getGoodStatus(uoInfos.idStatus)}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Siret :</label>
                            <div class="mr-sm-2">{{uoInfos.siret}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-3">Date de début :</label>
                            <div class="mr-sm-2">{{uoInfos.dateDebut}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-3">Date de Fin :</label>
                            <div class="mr-sm-2">{{uoInfos.dateFin}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4" *ngIf="uoInfos.statutValidation !== null">
                        <div class="form-inline">
                            <label class="mr-sm-3">Statut de validation :</label>
                            <div class="mr-sm-2">{{uoInfos.statutValidation}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-3">Structure :</label>
                            <div class="mr-sm-2">{{typo.libelleCourtTUO}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>

        <!-- fieldset containing array -->
        <fieldset *ngIf="adresses !== undefined && adresses.length != 0">
            <legend [ngStyle]="{'color':'#47BB9C'}">Informations concernant la localisation</legend>

            <!-- foreach information-->
            <div class="adresse" *ngFor="let currentAddress of adresses let index = index;">
                <div><strong> Adresse {{index + 1}} :</strong></div>
                <!-- removing button which is able to delete one address-->
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Appartement/Escalier : </label>
                            <div class="mr-sm-2">{{currentAddress.ApptEscalier}}</div>
                        </div>
                    </div>
                    <!-- Information about the localisation-->
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Num. Voie : </label>
                            <div class="mr-sm-2">{{currentAddress.numVoie}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Ville : </label>
                            <div class="mr-sm-2">{{currentAddress.Ville}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Code Postal : </label>
                            <div class="mr-sm-2">{{currentAddress.CodePostal}}</div>
                        </div>
                    </div>
                    <!-- information about the topology -->
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Typologie Adresse : </label>
                            <div class="mr-sm-2">{{currentAddress.libelleLongTypologieAdresse}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-2">Surface : </label>
                            <div class="mr-sm-2">{{currentAddress.surfaceTotale}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline" *ngIf="adresses !== undefined">
                            <label class="mr-sm-2">Nombre d'ordinateurs : </label>
                            <div class="mr-sm-2">{{currentAddress.nbOrdinateur}}</div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="mr-sm-4">Connexion Internet </label>
                        <input class="form-check-input" type="checkbox" name="connexion{{index}}"
                               [(ngModel)]="currentAddress.estConnecte"
                               [checked]="currentAddress.estConnecte=='1'" [disabled]="true">
                    </div>
                </div>
                <!-- Information about equipments -->
                <div class="form-row">
                    <div class="col-md-4">
                        <div *ngIf="adresses !== undefined">
                            <label class="mr-sm-4" for="CheckDemo3">Accès Handicapé </label>
                            <input class="form-check-input" id="CheckDemo3" type="checkbox"
                                   name="accesHandicape{{index}}" [(ngModel)]="currentAddress.accesHandicape"
                                   [checked]="currentAddress.accesHandicape=='1'" [disabled]="true">
                        </div>
                    </div>
                    <div class="col-md-4 form-inline">
                        <label class="mr-sm-4" for="CheckDemo4">Point d'eau </label>
                        <input class="form-check-input" id="CheckDemo4" type="checkbox" name="pointEau{{index}}"
                               [(ngModel)]="currentAddress.pointEau"
                               [checked]="currentAddress.pointEau=='1'" [disabled]="true">
                    </div>
                    <div class="col-md-4 form-inline">
                        <label class="mr-sm-4" for="CheckDemo5">WC </label>
                        <input class="form-check-input" id="CheckDemo5" type="checkbox" name="wc{{index}}"
                               [(ngModel)]="currentAddress.wc"
                               [checked]="currentAddress.wc=='1'" [disabled]="true">
                    </div>
                </div>
                <!-- localisation gestion -->
                <div class="form-row">
                    <div class="col-md-4">
                        <label class="mr-sm-4" for="CheckDemo6">Local partagé </label>
                        <input class="form-check-input" id="CheckDemo6" type="checkbox" name="localPartage{{index}}"
                               [(ngModel)]="currentAddress.localPartage"
                               [checked]="currentAddress.localPartage==1" [disabled]="true">
                    </div>
                    <div class="col-md-4">
                        <label class="mr-sm-4" for="CheckDemo7">Local précaire </label>
                        <input class="form-check-input" id="CheckDemo7" type="checkbox" name="localPrecaire{{index}}"
                               [(ngModel)]="currentAddress.localPrecaire"
                               [checked]="currentAddress.localPrecaire==1" [disabled]="true">
                    </div>
                    <div class="col-md-4">
                        <label class="mr-sm-4" for="CheckDemo8">Local temporaire </label>
                        <input class="form-check-input" id="CheckDemo8" type="checkbox" name="localTemporaire{{index}}"
                               [(ngModel)]="currentAddress.localTemporaire"
                               [checked]="currentAddress.localTemporaire==1" [disabled]="true">
                    </div>
                </div>
                <!-- date gestion -->
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-3">Date début assurance </label>
                            <div class="mr-sm-2">{{currentAddress.dateDebutAssurance}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-3">Date fin assurance </label>
                            <div class="mr-sm-2">{{currentAddress.dateFinAssurance}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-3">Date d'emmenagement </label>
                            <div class="mr-sm-2">{{currentAddress.dateEmmenagement}}</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <div class="form-inline">
                            <label class="mr-sm-3">Date de déménagement </label>
                            <div class="mr-sm-2">{{currentAddress.dateDemenagement}}</div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </fieldset>

        <!-- contact information-->
        <fieldset *ngIf="contact !== undefined && contact.length !== 0">
            <legend [ngStyle]="{'color':'#47BB9C'}">Informations de contact</legend>
            <div class="contacts" *ngFor="let currentContact of contact let index = index;">
                <label><strong>{{getGoodTypoContact(currentContact.idTypoContact) + ' : '}}</strong> {{currentContact.valeur}}
                </label>
            </div>
        </fieldset>

        <fieldset *ngIf="parentsUo !== undefined && parentsUo.length !==0">
            <legend [ngStyle]="{'color':'#47BB9C'}">Entité de rattachement</legend>
            <div class="uoParent" *ngFor="let parent of parentsUo let index = index;">
                <label><strong>{{parent.nomLong}} - {{parent.typo}} : </strong>{{parent.typeRelation}}</label>
            </div>
        </fieldset>


        <fieldset *ngIf="sonsUo !== undefined && sonsUo.length!==0">
            <legend [ngStyle]="{'color':'#47BB9C'}">Entité rattachée</legend>
            <div class="uoParent" *ngFor="let son of sonsUo let index = index;">
                <label> <strong>{{son.nomLong}} - {{son.typo}} :</strong> {{son.typeRelation}}</label>
            </div>
        </fieldset>

        <fieldset *ngIf="uoActivities !== undefined && uoActivities.length > 0">
            <legend [ngStyle]="{'color':'#47BB9C'}">Equipes</legend>
            <div class="uoParent" *ngFor="let act of uoActivities let index = index;">
                <label> <strong>{{act.libelleLong}}</strong> - {{getMessageAct(act.dateFinActivite)}}</label>
            </div>
        </fieldset>
    </div>
</div>
