import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import {IdentifiantExterne} from '../models/IdentifiantExterne.model';
@Injectable({
  providedIn: 'root'
})
export class IdentifiantExterneService extends GetAndPost {

  constructor(httpClient: HttpClient) {
    super('uo/identifiantsExternes', httpClient);
  }

  newElement(e: any): any {
    if (e != null) {
      return new IdentifiantExterne(e.idIdentifiantExterne, e.idUlysse, e.idAppros, e.idIntranet, e.idAssurance, e.idParCoeur, e.idAAIDA);
    } else {
      return new IdentifiantExterne(null, null, null, null, null, null, null);
    }
  }
}
