<!-- <div class="entete">
  <h1>interface de connexion</h1>
</div>
<div class="formulaire-modification">
  <form [formGroup]="formLogin" novalidate (ngSubmit)="login()">
    Nom d'utilisateur :<br>
    <input type="text" name="username" formControlName="username"><br>
    Mot de passe:<br>
    <input type="password" name="password" formControlName="password"><br>
    <button type="submit">Connexion</button>
  </form>
</div> -->
<ng-template #content let-modal>

    <div class="modal-header">
        <h4 class="modal-title label label-default" id="modal-basic-title">Séléctionnez une mission : </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div *ngFor="let mission of missions;let i = index">
            <button class="btn btn-outline-success  col-12 mt-1" (click)="modal.close(i)">{{mission['UO']}}
                - {{mission['mission']}}</button>
        </div>
    </div>
</ng-template>

<div class="row">
    <div class="col">
        <div class="mx-auto w-40 p-3 margin">
            <h2 class="text-center" [style.color]="'#E5007D'">Interface de connexion</h2>
            <form [formGroup]="formLogin" novalidate (ngSubmit)="login(content)">
                <div class="width elt">
                    <label>Nom d'utilisateur :</label>
                    <input type="text" class="form-control" placeholder="Nom d'utilisateur" name="username"
                           formControlName="username">

                    <br>
                    <label>Mot de passe :</label>
                    <input type="password" class="form-control" placeholder="Mot de passe" name="password"
                           formControlName="password">

                </div>
                <div class="d-flex justify-content-center div_top_25px">
                    <button class="boutonConnexion" type="submit">Connexion</button>
                </div>

                <br />
                <div class="d-flex justify-content-center div_top_25px">
                    <label class="text-danger">{{errorLogin}}</label>
                </div>
            </form>
        </div>
    </div>
</div>
