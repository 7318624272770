export class Contact {
  public isDeleted: boolean;
  public isUpdatable: boolean;
  constructor(public valeur: string,
    public dateDebut: Date,
    public libelleCourtTypologieMoyenContact: string,
    public libelleLongTypologieMoyenContact: string,
    public idUOContact: number,
    public idTypoContact: number,
    public dateDebutTMC?: Date,
    public dateFinTMC?: Date,
    public dateFin?: Date,
  ) {
    this.isUpdatable = false;
  }


}
