<div class="container-fluid margin-left padding">
    <h3 class="margin-top-bottom" [ngStyle]="{'color':'#47BB9C'}">Créations et modifications Entité :</h3>
    <div>
      <form>
        <div class="formUO" *ngIf="dataAreLoaded">

          <!-- uo informations-->
          <fieldset class="static" *ngIf="uoInfos !== undefined">
            <legend class="padding-titre"> Informations de l'entité</legend>
            <div class="form-group row align-items-center">
              <div class="col-sm-3">
                <label class="mr-sm-2 col-form-label">Nom Court</label>
              </div>
              <div class="col-sm-3">
                <input class="form-control mr-sm-2" type="text" name="nomCourt"
                       [(ngModel)]="uoInfos.nomCourt" *ngIf="uoInfos !== undefined"
                       [disabled]="disableFieldUpdateRW()"
                       value="{{uoInfos.nomCourt}}">
              </div>
              <div class="col-sm-3">
                <label class="mr-sm-2 col-form-label">Nom Long</label>
              </div>
              <div class="col-sm-3">
                <input class="form-control mr-sm-2" type="text" name="nomLong" [(ngModel)]="uoInfos.nomLong"
                       *ngIf="uoInfos !== undefined" value="{{uoInfos.nomLong}}"
                       [disabled]="disableFieldUpdateRW()"
                       [ngClass]="{ 'errorInput': uoInfos.nomLong === null && isSubmited }">
              </div>
            </div>
            <div class="form-group row align-items-center">
              <div class="col-sm-3">
                <label class="mr-sm-2 col-form-label">Siret</label>
              </div>
              <div class="col-sm-3">
                <input class="form-control mr-sm-2" type="text" name="siret" [(ngModel)]="uoInfos.siret"
                       [disabled]="!(isUO_AD() || isAdmin())"
                       *ngIf="uoInfos !== undefined" value="{{uoInfos.siret}}">
              </div>
              <div class="col-sm-3">
                <label for="uostatut" class="mr-sm-2 col-form-label">Statut de l'entité </label>
              </div>
              <div class="col-sm-3">
                <select name="uostatut" id="uostatut" [(ngModel)]="statut" (change)="changeIdStatut()" [disabled]="disableFieldUpdateRW()"
                        class="form-control mr-sm-2"
                        [ngClass]="{ 'errorInput': uoInfos.idStatus === null && isSubmited }">
                  <option *ngFor="let currentStatut of statuts"
                          [selected]="isGoodValue(currentStatut.id,uoInfos.idStatus)">
                    {{currentStatut.libelleStatusUo}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <div class="col-sm-3">
                <label class="mr-sm-2 col-form-label">Structure </label>
              </div>
              <div class="col-sm-3">
                <select class="form-control mr-sm-2" name="typoUO" id="typoUO"
                        [(ngModel)]="typo.libelleLongTUO" *ngIf="typologieUO != undefined"
                        (change)="changeIdTypology(typo)"
                        [ngClass]="{ 'errorInput': typo.libelleLongTUO === null && isSubmited }"
                        [disabled]="idUO != 0">
                  <option *ngFor="let typologie of typologieUO"
                          [selected]="isGoodTypology(typologie.libelleLongTUO)">
                    {{typologie.libelleLongTUO}}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label class="mr-sm-2 col-form-label">Date de début</label>
              </div>
              <div class="col-sm-3">
                <input class="form-control mr-sm-2" type="date" name="dateDebut"
                       [(ngModel)]="uoInfos.dateDebut" *ngIf="uoInfos !== undefined"
                       value="{{uoInfos.dateDebut}}" [disabled]="isUO_AD()"
                       [ngClass]="{ 'errorInput': uoInfos.dateDebut === null && isSubmited }">
              </div>
            </div>
            <div class="form-group row align-items-center">
              <div class="col-sm-3">
                <label class="mr-sm-2 col-form-label">Date de Fin</label>
              </div>
              <div class="col-sm-3">
                <input class="form-control mr-sm-2" type="date" name="dateFin" [(ngModel)]="uoInfos.dateFin" [disabled]="disableFieldUpdateRW()"
                       *ngIf="uoInfos !== undefined" value="{{uoInfos.dateFin}}">
              </div>
            </div>
            <ng-container *ngIf="isAdmin()">
              <div class="form-group row align-items-center">
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">idAppros </label>
                </div>
                <div class="col-sm-3">
                  <input *ngIf="identifiantExterne !== undefined" class="form-control mr-sm-2" type="text"
                         name="idAppros" [(ngModel)]="identifiantExterne.idAppros">
                </div>
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">idUlysse </label>
                </div>
                <div class="col-sm-3">
                  <input *ngIf="identifiantExterne !== undefined" class="form-control mr-sm-2" type="text"
                         name="idUlysse" [(ngModel)]="identifiantExterne.idUlysse">
                </div>
              </div>
              <div class="form-group row align-items-center">
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">idIntranet </label>
                </div>
                <div class="col-sm-3">
                  <input *ngIf="identifiantExterne !== undefined" class="form-control mr-sm-2" type="text"
                         name="idIntranet" [(ngModel)]="identifiantExterne.idIntranet">
                </div>
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">idAssurance </label>
                </div>
                <div class="col-sm-3">
                  <input *ngIf="identifiantExterne !== undefined" class="form-control mr-sm-2" type="text"
                         name="idAssurance" [(ngModel)]="identifiantExterne.idAssurance">
                </div>
              </div>
              <div class="form-group row align-items-center">
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">idParCoeur </label>
                </div>
                <div class="col-sm-3">
                  <input *ngIf="identifiantExterne !== undefined" class="form-control mr-sm-2" type="text"
                         name="idParCoeur" [(ngModel)]="identifiantExterne.idParCoeur">
                </div>
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">idAAIDA </label>
                </div>
                <div class="col-sm-3">
                  <input *ngIf="identifiantExterne !== undefined" class="form-control mr-sm-2" type="text"
                         name="idAAIDA" [(ngModel)]="identifiantExterne.idAAIDA" maxlength="3"
                         minlength="3" pattern="[a-zA-Z0-9]{3}">
                </div>
              </div>
            </ng-container>


          </fieldset>
          <!-------------------------------------------------->
          <!-- localisation informations -->

          <!-- fieldset containing array -->
          <fieldset class="static" *ngIf="adresses !== undefined">
            <legend class="padding-titre">Informations concernant la localisation</legend>
            <div class="text-right">
              <button *ngIf="isVisible()" class="btn btn-info mb-sm-1" (click)="addEmptyLocalisation()" 
                      [disabled]="disableFieldUpdateAttachedUO()">Ajouter une adresse
              </button>
            </div>
            <!-- foreach information-->
            <div class="margin-top"
                 *ngFor="let currentAddress of adresses; let index = index;trackBy : trackByFunction"
                 [ngClass]="{'removedElt': currentAddress.isDeleted}">
              <!-- removing button which is able to delete one address-->
              <div class="text-right">
                <button
                        class="btn btn-outline-danger margin-bottom"
                        [ngClass]="{'removeLocalisation':!currentAddress.isDeleted,'restoreLocalisation':currentAddress.isDeleted}"
                        id="boutonSupprAddress{{index}}" (click)="changeStateAddress(index)" 
                        [disabled]="isUO_AD() && currentAddress.isAdressePrincipale">Supprimer
                  l'adresse
                </button>
              </div>
              <div class="form-group row align-items-center">
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">Appartement / Escalier </label>
                </div>
                <div class="col-sm-3">
                  <input class="form-control mr-sm-2" type="text" name="apptEscalier{{index}}" 
                         [disabled]="!isAdmin() && currentAddress.isAdressePrincipale"
                         [(ngModel)]="currentAddress.ApptEscalier">
                </div>
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">Complément Géographique </label>
                </div>
                <div class="col-sm-3">
                  <input class="form-control mr-sm-2" type="text" name="complementGeo{{index}}" 
                         [disabled]="!isAdmin() && currentAddress.isAdressePrincipale"
                         [(ngModel)]="currentAddress.ComplementGeographique">
                </div>
              </div>
              <div class="form-group row align-items-center">
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">Typologie Adresse</label>
                </div>
                <div class="col-sm-3">
                  <select name="typologieAdresse{{index}}" class="form-control mr-sm-2" 
                          [disabled]="!isAdmin() && currentAddress.isAdressePrincipale"
                          [(ngModel)]="currentAddress.libelleLongTypologieAdresse" [ngClass]="{
                                  'errorInput': currentAddress.libelleLongTypologieAdresse === null && isSubmited
                                  }">
                    <option *ngFor="let typoAdresse of listTyposAdresses"
                            [selected]="isGoodValue(currentAddress.libelleLongTypologieAdresse,typoAdresse.libelleLong)"
                            value="{{typoAdresse.libelleLong}}">
                      {{typoAdresse.libelleLong}}
                    </option>
                  </select>
                </div>
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">Code Postal</label>
                </div>
                <div class="col-sm-3">
                  <input (focusout)="updatePotentialLocalisationList(index)" class="form-control mr-sm-2" 
                         [disabled]="!isAdmin() && currentAddress.isAdressePrincipale"
                         type="text" name="code_postal{{index}}" [(ngModel)]="currentAddress.CodePostal"
                         [ngClass]="{
                                  'errorInput': currentAddress.CodePostal === null && isSubmited
                                  }" [disabled]="!currentAddress.isAdressePrincipale">
                </div>
              </div>
              <div class="form-group row align-items-center">
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">Ville </label>
                </div>
                <div class="col-sm-3">
                  <input class="form-control mr-sm-2" type="text" name="ville{{index}}"
                         [(ngModel)]="currentAddress.Ville" [ngClass]="{
                                  'errorInput': currentAddress.Ville === null && isSubmited
                                  }" [disabled]="currentAddress.isAdressePrincipale && !isAdmin()">
                </div>
                <div class="col-sm-3">
                  <label class="mr-sm-2 col-form-label">Numéro et voie </label>
                </div>
                <div class="col-sm-3">
                  <input class="form-control mr-sm-2" type="text" name="adresse{{index}}"
                         [(ngModel)]="currentAddress.numVoie" [ngClass]="{
                                  'errorInput': currentAddress.numVoie === null && isSubmited
                                  }" [disabled]="currentAddress.isAdressePrincipale && !isAdmin()">
                </div>
              </div>
              <div class="form-group row align-items-center">
                <div class="col-sm-12">
                  <div class="listeAddrPossible" *ngIf="currentAddress.CodePostal !== null">
                    <dt class="dateFin"
                        *ngIf="potentialAddresses.length >0 && currentAddress.Ville == null">
                      Liste des
                      adresses déjà existantes  :
                    </dt>
                    <dt class="listeLoc" id="listeLocalisation{{index}}"></dt>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="currentAddress.isDisplayed">
                <div class="form-group row align-items-center">
                  <div class="col-sm-3 align-self-center">
                    <label class="mr-sm-2 col-form-label">Surface</label>
                  </div>
                  <div class="col-sm-3 align-self-center">
                    <input class="form-control mr-sm-2" type="text" name="surface{{index}}"  [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           [(ngModel)]="currentAddress.surfaceTotale">
                  </div>
                  <div class="col-sm-3 align-self-center" *ngIf="adresses !== undefined">
                    <label class="mr-sm-2 col-form-label">Nombre d'ordinateurs </label>
                  </div>
                  <div class="col-sm-3 align-self-center" *ngIf="adresses !== undefined">
                    <input class="form-control mr-sm-2" type="text" name="nbOrdinateur{{index}}" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           [(ngModel)]="currentAddress.nbOrdinateur">
                  </div>
                </div>

                <!-- date gestion -->
                <div class="form-group row align-items-center">
                  <div class="col-sm-3">
                    <label class="mr-sm-2 col-form-label" >Date début assurance </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-control mr-sm-2" type="date" name="dateDebutAssurance{{index}}" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           [(ngModel)]="currentAddress.dateDebutAssurance">
                  </div>
                  <div class="col-sm-3">
                    <label class="mr-sm-2 col-form-label">Date fin assurance </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-control mr-sm-2" type="date" name="dateFinAssurance{{index}}" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           [(ngModel)]="currentAddress.dateFinAssurance">
                  </div>
                </div>

                <div class="form-group row align-items-center">

                </div>
                <div class="form-group row align-items-center">
                  <div class="col-sm-3">
                    <label class="mr-sm-2 col-form-label">Date d'emmenagement </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-control mr-sm-2" type="date" name="dateEmmenagement{{index}}" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           [(ngModel)]="currentAddress.dateEmmenagement">
                  </div>
                  <div class="col-sm-3">
                    <label class="mr-sm-2 col-form-label">Date de déménagement </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-control mr-sm-2" type="date" name="dateDemenagement{{index}}" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           [(ngModel)]="currentAddress.dateDemenagement">
                  </div>
                </div>

                <!-- Information about equipments -->
                <div class="form-group row">
                  <div class="col-sm-3 align-self-center">
                    <label class="form-check-label mr-sm-2"> Connexion Internet </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-check-input ml-sm-2" id="CheckConn{{index}}" type="checkbox" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           name="connexion{{index}}" [(ngModel)]="currentAddress.estConnecte"
                           [checked]="currentAddress.estConnecte=='1'">
                  </div>
                  <div class="col-sm-3 align-self-center">
                    <label class="form-check-label mr-sm-2" *ngIf="adresses !== undefined">Accès
                      Handicapé</label>
                  </div>
                  <div class="col-sm-3" *ngIf="adresses !== undefined">
                    <input class="form-check-input ml-sm-2" id="CheckHand{{index}}" type="checkbox" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           name="accesHandicape{{index}}" [(ngModel)]="currentAddress.accesHandicape"
                           [checked]="currentAddress.accesHandicape=='1'">
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 align-self-center">
                    <label class="form-check-label mr-sm-2">Point d'eau </label>
                  </div>
                  <div class="col-sm-3" *ngIf="adresses !== undefined">
                    <input class="form-check-input ml-sm-2" id="CheckEau{{index}}" type="checkbox" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           name="pointEau{{index}}" [(ngModel)]="currentAddress.pointEau"
                           [checked]="currentAddress.pointEau=='1'">
                  </div>
                  <div class="col-sm-3 align-self-center">
                    <label class="form-check-label mr-sm-2">WC </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-check-input ml-sm-2" id="CheckWC{{index}}" type="checkbox" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           name="wc{{index}}" [(ngModel)]="currentAddress.wc"
                           [checked]="currentAddress.wc=='1'">
                  </div>
                </div>

                <!-- localisation gestion -->

                <div class="form-group row">
                  <div class="col-sm-3 align-self-center">
                    <label class="form-check-label mr-sm-2" for="CheckDemo2">Local partagé </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-check-input ml-sm-2" id="CheckDemo2" type="checkbox" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           name="localPartage{{index}}" [(ngModel)]="currentAddress.localPartage"
                           [checked]="currentAddress.localPartage==1">
                  </div>
                  <div class="col-sm-3 align-self-center">
                    <label class="form-check-label mr-sm-2" for="CheckDemo3">Local précaire </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-check-input ml-sm-2" id="CheckDemo3" type="checkbox"
                           name="localPrecaire{{index}}" [(ngModel)]="currentAddress.localPrecaire" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           [checked]="currentAddress.localPrecaire==1">
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 align-self-center">
                    <label class="form-check-label mr-sm-2" for="CheckDemo4">Local temporaire </label>
                  </div>
                  <div class="col-sm-3">
                    <input class="form-check-input ml-sm-2" id="CheckDemo4" type="checkbox" [disabled]="!(isUO_AD() || isAdmin() ||isUO_ANT() || isUO_AN())"
                           name="localTemporaire{{index}}" [(ngModel)]="currentAddress.localTemporaire"
                           [checked]="currentAddress.localTemporaire==1">
                  </div>
                </div>

              </ng-container>

              <!-- information about the topology -->
              <div class="text-right">
                <button class="btn btn-info"
                        (click)="currentAddress.isDisplayed = !currentAddress.isDisplayed;">
                  <i class="fa fa-plus" *ngIf="!currentAddress.isDisplayed"></i>
                  <i class="fa fa-minus" *ngIf="currentAddress.isDisplayed"></i>
                </button>
              </div>

              <br/><br/>
            </div>
          </fieldset>
          <!-- contact information-->
          <fieldset class="static">
            <legend class="padding-titre">Informations de contact</legend>
            <div class="text-right">
              <button class="btn btn-info margin-bottom" (click)="addEmptyContact()">Ajouter un contact
              </button>
            </div>

            <div class="contacts"
                 *ngFor="let currentContact of contact; let index = index;trackBy : trackByFunction">
              <div class="form-inline margin-bottom">
                <button class="btn btn-outline-danger col-lg-3 mr-2"
                        [ngClass]="{'removeLocalisation':!currentContact.isDeleted,'restoreLocalisation':currentContact.isDeleted}"
                        id="boutonSupprContact{{index}}" (click)="changeStateContact(index)">Supprimer le
                  contact
                </button>

                <ng-container *ngIf="currentContact.idUOContact !== null else listeSelectContacts">
                  <select class="form-control col-lg-4 mr-2" disabled>
                    <option>{{currentContact.valeur}}</option>
                  </select>
                  <select class="form-control col-lg-4" disabled>
                    <option>{{currentContact.libelleLongTypologieMoyenContact}}</option>
                  </select>
                </ng-container>

                <ng-template #listeSelectContacts>
                  <input class="form-control col-lg-4" type="text" name="valueContact{{index}}"
                         [disabled]="currentContact.idUOContact !== null"
                         [(ngModel)]="currentContact.valeur" [ngClass]="{
                                          'errorInput': currentContact.valeur === null && isSubmited
                                        }">

                  <select class="form-control col-lg-4" name="contactTypo{{index}}"
                          [(ngModel)]="currentContact.libelleLongTypologieMoyenContact"
                          (change)="changeIdTypoContact(index)"
                          [disabled]="currentContact.idUOContact !== null" [ngClass]="{
                            'errorInput': currentContact.libelleLongTypologieMoyenContact === null && isSubmited
                           }">
                    <option *ngFor="let currentTypo of listTyposContacts"
                            [selected]="isGoodValue(currentContact.idTypoContact,currentTypo.idTypoContact)">
                      {{currentTypo.libelle}}
                    </option>
                  </select>
                </ng-template>

                <dt class="dateFin mr-sm-2" *ngIf="currentContact.isDeleted"> Date de fin :
                  <input class="col-lg-4 form-control dateFinInput " type="date"
                         name="dateFinCtc{{index}}"
                         [(ngModel)]="currentContact.dateFin" [ngClass]="{
                                                      'errorInput': currentContact.isDeleted && currentContact.dateFin === null && isSubmited
                                                    }"/>
                </dt>
              </div>
            </div>
          </fieldset>

          <fieldset class="static" *ngIf="parentsUo !== undefined">
            <legend class="padding-titre">Entité de rattachement :</legend>
            <div class="text-right">
              <button class="btn btn-info margin-bottom" (click)="addEmptyParent()" [disabled]="isUO_AD()">Ajouter une entité de rattachement
              </button>
            </div>
            <div class="uoParent" *ngFor="let parent of parentsUo; let index = index;trackBy : trackByFunction">
              <div class="form-inline margin-bottom">
                <button class="btn btn-outline-danger col-lg-3 mr-2" *ngIf="!parent.isNewFather"
                        [ngClass]="{'removeLocalisation':!parent.isDeleted,'restoreLocalisation':parent.isDeleted}"
                        id="boutonSupprParent{{index}}" (click)="changeStateParent(index)" [disabled]="isUO_AD()">Supprimer le
                  parent
                </button>
                <ng-container *ngIf="parent.idUoUo !== null else listeSelectParents">
                  <select class="form-control col-lg-4 mr-2" disabled>
                    <option>{{parent.nomLong}} - {{parent.typo}}</option>
                  </select>
                  <select class="form-control col-lg-3 " disabled>
                    <option>{{parent.typeRelation}}</option>
                  </select>
                </ng-container>

                <ng-template #listeSelectParents>
                  <!-- element on click changer id input hidden-->
                  <select class="form-control col-lg-4 mr-2" name="nomLongP{{index}}"
                          [(ngModel)]="parent.nomLong" (change)="changeIdParent(parent.idUO)"
                          [disabled]="parent.idUoUo !== null || parent.isNewFather" [ngClass]="{
                                                      'errorInput': parent.nomLong === null && isSubmited
                                                    }">

                    <option *ngFor="let uo of listAllUO;let indexUO = index;"
                            [attr.selected]="isGoodValue(uo.nomLong,parent.nomLong)"
                            value="{{uo.nomLong}}">
                      {{uo.nomLong}} - {{uo.typologies[0].libelleCourtTUO}}
                    </option>
                  </select>
                  <select class="form-control col-lg-3" name="typeRelationP{{index}}"
                          [(ngModel)]="parent.typeRelation"
                          [disabled]="parent.idUoUo !== null || parent.isNewRelation" [ngClass]="{
                                                      'errorInput': parent.typeRelation === null && isSubmited
                                                    }">
                    <option *ngFor="let relation of relations"
                            [selected]="isGoodValue(relation.libelle,parent.typeRelation)">
                      {{relation.libelle}}
                    </option>
                  </select>
                </ng-template>
                <dt class="dateFin" *ngIf="parent.isDeleted"> Date de fin :
                  <input class="form-control ml-3 col-lg-4 dateFinInput" type="date"
                         name="dateFinParent{{index}}"
                         [(ngModel)]="parent.dateFin" [ngClass]="{
                                          'errorInput': parent.isDeleted && parent.dateFin === null && isSubmited
                                        }"/>
                </dt>
              </div>
            </div>
          </fieldset>

          <fieldset class="static" *ngIf="sonsUo !== undefined">
            <legend class="padding-titre">Entité rattachée</legend>
            <div class="text-right">
              <button class="btn btn-info margin-bottom" (click)="addEmptySon()" [disabled]="isUO_AD()">Ajouter une entité rattachée
              </button>
            </div>
            <div class="uoSon" *ngFor="let son of sonsUo; let index = index">
              <div class="form-inline margin-bottom">
                <button class="btn btn-outline-danger col-lg-3 mr-2"
                        [ngClass]="{'removeLocalisation':!son.isDeleted,'restoreLocalisation':son.isDeleted}"
                        id="boutonSupprSon{{index}}" (click)="changeStateSon(index)" [disabled]="isUO_AD()">Supprimer l'enfant
                </button>

                <ng-container *ngIf="son.idUoUo !== null else listeSelectEnfants">
                  <select class="form-control col-lg-4 mr-2" disabled>
                    <option>{{son.nomLong}} - {{son.typo}}</option>
                  </select>
                  <select class="form-control col-lg-3" disabled>
                    <option>{{son.typeRelation}}</option>
                  </select>
                </ng-container>

                <ng-template #listeSelectEnfants>
                  <!-- element on click changer id input hidden-->
                  <select class="form-control col-lg-3 mr-2" name="nomLongE{{index}}" [(ngModel)]="son.nomLong"
                          (change)="changeIdSon(son.idUO)" [disabled]="son.idUoUo !== null" [ngClass]="{
                                'errorInput': son.typeRelation === null && isSubmited
                              }">
                    <ng-container *ngFor="let uo of listAllUO;let indexUO = index;">
                      <!-- template otherUO -->
                      <ng-container
                              *ngIf=" connexionDroitService.isAdmin() || uoAutorises.includes(uo.idUniteOrganisationnelle)">
                        <ng-template #otherUO>
                          <option value="{{uo.nomLong}}">
                            {{uo.nomLong}} - {{uo.typologies[0].libelleCourtTUO}}
                          </option>
                        </ng-template>
                        <ng-container *ngIf="isGoodValue(uo.nomLong,son.nomLong) else otherUO">
                          <option [attr.selected]="true" value="{{uo.nomLong}}">
                            {{uo.nomLong}} - {{uo.typologies[0].libelleCourtTUO}}
                          </option>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </select>
                  <select class="form-control col-lg-3" name="typeRelationE{{index}}"
                          [(ngModel)]="son.typeRelation" [disabled]="son.idUoUo !== null" [ngClass]="{
                              'errorInput': son.typeRelation === null && isSubmited
                            }">
                    <option *ngFor="let relation of relations"
                            [selected]="isGoodValue(relation.libelle,son.typeRelation)">
                      {{relation.libelle}}
                    </option>
                  </select>
                </ng-template>

                <dt class="dateFin" *ngIf="son.isDeleted"> Date de fin :
                  <input class="form-control col-lg-3 dateFinInput" type="date"
                         name="dateFinEnfant{{index}}"
                         [(ngModel)]="son.dateFin" [ngClass]="{
                              'errorInput': son.isDeleted && son.dateFin === null && isSubmited
                            }"/>
                </dt>
              </div>
            </div>
          </fieldset>

          <fieldset class="static">
            <legend class="padding-titre">Equipes (Activités de l’Entité) :</legend>
            <div class="text-right">
              <button class="btn btn-info margin-bottom" (click)="addEmptyActivite()">Ajouter une activité
              </button>
            </div>
            <div class="uoSon" *ngFor="let act of uoActivities; let index = index;trackBy : trackByFunction">
              <div class="form-inline margin-bottom">
                <ng-container *ngIf="act.dateFinActivite === null">
                  <button class="btn btn-outline-danger mr-sm-2" id="boutonSupprAct{{index}}"
                          (click)="changeStateActivite(index)"
                          [ngClass]="{'removeLocalisation':!act.isDeleted,'restoreLocalisation':act.isDeleted}">
                    Supprimer l'activité
                  </button>
                </ng-container>
                <ng-container *ngIf="act.idActivite !== null else listeSelectActivites">
                  <select class="form-control mr-sm-2" disabled>
                    <option>{{act.libelleLong}}<ng-container *ngIf="act.dateFinActivite !== null"> - Fin={{act.dateFinActivite}}</ng-container></option>
                  </select>
                </ng-container>

                <ng-template #listeSelectActivites>
                  <select class="form-control mr-sm-2" name="act{{index}}" [(ngModel)]="act.libelleLong"
                          [disabled]="act.idActivite !== null">
                    <ng-container *ngFor="let activite of sortedActivities; let index=index;">
                      <ng-container *ngIf="containsSonActivity(activite)">
                        <ng-container *ngIf="activite.sonsActivity.length === 0; else optgroupAct">
                          <option value="{{activite.libelleLong}}"
                                  [selected]="act.libelleLong === activite.libelleLong">
                            {{ activite.libelleLong}}
                          </option>
                        </ng-container>

                        <ng-template #optgroupAct>
                          <option value="{{activite.libelleLong}}"
                                  [selected]="act.libelleLong === activite.libelleLong"
                                  [disabled]="!containsActivity(activite)">{{activite.libelleLong}}</option>
                          <ng-container
                                  *ngTemplateOutlet="recursiveList; context:{ $implicit: activite.sonsActivity, index: 1}">
                          </ng-container>
                        </ng-template>
                      </ng-container>
                      <ng-template #recursiveList let-list let-index="index">
                        <ng-container>
                          <ng-container *ngFor="let item of list">
                            <ng-container *ngIf="containsSonActivity(item)">
                              <ng-container
                                      *ngIf="item.sonsActivity.length !== 0; else optgroupActSon">
                                <option value="{{item.libelleLong}}"
                                        [selected]="act.libelleLong === item.libelleLong"
                                        [disabled]="!containsActivity(item)">{{getTab(index) + item.libelleLong}}</option>
                                <ng-container
                                        *ngTemplateOutlet="recursiveList; context:{ $implicit: item.sonsActivity, index: index + 1 }">
                                </ng-container>
                              </ng-container>
                              <ng-template #optgroupActSon>
                                <option value="{{item.libelleLong}}"
                                        [selected]="act.libelleLong === item.libelleLong">
                                  {{ getTab(index) + item.libelleLong}}
                                </option>
                              </ng-template>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </ng-container>
                    <!--</ng-container>-->
                  </select>
                </ng-template>

                <dt class="dateFin" *ngIf="act.isDeleted"> Date de fin :
                  <input class="form-controlcol-lg-3 dateFinInput" type="date"
                         name="dateFinEquipe{{index}}"
                         [(ngModel)]="act.dateFinActivite" [ngClass]="{
                                       'errorInput': act.isDeleted && act.dateFinActivite === null && isSubmited
                                }"/>
                </dt>
              </div>
            </div>
          </fieldset>
        </div>
        <!-- submit button -->
        <button type="submit" class="btn btn-success pull-right margin-top margin-bottom-btn"
                (click)="saveData()"><span
                class="fa fa-check"></span> Sauvegarder
        </button>
        &nbsp;
        <button type="reset" class="btn btn-danger pull-right margin-top margin-bottom-btn"
                (click)="reset()"><span
                class="fa fa-remove"></span> Annuler
        </button>
      </form>

    </div>
</div>
