import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import {StatutUO} from '../models/StatutUO.model';

@Injectable({
  providedIn: 'root'
})
export class StatutUOService extends GetAndPost {
  /**
   * Constructor of our service
   * @param httpClient
   */
  constructor(httpClient: HttpClient) {
    super('statutUO', httpClient);
  }

  newElement(e: any) {
    if (e === null) {
      return new StatutUO(null, null, null, null);
    }
      return new StatutUO(
        e.id,
        e.libelleStatusUo,
        e.dateDebut,
        e.dateFin);
  }
}
