export class Activite {

  public isDeleted: boolean;
  public sonsActivity: Activite[];

  constructor(public idActivite: number,
              public libelleCourt: string,
              public libelleLong: String,
              public dateDebutActivite: Date,
              public dateFinActivite: Date,
              public idTypologieUo?: number,
              public parent?: number
  ) {
    this.isDeleted = false;
    this.sonsActivity = [];
  }
}
