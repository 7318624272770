import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostMissionService } from 'src/app/services/post-mission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MissionTypeService } from 'src/app/services/mission-type.service';
import { MissionType } from 'src/app/models/MissionType.model';

@Component({
  selector: 'app-interface-modification',
  templateUrl: './interface-modification.component.html',
  styleUrls: ['./interface-modification.component.css']
})

export class InterfaceModificationComponent implements OnInit {

  missionTypeForm : FormGroup;

  error : string;

  missionsType: MissionType[];

  missionTypeSelected : MissionType;

  idMissionType : string;

  constructor(private formBuilder : FormBuilder, 
    private missionTypeService : MissionTypeService, 
    private route: ActivatedRoute, 
    private router: Router) { }

  ngOnInit() {
    this.idMissionType = this.route.snapshot.params['id'];
  this.error = '';
    new Promise<string>((resolve, reject) => {
      this.missionTypeService.dataSubject.subscribe((missionsType: MissionType[]) => {
        this.missionsType = missionsType;
        resolve('ok');
      });
      this.missionTypeService.getWithValue('actif', 'false');
    }).then(() => {
      this.findMissionTypeSelected(Number(this.idMissionType));
      this.missionTypeForm = this.formBuilder.group({
        intitule : [this.missionTypeSelected.libelleMissionType, Validators.required],
        dateDebut : [this.missionTypeSelected.dateDebut, Validators.required],
        dateFin : [this.missionTypeSelected.dateFin]
      });
    });


  }

  findMissionTypeSelected(idMissionType: number) {
    this.missionsType.forEach(m => {
      if (m.idMissionType === idMissionType) {
        this.missionTypeSelected = m;
      }
    });
  }

  onSubmitForm() {
    if (this.missionTypeForm.value.intitule === '') {
      this.error =  'L\'intitulé de la mission type est requis.';
    } else {
      this.missionTypeService.putMissionType(this.idMissionType,
          this.missionTypeForm.value.intitule,
          this.missionTypeForm.value.dateDebut,
          this.missionTypeForm.value.dateFin)
          .subscribe(
              r => {
                localStorage.removeItem('missionsTypes');
                this.router.navigate(['mission']);
              },
              err => {
                this.error = err.error.message;
              }
          );
    }
  }

}
