import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MissionType } from '../../models/MissionType.model';
import { MissionTypeService } from '../../services/mission-type.service';
import { Activite } from 'src/app/models/Activite.model';
import { ActiviteService } from 'src/app/services/activite.service';
import { ContactService } from 'src/app/services/contact.service';
import { PersonneService } from 'src/app/services/personne.service';
import { ActivitesParUO } from '../../models/ActivitesParUO.model';
import { AdresseService } from 'src/app/services/adresse.service';
import { MissionTypePossible } from '../../models/MissionTypePossible.model';
import { MissionTypePossibleService } from 'src/app/services/mission-type-possible.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { TypologieUoService } from 'src/app/services/typologie-uo.service';
import { TypologieUO } from 'src/app/models/TypologieUO.model';
import {SavedPage} from '../../models/storage/SavedPage.model';
import {StackService} from '../../services/storage/stack.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-modification-mission-possible',
  templateUrl: './modification-mission-possible.component.html',
  styleUrls: ['./modification-mission-possible.component.css']
})

export class ModificationMissionPossibleComponent implements OnInit, OnDestroy, AfterViewInit {
  private _tabulationNumber: number;
  private sortedActivities: Activite[];
  private returnClicked: boolean;
  private config: any;
  private _renouvellement: boolean;
  
  public get renouvellement() : boolean {
    return this._renouvellement;
  }
  

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private missionTypeService: MissionTypeService,
    private activiteService: ActiviteService,
    private contactService: ContactService,
    private personneService: PersonneService,
    private adresseService: AdresseService,
    private missionTypePossibleService: MissionTypePossibleService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private typologieUoService: TypologieUoService,
    private stackService: StackService) {
    this.returnClicked = false;
    this._renouvellement = false;
    this.config = {
      'bgsColor': '#47BB9C',
      'bgsOpacity': 10,
      'bgsPosition': 'bottom-right',
      'bgsSize': 60,
      'bgsType': 'circle',
      'blur': 100,
      'fgsColor': '#47BB9C',
      'fgsPosition': 'center-center',
      'fgsSize': 90,
      'fgsType': 'three-strings',
      'gap': 47,
      'logoPosition': 'center-center',
      'logoSize': 400,
      'logoUrl': '',
      'masterLoaderId': 'master',
      'overlayBorderRadius': '0',
      'overlayColor': 'rgba(40, 40, 40, 0.8)',
      'pbColor': '#47BB9C',
      'pbDirection': 'ltr',
      'pbThickness': 4,
      'hasProgressBar': true,
      'text': 'Chargement des informations',
      'textColor': '#47BB9C',
      'textPosition': 'center-center',
      'threshold': 500
      };
  }


  private missionsTypePossible: MissionTypePossible[];
  private _missionPossibleSelected: MissionTypePossible;
  private activitesAll: ActivitesParUO[];
  distinctMissionType: MissionType[];
  activites: Activite[] = [];
  typologiesUo: TypologieUO[];
  postMissionForm: FormGroup;
  idMissionPossible: string;
  errorMessage: String;

  ngOnInit() {
    this.idMissionPossible = this.route.snapshot.paramMap.get('id');
    this.missionTypeService.dataSubject.subscribe((missions: MissionType[]) => {
      this.distinctMissionType = missions;
    });
    this.missionTypeService.getWithValue('actif', '1', 'sortProperty', 'libelleMissionType', 'sortDirection', 'asc');

    this.typologieUoService.dataSubject.subscribe((typologiesUO: TypologieUO[]) => {
      this.typologiesUo = typologiesUO;
    });
    this.typologieUoService.getWithValue('sortProperty', 'libelleLong', 'sortDirection', 'asc');
    this.activiteService.dataSubject.subscribe((activitesParUo: ActivitesParUO[]) => {
      this.activitesAll = activitesParUo;
      this.sortedActivities = this.sortActivitiesArray(this.buildActivitiesArray());
    });
    this.activiteService.getWithValue('actif', '1', 'sortProperty', 'libelleLong', 'sortDirection', 'asc');

    this.missionTypePossibleService.dataSubject.subscribe((missionsTypePossible: MissionTypePossible[]) => {
      this.missionsTypePossible = missionsTypePossible;
      this.findMissionPossible(Number(this.idMissionPossible));
    });
      this.missionTypePossibleService.getWithValue('actif', 'false');


  }

    findMissionPossible(idMissionPossible: number) {
      this.missionsTypePossible.forEach(m => {
      if (m.idMissionPossible === idMissionPossible) {
          this._missionPossibleSelected = m;
          if (this._renouvellement === false && !this.dateIsValid(this.missionPossibleSelected.dateDebut, this.missionPossibleSelected.dateFin)) {
              this._missionPossibleSelected.dateFin = null;
              this._renouvellement = true;
          } else {
              this._renouvellement = false;
          }
      }
    });
  }

    put() {
    if (this._missionPossibleSelected.libelleMissionPossible === '') {
      this.errorMessage = 'Erreur: Libelle mission possible manquant.';
    } else {
      this.missionTypePossibleService.putMissionTypePossible(this.idMissionPossible, this._missionPossibleSelected.libelleMissionPossible,
          String(this._missionPossibleSelected.idMissionType),
          String(this._missionPossibleSelected.idTypologieUO),
          String(this._missionPossibleSelected.idActivite),
          String(this._missionPossibleSelected.dateDebut), String(this._missionPossibleSelected.dateFin)).subscribe( () => {
            localStorage.removeItem('missionsPossibles');
            this.router.navigate(['missionPossible']);
          },
          err => {
            if (null != err.error.message) {
              this.errorMessage = 'Erreur: ' + err.error.message;
            }
          });
    }
  }

  private getTab(tabNumber: number) {
    let str = '';
    for (let i = 0; i < tabNumber; i++) {
      str += '\u00A0\u00A0\u00A0';
    }
    return str;
  }

  addTab() {
    this._tabulationNumber++;
    return true;
  }

  removeTab() {
    this._tabulationNumber--;
    return true;
  }

  /**
   *  this function will be used to build the activities array
   *  from the activitesAll array
   *  */
  activityIsContained(elt: Activite, array: Activite[]): boolean {
    let response = false;
    array.forEach(e => {
      response = e.idActivite === elt.idActivite || response;
      if (response) {
        return;
      } else {
        response = this.activityIsContained(elt, e.sonsActivity);
        if (response) {
          return;
        }
      }
    });
    return response;
  }

    buildActivitiesArray(): Activite[] {
    let array: Activite[] = [];
    this.activitesAll.forEach(e => {
      e.allActivites.forEach(elt => {
        if (!this.activityIsContained(elt, array)) {
          // if the parent is 0 then we don't have any parent
          if (!array.some(element => element.idActivite === elt.idActivite)) {
            if (elt.parent === 0) {
              // we add the element to the array
              array.push(elt);
            } else {
              // recursive function which will search and insert the element at the good position
              array = this.pushElt(elt, array);
            }
          }
        }
      });
    });
    return array;
  }

    private pushElt(elt: Activite, array: Activite[]): Activite[] {
    if (!array.some(element => element.idActivite === elt.idActivite)) {
      // for each element of our array
      array.forEach(e => {
        // if the parent is the current element
        if (e.idActivite === elt.parent && !array.some(elt2 => elt2.idActivite === elt.idActivite)) {
          // we add it
          e.sonsActivity.push(elt);
        } else {
          // if the sons array is empty we stop the recursivity and go back to the previous call
          if (e.sonsActivity === []) {
            return;
          } else {
            // recursivity on the sons array
            e.sonsActivity = this.pushElt(elt, e.sonsActivity);
          }
        }
      });
    }
    return array;
  }

    ngOnDestroy(): void {
    // /modificationMissionPossible/931
    if (!this.returnClicked) {
//      this.stackService.push(new SavedPage(null, null, '/modificationMissionPossible/' + this.idMissionPossible, null));
    }
  }

    handleLastPage() {
    this.returnClicked = true;
    const savedPage = this.stackService.peek();
    if (savedPage !== undefined) {
      if (savedPage.lastPage !== this.router.url) {
        const path = savedPage.lastPage.slice();
        this.stackService.pop();
        this.router.navigate([path]);
      } else {
        this.stackService.pop();
      }
    }
  }

    private sortActivitiesArray(array: Activite[]): Activite[] {
    const sortedArray = array.sort((n1, n2) => {
      if (n1.libelleLong == null && n2.libelleLong != null) {
        return 1;
      } else if (n1.libelleLong == null && n2.libelleLong != null) {
        return -1;
      }
      if (n1.libelleLong > n2.libelleLong) {
        return 1;
      }
      if (n1.libelleLong < n2.libelleLong) {
        return -1;
      }
      return 0;
    });
    sortedArray.forEach(element => {
      if (element.sonsActivity !== []) {
        element.sonsActivity = this.sortActivitiesArray(element.sonsActivity);
      }
    });
    return sortedArray;
  }

    ngAfterViewInit(): void {
    // this.ngxUiLoaderService.stopLoader(this.config.masterLoaderId, '1');
  }


  get missionPossibleSelected(): MissionTypePossible {
    return this._missionPossibleSelected;
  }

  set missionPossibleSelected(value: MissionTypePossible) {
    this._missionPossibleSelected = value;
  }

/*  checkRightsMission() {
    const role = localStorage.getItem('roles').split(',');
    return (role.includes('Ref_MS_RW') || role.includes('Ref_Admin'));

  }*/
  dateIsValid(dateDebut: any, dateFin: any): boolean {
    if (dateFin == null) {
      return true;
    }
    if (dateDebut === null) {
      dateDebut = new Date();
    }
    const currentDate = new Date();
    if (new Date(dateFin) <= currentDate) {
      this.errorMessage = 'Erreur: La date de fin ne peut pas être antérieure à la date du jour !';
    }
    return dateFin === '' || new Date(dateDebut) < currentDate && new Date(dateFin) > currentDate;
  }
}
