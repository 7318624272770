import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import {TypologieContact} from '../models/TypologieContact.model';

@Injectable({
  providedIn: 'root'
})
export class TypologieContactService extends GetAndPost {
  /**
   * Constructor of our service
   * @param httpClient
   */
  constructor(httpClient: HttpClient) {
    super('TypologieContact', httpClient);
  }

  /**
   * Methode qui sert à ??????????
   * @param e
   */
  newElement(e: any) {
    if (e === null) {
      return new TypologieContact( null, null);
    }
    return new TypologieContact( e.idTypoContact, e.libelleLong);
  }
}
