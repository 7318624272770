import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referentiel',
  templateUrl: './referentiel.component.html',
  styleUrls: ['./referentiel.component.css']
})
export class ReferentielComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  supprimerTypoContact() {
    alert("suppression typo contact");
  }
  supprimerTypoUO() {
    alert("suppression typo UO");
  }
  supprimerTypoAdresse() {
    alert("suppression typo Adresse");
  }
  activer() {
    alert("activer");
  }
}
