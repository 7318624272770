
<div class="formulaire-creation">
    <form [formGroup]="formMission" novalidate (ngSubmit)="onSubmit()">
        <fieldset class="static">
            <legend class="padding-titre"> Création d'une mission type </legend>
            <div class="form-group row align-items-center">
                <div class="col-sm-6">
                    <label class="mr-sm-6 col-form-label">Libelle mission type</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control" name="libelleMissionType" formControlName="libelleMissionType">
                </div>
            </div>
        </fieldset>
        <button type="submit" class="btn btn-success mt-1">Creer mission</button>
        <br />
        <span class="error">{{error}}</span>
    </form>
</div>
