import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { Activite } from '../models/Activite.model';
import { ActivitesParUO } from '../models/ActivitesParUO.model';
@Injectable({
  providedIn: 'root'
})
export class ActiviteService extends GetAndPost {
  allActivites: Activite[] = [];
  constructor(httpClient: HttpClient) {
    super('activite', httpClient);
    this.allActivites = [];
  }
  newElement(e: any): any {
    this.allActivites = [];
    if (e === null) {
      return new ActivitesParUO([], null, null, null);
    } else {
      e.allActivites.forEach(element => {
        this.allActivites.push(
            new Activite(element.idActivite,
                element.libelleCourt,
                element.libelleLong,
                element.dateDebutActivite,
                element.dateFinActivite,
                element.idTypologie,
                element.parent));
      });
      return new ActivitesParUO(this.allActivites,
          e.dateDebut,
          e.idUniteOrganisationnelle,
          e.dateFin);
    }
  }
}
