import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import {WINDOW_PROVIDERS} from './providers/window.provider';
import {UrlEnvironmentService} from './services/url-environment-service';
import { AppComponent } from './app.component';
import { TableauComponent } from './modules/interface-principale/tableau/tableau.component';
import { HeaderComponent } from './modules/header/header.component';
import { InfosCompteComponent } from './modules/header/infos-compte/infos-compte.component';
import { InterfacePrincipaleComponent } from './modules/interface-principale/interface-principale.component';
import { DetailsEntiteComponent } from './modules/interface-principale/details-entite/details-entite.component';
import { MenuComponent } from './modules/header/menu/menu.component';
import { InterfaceModificationComponent } from './modules/interface-modification/interface-modification.component';
import { InterfaceCreationComponent } from './modules/interface-creation/interface-creation.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './interceptor/timeout.interceptor';
import { ErrordialogComponent } from './services/errordialog/errordialog.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { RouterModule, Routes, Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReferentielComponent } from './modules/referentiel/referentiel.component';
import { ModificationUOComponent } from './modules/modification-uo/modification-uo.component';
import {LoginComponent} from './modules/login/login.component';
import { ModificationMissionPossibleComponent } from './modules/modification-mission-possible/modification-mission-possible.component';
import { EnConstructionComponent } from './modules/en-construction/en-construction.component';
import { DefaultPageComponent } from './modules/default-page/default-page.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';
import { CreationMissionPossibleComponent } from './modules/creation-mission-possible/creation-mission-possible.component';
import { AffichageUOComponent } from './modules/affichage-uo/affichage-uo.component';
import {NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule} from 'ngx-ui-loader';
import { AutofocusDirective } from './directives/autofocus.directive';
import {AuthGuard} from './services/guard/auth-guard.service';
import {AuthMSGuardService} from './services/guard/auth-msguard.service';
import {AuthUOGuardService} from './services/guard/auth-uoguard.service';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { NotificationComponent } from './modules/header/notifications/notifications.component';
import { environment } from './../environments/environment';

export const appRouteList: Routes = [

  {
    path: 'mission',
    canActivate: [AuthMSGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'uo',
    canActivate: [AuthGuard],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'uo/details/:id',
    canActivate: [AuthGuard],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'mission/details/:id',
    canActivate: [AuthMSGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'missionPossible/details/:id',
    canActivate: [AuthMSGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'creationMissionType',
    canActivate: [AuthMSGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'creationUO',
    canActivate: [AuthGuard],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'affichageUO/:id',
    canActivate: [AuthGuard],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'modificationMissionType/:id',
    canActivate: [AuthMSGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'modificationUO/:id',
    canActivate: [AuthUOGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'modificationMissionPossible/:id',
    canActivate: [AuthMSGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'creationMissionPossible',
    canActivate: [AuthMSGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'missionPossible',
    canActivate: [AuthMSGuardService],
    component: InterfacePrincipaleComponent
  },
  {
    path: 'referentiel',
    canActivate: [AuthGuard],
    component: ReferentielComponent
  },
  {
    path: 'default',
    component: DefaultPageComponent
  },
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: '**',
    component: EnConstructionComponent
  }
];

@NgModule({

  declarations: [

    AppComponent,
    TableauComponent,
    NotificationComponent,
    HeaderComponent,
    InfosCompteComponent,
    InterfacePrincipaleComponent,
    DetailsEntiteComponent,
    MenuComponent,
    InterfaceModificationComponent,
    InterfaceCreationComponent,
    ErrordialogComponent,
    ReferentielComponent,
    ModificationUOComponent,
    LoginComponent,
    ModificationMissionPossibleComponent,
    EnConstructionComponent,
    DefaultPageComponent,
    CreationMissionPossibleComponent,
    AffichageUOComponent,
    AutofocusDirective,
    HomepageComponent
  ],
  imports: [
    RouterModule.forRoot(appRouteList),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    StorageServiceModule,
    NgxLoadingModule.forRoot({}),
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule, // import this module for showing loader automatically when navigating between app routes
    NgxUiLoaderHttpModule,
    NgxPaginationModule,
    NgbModule
  ],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: environment.timeoutResponseAPI }],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard, AuthUOGuardService, AuthMSGuardService,
    WINDOW_PROVIDERS,UrlEnvironmentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private static NgbdModalBasic: any;
  constructor(private injector: Injector){    // Create global Service Injector.
    UrlEnvironmentService.injector = this.injector;
}
}
