import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Activite } from 'src/app/models/Activite.model';
import { ParentUo } from 'src/app/models/ParentUo.model';
import { TypologieUO } from 'src/app/models/TypologieUO.model';
import { ActiviteService } from 'src/app/services/activite.service';
import { AdresseService } from 'src/app/services/adresse.service';
import { ContactService } from 'src/app/services/contact.service';
import { InformationUOService } from 'src/app/services/information-uo.service';
import { MissionTypePossibleService } from 'src/app/services/mission-type-possible.service';
import { PersonneService } from 'src/app/services/personne.service';
import { UOService } from 'src/app/services/uo.service';
import { UOAllService } from 'src/app/services/uoall.service';
import { ActivitesParUO } from '../../../models/ActivitesParUO.model';
import { Adresse } from '../../../models/Adresse.model';
import { Contact } from '../../../models/Contact.model';
import { DataMission, DataMissionPossible, DataUO, Page } from '../../../models/data.model';
import { MissionType } from '../../../models/MissionType.model';
import { MissionTypePossible } from '../../../models/MissionTypePossible.model';
import { Personne } from '../../../models/Personne.model';
import { Relation } from '../../../models/relation.model';
import { SavedPage } from '../../../models/storage/SavedPage.model';
import { UO } from '../../../models/UO.model';
import { ConnexionDroitService } from '../../../services/connexion/connexion-droit.service';
import { MissionTypeService } from '../../../services/mission-type.service';
import { RelationUOService } from '../../../services/relation-uo.service';
import { SaveLastValueService } from '../../../services/save-last-value.service';
import { StackService } from '../../../services/storage/stack.service';
import { UoEnfantsService } from '../../../services/uo-enfant.service';
import { DetailsEntiteComponent } from '../details-entite/details-entite.component';
import { UoParentService } from './../../../services/uo-parent.service';
import { $, browser } from 'protractor';

@Component({

  selector: 'app-tableau',
  templateUrl: './tableau.component.html',
  styleUrls: ['./tableau.component.css']
})
export class TableauComponent implements OnInit, AfterViewInit, OnDestroy {

  private _config: any;
  UniteOrganisationnelleOnlyActive : UO[];

  @Input()
  tableau: DetailsEntiteComponent;

  @Input()
  page: Page;

  @Input()
  set dataUO(dataUO: DataUO) {
    this._dataUO = dataUO;
  }

  get dataUO(): DataUO {
    return this._dataUO;
  }

  @Input()
  set dataMission(dataMission: DataMission) {
    this._dataMission = dataMission;
  }

  get dataMission(): DataMission {
    return this._dataMission;
  }

  @Input()
  set dataMissionPossible(dataMissionPossible: DataMissionPossible) {
    this._dataMissionPossible = dataMissionPossible;
  }

  get dataMissionPossible(): DataMissionPossible {
    return this._dataMissionPossible;
  }

  // typeDef
  Page = Page;
  // attributes
  rechercherValue: string;
  clickPairId: boolean;
  clickPairMT: boolean;
  clickPairMP: boolean;
  clickPairDD: boolean;
  clickPairIdMT: boolean;
  clickPairMTNom: boolean;
  clickPairIdUO: boolean;
  clickPairNomLongUO: boolean;
  clickPairNomCourtUO: boolean;
  showToggle: boolean;
  clickTypoUO: boolean;
  clickPairTypology: boolean;
  loader: any;
  lastSelectedTab: string;
  clickPairDF: any;
  config: any;
  // default value for testing, set with route
  _dataUO: DataUO;
  _dataMission: DataMission;
  _dataMissionPossible: DataMissionPossible;
  private clickPairActivite: boolean;
  private clickPairMTDD: any;
  private clickPairMTDF: boolean;
  private returnClicked: boolean;
  private configPaginationUO: any;
  private configPaginationM: any;
  private configPaginationMP: any;
  // this variable is used by the NgxLoadingModule for display the loading icon
  // When the loading will be set to true
  public loading = false;
  missionsTypeFiltre: MissionType[] = [];
  missions: MissionType[];
  contacts: Contact[];
  personnes: Personne[];
  activitesParUO: ActivitesParUO[];
  activites: Activite[];
  adresses: Adresse[];
  missionPossibles: MissionTypePossible[];
  missionPossiblesFiltre: MissionTypePossible[];
  unitesOrganisationnelles: UO[];
  uoFillesTmp: UO[];
  uoFilles: UO[];
  uoFiltre: UO[];
  typologiesFiltre: TypologieUO[] = [];
  lastSort: string;
  uoAutorises: number[];
  lastSortOrder: string;
  lastSelected: number;
  lastSelectedFilter: string;
  private id: number;

  constructor(private missionTypeService: MissionTypeService,
    private activiteService: ActiviteService,
    private contactService: ContactService,
    private personneService: PersonneService,
    private adresseService: AdresseService,
    private missionTypePossibleService: MissionTypePossibleService,
    private uoService: UOService,
    private uoAllService: UOAllService,
    private informationUoService: InformationUOService,
    private router: Router,
    private uoParentService: UoParentService,
    private relationService: RelationUOService,
    private uoEnfantsService: UoEnfantsService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private stackService: StackService,
    private route: ActivatedRoute,
    private saveLastValueService: SaveLastValueService,
    private connexionDroitService: ConnexionDroitService,
    private location: Location) {

    this.UniteOrganisationnelleOnlyActive = new Array<UO>();

    this.location = location;
    this.configPaginationUO = {
      itemsPerPage: 10,
      currentPage: 1,
      id: 'uo'
    };
    this.configPaginationM = {
      itemsPerPage: 10,
      currentPage: 1,
      id: 'mission'
    };
    this.configPaginationMP = {
      itemsPerPage: 10,
      currentPage: 1,
      id: 'missionPossible'
    };
    this.config = {
      'bgsColor': '#47BB9C',
      'bgsOpacity': 0.5,
      'bgsPosition': 'bottom-right',
      'bgsSize': 60,
      'bgsType': 'circle',
      'blur': 7,
      'fgsColor': '#47BB9C',
      'fgsPosition': 'center-center',
      'fgsSize': 90,
      'fgsType': 'three-strings',
      'gap': 47,
      'logoPosition': 'center-center',
      'logoSize': 120,
      'logoUrl': '',
      'masterLoaderId': 'master',
      'overlayBorderRadius': '0',
      'overlayColor': 'rgba(40, 40, 40, 0.8)',
      'pbColor': '#47BB9C',
      'pbDirection': 'ltr',
      'pbThickness': 4,
      'hasProgressBar': true,
      'text': 'Chargement des informations',
      'textColor': '#47BB9C',
      'textPosition': 'center-center',
      'threshold': 500
    };
    this.uoAutorises = this.connexionDroitService.getUoAutorises();

  }

  ngOnInit() {
    this.clickPairId = true;
    this.clickPairMT = false;
    this.clickPairMP = false;
    this.clickPairTypology = false;
    this.clickPairDD = false;
    this.clickPairIdUO = true;
    this.clickPairId = true;
    this.clickPairIdMT = true;
    this.clickPairMTNom = false;
    this.clickPairNomLongUO = false;
    this.clickPairNomCourtUO = false;
    this.showToggle = false;
    this.clickTypoUO = false;


    if (this.router.url.startsWith('/uo/details')) {
      const idLink: number = Number(this.route.snapshot.paramMap.get('id'));
      this.dataUO.tabFiltre.forEach((e, index) => {
        if (e.idUniteOrganisationnelle === idLink) {
          this.dataUO.idx = index;
        }
      });
    } else if (this.router.url.startsWith('/mission/details')) {
      const idLink: number = Number(this.route.snapshot.paramMap.get('id'));
      this.dataMission.tabFiltre.forEach((e, index) => {
        if (e.idMissionType === idLink) {
          this.dataMission.idx = index;
        }
      });
    } else if (this.router.url.startsWith('/missionPossible/details')) {
      const idLink: number = Number(this.route.snapshot.paramMap.get('id'));
      this.dataMissionPossible.tabFiltre.forEach((e, index) => {
        if (e.idMissionPossible === idLink) {
          this.dataMissionPossible.idx = index;
        }
      });
    }
  }

  ngOnDestroy() {
    if (!this.returnClicked) {
      this.saveIfNotLastElement();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // recuperation de la dernier statut par page
      this.rechercherValue = this.saveLastValueService.getSearchedValue(this.page);
      this.lastSelectedFilter = this.saveLastValueService.getselectedFilter(this.page);
      this.lastSortOrder = this.saveLastValueService.getSortOrder(this.page);
      this.lastSelectedTab = this.saveLastValueService.getselectedTab(this.page);

      if (this.rechercherValue !== '' && !(this.router.url.startsWith('/modification') || this.router.url.startsWith('/creation')
        || this.router.url.startsWith('/affichage'))) {
        this.doRecherche();
        this.handleLastPage();
      }
      if (this.lastSelectedFilter !== '') {
        switch (this.lastSelectedFilter) {
          case 'IDUO':
            this.clickPairId = this.isSortOrderAsc();
            this.sortByIdUO();
            break;
          case 'TYPOUO':
            this.clickTypoUO = this.isSortOrderAsc();
            this.sortByTypoUO();

            break;
          case 'NOMCOURTUO':
            this.clickPairNomCourtUO = this.isSortOrderAsc();
            this.sortByNomCourtUO();
            break;
          case 'NOMLONGUO':
            this.clickPairNomLongUO = this.isSortOrderAsc();
            this.sortByNomLongUO();
            break;
          // mission type
          case 'IDMT':
            this.clickPairIdMT = this.isSortOrderAsc();
            this.sortByIdMT();
            break;
          case 'MTNOM':
            this.clickPairMTNom = this.isSortOrderAsc();
            this.sortByMTNom();
            break;
          case 'MTDD':
            this.clickPairMTDD = this.isSortOrderAsc();
            this.sortByMTDD();
            break;
          case 'MTDF':
            this.clickPairMTDF = this.isSortOrderAsc();
            this.sortByMTDF();
            break;
          // mission possible
          case 'ID':
            this.clickPairId = this.isSortOrderAsc();
            this.sortByID();
            break;
          case 'MP':
            this.clickPairMP = this.isSortOrderAsc();
            this.sortByMP();
            break;
          case 'MT':
            this.clickPairMT = this.isSortOrderAsc();
            this.sortByMT();
            break;
          case 'TYPOLOGY':
            this.clickPairTypology = this.isSortOrderAsc();
            this.sortByTypology();
            break;
          case 'ACTIVITE':
            this.clickPairActivite = this.isSortOrderAsc();
            this.sortByActivite();
            break;
          case 'DD':
            this.clickPairDD = this.isSortOrderAsc();
            this.sortByDD();
            break;
          case 'DF':
            this.clickPairDF = this.isSortOrderAsc();
            this.sortByMPDF();
            break;
        }

      }

      if (localStorage.getItem('uo') !== null && this.lastSelectedTab !== '-1' &&
      !(this.router.url.startsWith('/modification') || this.router.url.startsWith('/creation')
      || this.router.url.startsWith('/affichage'))) {
        this.onClicTab(parseInt(this.lastSelectedTab));
      }

      const poppedSavedPage = this.stackService.poppedSavedPage;
      if (poppedSavedPage !== null) {
        this.handleSortCache(poppedSavedPage.lastSort, poppedSavedPage.lastSortOrder);
        this.lastSort = poppedSavedPage.lastSort;
        this.lastSortOrder = poppedSavedPage.lastSortOrder;
        this.id = poppedSavedPage.lastSelectedElement;
        if (poppedSavedPage.lastPage === '/uo') {
          document.getElementById('idUO' + this.id).click();
        } else if (poppedSavedPage.lastPage === '/mission' &&
          document.getElementById('idM' + this.id)
        ) {
          document.getElementById('idM' + this.id).click();
        } else if (poppedSavedPage.lastPage === '/missionPossible' &&
          document.getElementById('idMP' + this.id)) {
          document.getElementById('idMP' + this.id).click();
        }
        this.stackService.pop();
        this.stackService.clearPoppedSavedPage();
      }
    });
  }

  private isSortOrderAsc(): boolean {
    return this.lastSortOrder === 'ASC' ? true : false;
  }
  return(){ 
      this.supprimerFiltre();
      //this.location.back();
  }
  handleLastPage() {
    this.returnClicked = true;
    const savedPage = this.stackService.peek();
    if (savedPage !== undefined && savedPage !== null) {
      const splittedUrl = this.router.url.split('/');
      const splittedLastPage = savedPage.lastPage.split('/');
      if ((splittedUrl[0] === splittedLastPage[0]) && (splittedUrl[1] === splittedLastPage[1])) {
        this.handleSortCache(savedPage.lastSort, savedPage.lastSortOrder);
        this.lastSort = savedPage.lastSort;
        this.lastSortOrder = savedPage.lastSortOrder;
        this.id = savedPage.lastSelectedElement;
        if (savedPage.lastPage.startsWith('/uo/details/') && document.getElementById('idUO' + this.id)) {
          document.getElementById('idUO' + this.id).click();
        } else if (savedPage.lastPage.startsWith('/mission/details/') && document.getElementById('idM' + this.id)) {
          document.getElementById('idM' + this.id).click();
        } else if (savedPage.lastPage.startsWith('/missionPossible/details/') && document.getElementById('idMP' + this.id)) {
          document.getElementById('idMP' + this.id).click();
        }
        this.stackService.pop();
        this.stackService.clearPoppedSavedPage();
      } else {
        const path = savedPage.lastPage.slice();
        this.stackService.pop();
        this.stackService.clearPoppedSavedPage();
        this.router.navigate([path]);
      }
    }
  }

  isExistPage() {
    return undefined != this.stackService.peek();
  }


  getCorrectLabel(page: string, lastSortCached: string, lastSortOrderCached: string): string {
    if (page === 'uo') {
      switch (lastSortCached) {
        case 'IDUO':
          this.clickPairIdUO = lastSortOrderCached === 'ASC' ? true : false;
          return 'idUniteOrganisationnelle';
          break;
        case 'NOMCOURTUO':
          this.clickPairIdUO = false;
          this.clickPairNomCourtUO = lastSortOrderCached === 'ASC' ? true : false;
          return 'nomCourt';
          break;
        case 'NOMLONGUO':
          this.clickPairIdUO = false;
          this.clickPairNomLongUO = lastSortOrderCached === 'ASC' ? true : false;
          return 'nomLong';
          break;
        case 'TYPOUO':
          this.clickPairIdUO = false;
          this.clickTypoUO = lastSortOrderCached === 'ASC' ? true : false;
          return 'typologies.libelleCourtUO';
          break;
        default:
          break;
      }
    } else if (page === 'mission') {
      switch (lastSortCached) {
        case 'IDMT':
          this.clickPairIdMT = lastSortOrderCached === 'ASC' ? true : false;
          return 'idMissionType';
          break;
        case 'MT':
          this.clickPairIdMT = false;
          this.clickPairMTNom = lastSortOrderCached === 'ASC' ? true : false;
          return 'libelleMissionType';
          break;
        case 'MTDD':
          this.clickPairIdMT = false;
          this.clickPairMTDD = lastSortOrderCached === 'ASC' ? true : false;
          return 'dateDebut';
          break;
        case 'MTDF':
          this.clickPairIdMT = false;
          this.clickPairMTDF = lastSortOrderCached === 'ASC' ? true : false;
          return 'dateFin';
          break;
        default:
          break;
      }
    } else if (page === 'missionPossible') {
      switch (lastSortCached) {
        case 'ID':
          this.clickPairId = lastSortOrderCached === 'ASC' ? true : false;
          return 'idMissionPossible';
          break;
        case 'MT':
          this.clickPairId = false;
          this.clickPairMT = lastSortOrderCached === 'ASC' ? true : false;
          return 'libelleMissionType';
          break;
        case 'MP':
          this.clickPairId = false;
          this.clickPairMTDD = lastSortOrderCached === 'ASC' ? true : false;
          return 'libelleMissionPossible';
          break;
        case 'ACTIVITE':
          this.clickPairId = false;
          this.clickPairActivite = lastSortOrderCached === 'ASC' ? true : false;
          return 'libelleActivite';
          break;
        case 'TYPOLOGY':
          this.clickPairId = false;
          this.clickPairTypology = lastSortOrderCached === 'ASC' ? true : false;
          return 'libelleTypologieUOCourt';
          break;
        case 'MPDD':
          this.clickPairId = false;
          this.clickPairDD = lastSortOrderCached === 'ASC' ? true : false;
          return 'dateDebut';
          break;
        case 'MPDF':
          this.clickPairId = false;
          this.clickPairDF = lastSortOrderCached === 'ASC' ? true : false;
          return 'dateFin';
          break;
        default:
          break;
      }
    } else {
      return '';
    }
  }

  sortSpecificPage(array: any[], lastSortCached: string, lastSortOrderCached: string) {
    if (lastSortOrderCached === 'ASC') {
      this.sortByElementAsc(array, lastSortCached);
    } else if (lastSortOrderCached === 'DESC') {
      this.sortByElementDesc(array, lastSortCached);
    }
  }

  handleSortCache(lastSortCached: string, lastSortOrderCached: string): void {
    if (this.page === Page.UO || this.router.url.startsWith('creationUO') || this.router.url.startsWith('modificationUO')) {
      this.sortSpecificPage(
        this.dataUO.tabFiltre, this.getCorrectLabel('uo', lastSortCached, lastSortOrderCached), lastSortOrderCached);
    } else if (this.page === Page.mission || this.router.url.startsWith('creationMissionType') || this.router.url.startsWith('modificationMissionType')) {
      this.sortSpecificPage(
        this.dataMission.tabFiltre, this.getCorrectLabel('mission', lastSortCached, lastSortOrderCached), lastSortOrderCached);
    } else {
      this.sortSpecificPage(
        this.dataMissionPossible.tabFiltre,
        this.getCorrectLabel('missionPossible', lastSortCached, lastSortOrderCached), lastSortOrderCached);
    }
  }

  setRecherche(event) {
    this.rechercherValue = event.target.value; 
  }

  onClicTab(i: number) {
    this.saveLastValueService.insertSelectedTab(this.page, i);
    this.loading = true;
    // The page is UO
    if (this.page === Page.UO) {
      try {
        this.ngxUiLoaderService.startLoader(this.config.masterLoaderId, '1');
      } catch (e) {
        console.error(e);
      }
      this.saveIfNotLastElement();
      if(this.dataUO.tabFiltre[i + this.configPaginationUO.itemsPerPage * (this.configPaginationUO.currentPage - 1)]===undefined){
        this.id = 1;
      }else{
        this.id = this.dataUO.tabFiltre[i + this.configPaginationUO.itemsPerPage * (this.configPaginationUO.currentPage - 1)].idUniteOrganisationnelle;
      }
      
      this.dataUO.idx = i;
      this.tableau.uoFillesDetails = [];
      this.tableau.activitesDetails = [];
      this.tableau.contacts = [];
      this.tableau.adresses = [];
      this.tableau.listeRelationFille = [];
      this.tableau.uoParentsDetails = [];
      this.tableau.uoFilles = [];
      // We get all data needed from the api, when they are recieved we sort the 'uofilles' array.
      Promise.all
        ([this.getPromiseFromActivites(), this.getPromiseFromAdresse(), this.getPromiseFromContacts(),
        this.getPromiseFromParentsUO(), this.getPromiseFromSons()])
        .then(() => {
          try {
            this.ngxUiLoaderService.stopLoader(this.config.masterLoaderId, '1');
            this.location.go('/uo/details/' + this.id);
          } catch (e) {
          }
          this.loading = false;
        }).catch(error => {
          console.error(error);
        });
    }

    if (this.page === Page.mission) {
      this.saveIfNotLastElement();
      this.id = this.dataMission.tabFiltre[i + this.configPaginationM.itemsPerPage * (this.configPaginationM.currentPage - 1)].idMissionType;
      this.dataMission.idx = i + this.configPaginationM.itemsPerPage * (this.configPaginationM.currentPage - 1);
      try {
        this.router.navigate(['/mission/details/' + this.id]);
      } catch (e) {
      }
      this.loading = false;
    }

    if (this.page === Page.missionPossible) {
      this.saveIfNotLastElement();
      this.id = this.dataMissionPossible.tabFiltre[i + this.configPaginationMP.itemsPerPage * (this.configPaginationMP.currentPage - 1)].idMissionPossible;
      this.dataMissionPossible.idx = i + this.configPaginationMP.itemsPerPage * (this.configPaginationMP.currentPage - 1);
      try {
        this.router.navigate(['/missionPossible/details/' + this.id]);
      } catch (e) {
      }
      this.loading = false;
    }
    localStorage.setItem('lastSelected', '' + this.id);

  }

  getPromiseFromUoFilles() {
    return new Promise((resolve, reject) => {
      this.uoService.dataSubject.subscribe((unitesOrganisationnelles: UO[]) => {
        this.tableau.uoFillesDetails = unitesOrganisationnelles;
        resolve('Uo filles ok');
      });
      const string= (this.dataUO.tabFiltre[this.dataUO.idx]===undefined)?undefined:this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle;
      this.uoService.getWithValue('idUO', String(string));
    });
  }

  getPromiseFromSons() {
    return new Promise((resolve) => {
      // get sonsuo informations
      this.uoEnfantsService.dataSubject.subscribe((unitesOrganisationnelles: ParentUo[]) => {
        this.tableau.uoFilles = unitesOrganisationnelles;
        resolve('SONS OK');
      });
      const parameter= (this.dataUO.tabFiltre[this.dataUO.idx]===undefined)?undefined:this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle;
      this.uoEnfantsService.getActiveWithId(parameter);
    });
  }

  getPromiseFromParentsUO() {
    return new Promise((resolve, reject) => {
      // get uo informations
      this.tableau.uoParentsDetails = [];
      this.uoParentService.dataSubject.subscribe((parents: ParentUo[]) => {
        this.tableau.uoParentsDetails = parents;
        resolve('PARENT UO OK');
      });
      const parameter= (this.dataUO.tabFiltre[this.dataUO.idx]===undefined)?undefined:this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle;
      this.uoParentService.getActiveWithId(parameter);
    });
  }

  getPromiseFromActivites() {
    return new Promise((resolve, reject) => {
      if (this.dataUO.tabFiltre[this.dataUO.idx]!==undefined && localStorage.getItem('uoActivites' + this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle) != null) {
        this.activitesParUO = JSON.parse(localStorage.getItem('uoActivites' + this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle));
        this.tableau.activitesDetails = JSON.parse(localStorage.getItem('activitesParUO' + this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle));
        resolve('Activité ok');
      } else {
        this.activiteService.dataSubject.subscribe((activites: ActivitesParUO[]) => {
          if (activites.length > 0) {
            this.activitesParUO = activites;
            if (activites[0].allActivites !== []) {
              this.tableau.activitesDetails = activites[0].allActivites;
            }
          }
          resolve('Activité ok');
        });
      }
      const string= (this.dataUO.tabFiltre[this.dataUO.idx]===undefined)?undefined:this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle;
      this.activiteService.getWithValue(
        'idUO',
        String(string),
        'active',
        'true');
    });
  }

  getPromiseFromContacts() {
    return new Promise((resolve, reject) => {
      this.contactService.dataSubject.subscribe((contacts: Contact[]) => {
        this.tableau.contacts = contacts;
        resolve('contact ok');
      });
      const string= (this.dataUO.tabFiltre[this.dataUO.idx]===undefined)?undefined:this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle;
      this.contactService.getWithValue(
        'idUO',
        String(string),
        'active',
        'true');
    });
  }

  getPromiseFromAdresse() {
    return new Promise((resolve, reject) => {
      this.adresseService.dataSubject.subscribe((adresse: Adresse[]) => {
        this.tableau.adresses = adresse;
        localStorage.setItem('adresses' + this.dataUO.tab[this.dataUO.idx].idUniteOrganisationnelle,
          JSON.stringify(this.tableau.adresses));
        resolve('adresse ok');

      });
      const string= (this.dataUO.tabFiltre[this.dataUO.idx]===undefined)?undefined:this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle;
      this.adresseService.getWithValue(
        'idUO',
        String(string),
        'active', 'true',
        'complet',
        'true');
    });
  }

  getPromiseFromRelationUO(e: UO) {
    return new Promise((resolve) => {
      // get uo informations
      this.relationService.dataSubject.subscribe((relation: Relation[]) => {
        this.tableau.listeRelationFille = relation;
      });
      const string= (this.dataUO.tabFiltre[this.dataUO.idx]===undefined)?undefined:this.dataUO.tabFiltre[this.dataUO.idx].idUniteOrganisationnelle;
      this.relationService.getWithValue(
        'idUOP',
        String(string),
        'idUOF',
        String(e.idUniteOrganisationnelle));
    });
  }

  @HostListener('document:keypress', ['$event'])
  search(event: KeyboardEvent) {
    this.setRecherche(event);
    if (event.keyCode === 13) {
      this.doRecherche();
      this.handleLastPage();
    }
  }

  isNotErreurUO() {
    return !this.dataUO || null === this.dataUO.erreurChargement;
  }

  ChangeUOInactiveVisibilite(event){

    if(!event.target.checked)
    {
      console.log( "faux :" +  event.target.checked);
      this.uoAllService.dataSubject.subscribe((unitesOrganisationnelles: UO[]) => {
        
        this.dataUO.tab = unitesOrganisationnelles.slice();
        this.dataUO.tabFiltre = this.dataUO.tab.slice();

        unitesOrganisationnelles = this.dataUO.tab.slice(0,1);
        this.dataUO.tab.forEach(e => {
           if(e.estActive === 1){
             this.UniteOrganisationnelleOnlyActive.push(
              new UO(
                e.idUniteOrganisationnelle,
                e.nomCourt,
                e.nomLong,
                e.idStatus,
                e.libelleStatus,
                e.typologies,
                e.dateDebut,
                e.siret,
                e.dateFin,
                e.estActive,
                e.statutValidation)
             );
           }
        })

        localStorage.removeItem('uo');
        localStorage.setItem('uo', JSON.stringify(this.UniteOrganisationnelleOnlyActive));
        this.ngxUiLoaderService.stopLoader(this._config.masterLoaderId, 'ipuo');
        
      });

      this.uoAllService.getWithValueActiveUO();
    }
    else
    {
      console.log(event.target.checked);
        this.uoAllService.dataSubject.subscribe((unitesOrganisationnelles: UO[]) => {
          this.dataUO.tab = unitesOrganisationnelles.slice();
          this.dataUO.tabFiltre = this.dataUO.tab.slice();
  
          unitesOrganisationnelles = this.dataUO.tab.slice(0,1);
          localStorage.removeItem('uo');
          localStorage.setItem('uo', JSON.stringify(this.dataUO.tab));
          this.ngxUiLoaderService.stopLoader(this._config.masterLoaderId, 'ipuo');
          
        });
        this.uoAllService.getWithValueAllUO();
    }

  }

  

  // dataMissionPossible -> constant
  //
  sortByElement(list: any[], str: string, sortDirection: boolean): any {
    sortDirection = !sortDirection;
    localStorage.setItem('lastSort', this.lastSort);
    if (sortDirection) {
      this.lastSortOrder = 'ASC';
      localStorage.setItem('lastSortOrder', this.lastSortOrder);
      this.sortByElementAsc(list, str);
    } else {
      this.lastSortOrder = 'DESC';
      localStorage.setItem('lastSortOrder', this.lastSortOrder);
      this.sortByElementDesc(list, str);
    }
    return sortDirection;
  }

  sortByElementAsc(list: any[], str: string) {
    list.sort((n1, n2) => {
      let n1Elt = n1.getElementFromString(str);
      let n2Elt = n2.getElementFromString(str);
      if (n1Elt == null && n2Elt != null) {
        return 1;
      } else if (n2Elt == null && n1Elt != null) {
        return -1;
      }
      if (typeof n1Elt === 'string' && typeof n2Elt === 'string') {
        n1Elt = n1Elt.toLocaleLowerCase();
        n2Elt = n2Elt.toLocaleLowerCase();
      }
      if (n1Elt > n2Elt) {
        return 1;
      }
      if (n1Elt < n2Elt) {
        return -1;
      }
      return 0;
    });
  }

  sortByElementDesc(list: any[], str: string) {
    list.sort((n1, n2) => {
      let n1Elt = n1.getElementFromString(str);
      let n2Elt = n2.getElementFromString(str);
      if (n1Elt == null && n2Elt != null) {
        return 1;
      } else if (n2Elt == null && n1Elt != null) {
        return -1;
      }
      if (typeof n1Elt === 'string' && typeof n2Elt === 'string') {
        n1Elt = n1Elt.toLocaleLowerCase();
        n2Elt = n2Elt.toLocaleLowerCase();
      }
      if (n1Elt < n2Elt) {
        return 1;
      }
      if (n1Elt > n2Elt) {
        return -1;
      }
      return 0;
    });
  }

  /*   START  DATAMISSIONPOSSIBLE   */

  sortByID() {
    this.lastSort = 'ID';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairDD = false;
    this.clickPairMT = false;
    this.clickPairDF = false;
    this.clickPairTypology = false;
    this.clickPairMP = false;
    this.clickPairId = this.sortByElement(this.dataMissionPossible.tabFiltre, 'idMissionPossible', this.clickPairId);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairId ? 'ASC' : 'DESC');
  }

  checkMPSortedArray(): void {
    if (this.dataMissionPossible.tabFiltre === undefined) {
      this.dataMissionPossible.tabFiltre = this.dataMissionPossible.tab.slice();
    }
  }

  sortByMT() {
    this.lastSort = 'MT';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairDD = false;
    this.clickPairDF = false;
    this.clickPairId = false;
    this.clickPairMP = false;
    this.clickPairActivite = false;
    this.clickPairTypology = false;
    this.clickPairMT = this.sortByElement(this.dataMissionPossible.tabFiltre, 'libelleMissionType', this.clickPairMT);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairMT ? 'ASC' : 'DESC');
  }

  sortByMP() {
    this.lastSort = 'MP';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairDD = false;
    this.clickPairId = false;
    this.clickPairMT = false;
    this.clickPairTypology = false;
    this.clickPairDF = false;
    this.clickPairActivite = false;
    this.checkMPSortedArray();
    this.clickPairMP = this.sortByElement(this.dataMissionPossible.tabFiltre, 'libelleMissionPossible', this.clickPairMP);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairMP ? 'ASC' : 'DESC');
  }

  sortByTypology() {
    this.lastSort = 'TYPOLOGY';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairDD = false;
    this.clickPairId = false;
    this.clickPairMT = false;
    this.clickPairMP = false;
    this.clickPairDF = false;
    this.clickPairActivite = false;
    this.checkMPSortedArray();
    this.clickPairTypology = this.sortByElement(this.dataMissionPossible.tabFiltre, 'libelleTypologieUOCourt', this.clickPairTypology);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairTypology ? 'ASC' : 'DESC');
  }

  sortByDD() {
    this.lastSort = 'DD';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairActivite = false;
    this.clickPairId = false;
    this.clickPairMT = false;
    this.clickPairMP = false;
    this.clickPairTypology = false;
    this.clickPairDF = false;
    this.checkMPSortedArray();
    this.clickPairDD = this.sortByElement(this.dataMissionPossible.tabFiltre, 'dateDebut', this.clickPairDD);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairDD ? 'ASC' : 'DESC');
  }

  /*   END  DATAMISSIONPOSSIBLE   */
  /*------------------------------*/

  /*  START DATAMISSION   */

  sortByIdMT() {
    this.lastSort = 'IDMT';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairMTNom = false;
    this.clickPairMTDF = false;
    this.clickPairMTDD = false;
    this.clickPairIdMT = this.sortByElement(this.dataMission.tabFiltre, 'idMissionType', this.clickPairIdMT);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairIdMT ? 'ASC' : 'DESC');
  }

  sortByMTNom() {
    this.lastSort = 'MTNOM';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairIdMT = false;
    this.clickPairMTDF = false;
    this.clickPairMTDD = false;
    this.clickPairMTNom = this.sortByElement(this.dataMission.tabFiltre, 'libelleMissionType', this.clickPairMTNom);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairMTNom ? 'ASC' : 'DESC');
  }

  /*  END DATAMISSION   */
  /*--------------------*/

  /*  START DATAUO   */

  sortByIdUO() {
    this.lastSort = 'IDUO';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickTypoUO = false;
    this.clickPairNomCourtUO = false;
    this.clickPairNomLongUO = false;
    this.clickPairIdUO = this.sortByElement(this.dataUO.tabFiltre, 'idUniteOrganisationnelle', this.clickPairIdUO);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairIdUO ? 'ASC' : 'DESC');
  }

  sortByNomLongUO() {
    this.lastSort = 'NOMLONGUO';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickTypoUO = false;
    this.clickPairNomCourtUO = false;
    this.clickPairIdUO = false;
    this.clickPairNomLongUO = this.sortByElement(this.dataUO.tabFiltre, 'nomLong', this.clickPairNomLongUO);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairNomLongUO ? 'ASC' : 'DESC');
  }

  sortByNomCourtUO() {
    this.lastSort = 'NOMCOURTUO';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickTypoUO = false;
    this.clickPairIdUO = false;
    this.clickPairNomLongUO = false;
    this.clickPairNomCourtUO = this.sortByElement(this.dataUO.tabFiltre, 'nomCourt', this.clickPairNomCourtUO);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairNomCourtUO ? 'ASC' : 'DESC');
  }

  sortByTypoUO() {
    this.lastSort = 'TYPOUO';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairIdUO = false;
    this.clickPairNomCourtUO = false;
    this.clickPairNomLongUO = false;
    this.clickTypoUO = this.sortByElement(this.dataUO.tabFiltre, 'typologies.libelleCourtUO', this.clickTypoUO);
    this.saveLastValueService.insertSortOrder(this.page, this.clickTypoUO ? 'ASC' : 'DESC');
  }

  /*  END DATAUO  */

  doRecherche() {
    this.saveLastValueService.insertSearchValue(this.page, this.rechercherValue);
    this.saveLastValueService.deleteSelectedTab(this.page);
    if (this.router.url.startsWith('/mission') || this.router.url.startsWith('/creationMissionType') || this.router.url.startsWith('/modificationMissionType')) {
      this.missionsTypeFiltre = [];
      this.dataMission.tab.forEach(e => {
        if (e.libelleMissionType.toUpperCase().includes(this.rechercherValue.toUpperCase()) ||
          e.idMissionType === Number(this.rechercherValue)) {
          this.missionsTypeFiltre.push(new MissionType(e.idMissionType, e.libelleMissionType, e.dateDebut, e.dateFin));
        }
      });
      this.dataMission.tabFiltre = this.missionsTypeFiltre;
    }
    if (this.router.url.startsWith('/missionPossible') || this.router.url.startsWith('/creationMissionPossible')
      || this.router.url.startsWith('/modificationMissionPossible')) {
      this.missionPossiblesFiltre = [];
      this.dataMissionPossible.tab.forEach(e => {
        if (e.libelleMissionType.toUpperCase().includes(this.rechercherValue.toUpperCase()) ||
          e.libelleActivite.toUpperCase().includes(this.rechercherValue.toUpperCase()) ||
          e.libelleTypologieUOCourt.toUpperCase().includes(this.rechercherValue.toUpperCase()) ||
          e.idMissionPossible === Number(this.rechercherValue)) {

          // Add mission in the mission possible sorted array
          this.missionPossiblesFiltre.push(new MissionTypePossible(
            e.idMissionPossible,
            e.libelleMissionPossible,
            e.idMissionType,
            e.libelleMissionType,
            e.idTypologieUO,
            e.libelleTypologieUOLong,
            e.libelleTypologieUOCourt,
            e.idActivite,
            e.dateDebut,
            e.libelleActivite,
            e.dateFin));
        }
      });
      this.dataMissionPossible.tabFiltre = this.missionPossiblesFiltre;
    }
    if (this.router.url.startsWith('/uo') || this.router.url.startsWith('/creationUO')
      || this.router.url.startsWith('/modificationUO')) {
      this.uoFiltre = [];
      this.dataUO.tab.forEach(e => {
        if (e.nomLong.toUpperCase().includes(
          this.rechercherValue.toUpperCase()) ||
          (e.nomCourt != null && e.nomCourt.toUpperCase().includes(this.rechercherValue.toUpperCase())) ||
          e.idUniteOrganisationnelle === Number(this.rechercherValue)) {
          e.typologies.forEach(element => {
            this.typologiesFiltre.push(
              new TypologieUO(
                element.idTypologieUo,
                element.libelleCourtTUO,
                element.libelleLongTUO,
                element.dateDebutTUO,
                element.dateFinTUO));
          });
          this.uoFiltre.push(
            new UO(
              e.idUniteOrganisationnelle,
              e.nomCourt,
              e.nomLong,
              e.idStatus,
              e.libelleStatus,
              e.typologies,
              e.dateDebut,
              e.siret,
              e.dateFin));
        }
      });
      this.dataUO.tabFiltre = Array.from(this.uoFiltre.reduce((m, t) => m.set(t.idUniteOrganisationnelle, t), new Map()).values());
    }
    this.clickPairIdUO = true;
    this.clickPairId = true;
    this.clickPairIdMT = true;


  }

  supprimerFiltre() {
    this.rechercherValue = '';
    this.saveLastValueService.deleteSearchedValue(this.page);
    this.saveLastValueService.deleteSelectedTab(this.page);
    this.saveLastValueService.deleteSelectedFilter(this.page);
    this.saveLastValueService.deleteSortOrder(this.page);
    (<HTMLInputElement>document.getElementById('searchField')).value = this.rechercherValue;
    this.doRecherche();
  }


  checkEndDate(endDate: any) {
    if (endDate === null) {
      return false;
    }
    return Date.now() > new Date(endDate).getTime();
  }

  sortByActivite() {
    this.lastSort = 'ACTIVITE';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairDD = false;
    this.clickPairId = false;
    this.clickPairMT = false;
    this.clickPairDF = false;
    this.clickPairMP = false;
    this.clickPairTypology = false;
    this.checkMPSortedArray();
    this.clickPairActivite = this.sortByElement(this.dataMissionPossible.tabFiltre, 'libelleActivite', this.clickPairActivite);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairActivite ? 'ASC' : 'DESC');

  }

  sortByMTDD() {
    this.lastSort = 'MTDD';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairMTNom = false;
    this.clickPairIdMT = false;
    this.clickPairMTDF = false;
    this.clickPairMTDD = this.sortByElement(this.dataMission.tabFiltre, 'dateDebut', this.clickPairMTDD);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairMTDD ? 'ASC' : 'DESC');
  }

  sortByMTDF() {
    this.lastSort = 'MTDF';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairMTNom = false;
    this.clickPairIdMT = false;
    this.clickPairMTDD = false;
    this.clickPairMTDF = this.sortByElement(this.dataMission.tabFiltre, 'dateDebut', this.clickPairMTDF);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairMTDF ? 'ASC' : 'DESC');
  }

  sortByMPDF() {
    this.lastSort = 'DF';
    this.saveLastValueService.insertSelectedFilter(this.page, this.lastSort);
    this.clickPairActivite = false;
    this.clickPairId = false;
    this.clickPairMT = false;
    this.clickPairMP = false;
    this.clickPairTypology = false;
    this.clickPairDD = false;
    this.checkMPSortedArray();
    this.clickPairDF = this.sortByElement(this.dataMissionPossible.tabFiltre, 'dateFin', this.clickPairDF);
    this.saveLastValueService.insertSortOrder(this.page, this.clickPairDF ? 'ASC' : 'DESC');
  }

  isAutorised(idUniteOrganisationnelle: number) {
    let i = 0;
    let response = false;
    while (i < this.uoAutorises.length || !response) {
      response = this.uoAutorises[i] === idUniteOrganisationnelle;
      i++;
    }
    return response;
  }

  private saveIfNotLastElement(): void {
    if (this.id !== undefined && localStorage.getItem('token')) {
      const savedPage = this.stackService.peek();
      const pageActuelle = (this.route.routeConfig.path.split('/'))[0];
      if (savedPage !== undefined) {
        if (('/' + pageActuelle + '/details/' + this.id) !== savedPage.lastPage ||
           this.id !== savedPage.lastSelectedElement) {
          this.stackService.push(new SavedPage(this.lastSort, this.lastSortOrder, '/' + pageActuelle + '/details/' + this.id, this.id));
        }
      } else {
        this.stackService.push(new SavedPage(this.lastSort, this.lastSortOrder, '/' + pageActuelle + '/details/' + this.id, this.id));
      }
    }
  }

  private getCorrectPage() {
    if (this.page === Page.UO) {
      return '/uo';
    } else if (this.page === Page.mission) {
      return '/mission';
    } else if (this.page === Page.missionPossible) {
      return '/missionPossible';
    }
  }

  pageChanged(event, id) {
    switch (id) {
      case 'uo': this.configPaginationUO.currentPage = event;
        break;
      case 'mission': this.configPaginationM.currentPage = event;
        break;
      case 'missionPossible': this.configPaginationMP.currentPage = event;
        break;
      default:
    }
  }
}
