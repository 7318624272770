import {AfterViewInit, Component, NgModule, OnDestroy, OnInit} from '@angular/core';
import {MissionType} from '../../models/MissionType.model';
import {MissionTypeService} from '../../services/mission-type.service';
import {Activite} from 'src/app/models/Activite.model';
import {ActiviteService} from 'src/app/services/activite.service';
import {ContactService} from 'src/app/services/contact.service';
import {PersonneService} from 'src/app/services/personne.service';
import {ActivitesParUO} from '../../models/ActivitesParUO.model';
import {AdresseService} from 'src/app/services/adresse.service';
import {MissionTypePossibleService} from 'src/app/services/mission-type-possible.service';
import {Router, ActivatedRoute} from '@angular/router';
import {FormsModule, FormGroup, FormControl, Validators} from '@angular/forms';
import {TypologieUoService} from 'src/app/services/typologie-uo.service';
import {TypologieUO} from 'src/app/models/TypologieUO.model';
import {StackService} from '../../services/storage/stack.service';
import {SavedPage} from '../../models/storage/SavedPage.model';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
    selector: 'app-creation-mission-possible',
    templateUrl: './creation-mission-possible.component.html',
    styleUrls: ['./creation-mission-possible.component.css']
})

@NgModule({
  imports: [
    FormsModule
  ]
})
export class CreationMissionPossibleComponent implements OnInit, OnDestroy, AfterViewInit {

    submitted = false;
    private _tabulationNumber: number;
    private _sortedActivities: Activite[];
    private config: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private missionTypeService: MissionTypeService,
        private activiteService: ActiviteService,
        private contactService: ContactService,
        private personneService: PersonneService,
        private adresseService: AdresseService,
        private missionTypePossibleService: MissionTypePossibleService,
        private ngxUiLoaderService: NgxUiLoaderService,
        private typologieUoService: TypologieUoService,
        private stackService: StackService) {

        this.returnClicked = false;
    }

    private returnClicked: boolean;
    private activitesAll: ActivitesParUO[];

    distinctMissionType: MissionType[];
    activites: Activite[] = [];
    typologiesUo: TypologieUO[];
    postMissionForm: FormGroup;
    idMissionPossible: number;
    errorMessage: String;

    ngOnInit() {
        this.postMissionForm = new FormGroup(
            {
                libelleMissionPossible: new FormControl('', [Validators.required]),
                idMissionType: new FormControl('', [Validators.required]),
                idTypologieUO: new FormControl('', [Validators.required]),
                idActivite: new FormControl('', [Validators.required]),
                dateDebut: new FormControl(  this.formattedDate(new Date()) , [Validators.required]),
               /* dateFin: new FormControl('')*/
            }
        );
        this._tabulationNumber = 0;

        this.missionTypeService.dataSubject.subscribe((missions: MissionType[]) => {
            this.distinctMissionType = missions;
        });
        this.missionTypeService.getWithValue('actif', '1', 'sortProperty', 'libelleMissionType', 'sortDirection', 'asc');

        this.typologieUoService.dataSubject.subscribe((typologiesUO: TypologieUO[]) => {
            this.typologiesUo = typologiesUO;
        });
        this.typologieUoService.getWithValue('sortProperty', 'libelleLong', 'sortDirection', 'asc');
        this.activiteService.dataSubject.subscribe((activitesParUo: ActivitesParUO[]) => {
            this.activitesAll = activitesParUo;
            this._sortedActivities = this.sortActivitiesArray(this.buildActivitiesArray());
        });
        this.activiteService.getWithValue('actif', '1', 'sortProperty', 'libelleLong', 'sortDirection', 'asc');
        this.idMissionPossible = Number(this.route.snapshot.paramMap.get('id'));
    }

    ngAfterViewInit(): void {
    }

    post() {
        this.errorMessage = '';
        this.missionTypePossibleService.postWithBody(this.postMissionForm.value).subscribe(
            r => {
                console.log('POST MISSION POSSIBLE OK', r);
                localStorage.removeItem('missionsPossibles');
                this.router.navigate(['missionPossible']);
            },
            err => {
                console.log('POST MISSION POSSIBLE ERROR', err);
                if (null != err.error.message) {
                    this.errorMessage = 'Erreur: ' + err.error.message;
                }
            });
    }

    private getTab(tabNumber: number) {
        let str = '';
        for (let i = 0; i < tabNumber; i++) {
            str += '\u00A0\u00A0\u00A0';
        }
        return str;
    }

    addTab() {
        this._tabulationNumber++;
        return true;
    }

    removeTab() {
        this._tabulationNumber--;
        return true;
    }

    /**
     *  this function will be used to build the activities array
     *  from the activitesAll array
     *  */
    activityIsContained(elt: Activite, array: Activite[]): boolean {
        let response = false;
        array.forEach(e => {
            response = e.idActivite === elt.idActivite || response;
            if (response) {
                return;
            } else {
                response = this.activityIsContained(elt, e.sonsActivity);
                if (response) {
                    return;
                }
            }
        });
        return response;
    }

    buildActivitiesArray(): Activite[] {
        let array: Activite[] = [];
        this.activitesAll.forEach(e => {
            e.allActivites.forEach(elt => {
                if (!this.activityIsContained(elt, array)) {
                    // if the parent is 0 then we don't have any parent
                    if (!array.some(element => element.idActivite === elt.idActivite)) {
                        if (elt.parent === 0) {
                            // we add the element to the array
                            array.push(elt);
                        } else {
                            // recursive function which will search and insert the element at the good position
                            array = this.pushElt(elt, array);
                        }
                    }
                }
            });
        });
        return array;
    }

    private pushElt(elt: Activite, array: Activite[]): Activite[] {
        if (!array.some(element => element.idActivite === elt.idActivite)) {
            // for each element of our array
            array.forEach(e => {
                // if the parent is the current element
                if (e.idActivite === elt.parent && !array.some(elt2 => elt2.idActivite === elt.idActivite)) {
                    // we add it
                    e.sonsActivity.push(elt);
                } else {
                    // if the sons array is empty we stop the recursivity and go back to the previous call
                    if (e.sonsActivity === []) {
                        return;
                    } else {
                        // recursivity on the sons array
                        e.sonsActivity = this.pushElt(elt, e.sonsActivity);
                    }
                }
            });
        }
        return array;
    }

    handleLastPage() {
        this.returnClicked = true;
        const savedPage = this.stackService.peek();
        if (savedPage !== undefined) {
            if (savedPage.lastPage !== this.router.url) {
                const path = savedPage.lastPage.slice();
                this.stackService.pop();
                this.router.navigate([path]);
            } else {
                this.stackService.pop();
            }
        }
    }

    ngOnDestroy(): void {
        if (!this.returnClicked) {
//            this.stackService.push(new SavedPage(null, null, '/creationMissionPossible', null));
        }
    }


    private sortActivitiesArray(array: Activite[]): Activite[] {
        const sortedArray = array.sort((n1, n2) => {
            if (n1.libelleLong == null && n2.libelleLong != null) {
                return 1;
            } else if (n1.libelleLong == null && n2.libelleLong != null) {
                return -1;
            }
            if (n1.libelleLong > n2.libelleLong) {
                return 1;
            }
            if (n1.libelleLong < n2.libelleLong) {
                return -1;
            }
            return 0;
        });
        sortedArray.forEach(element => {
           if (element.sonsActivity !== []) {
               element.sonsActivity = this.sortActivitiesArray(element.sonsActivity);
           }
        });
        return sortedArray;
    }

    get sortedActivities(): Activite[] {
        return this._sortedActivities;
    }

    get libelleMissionPossible () {
      return this.postMissionForm.get('libelleMissionPossible');
    }
    get idMissionType () {
      return this.postMissionForm.get('idMissionType');
    }
    get idTypologieUO () {
      return this.postMissionForm.get('idTypologieUO');
    }
    get idActivite () {
      return this.postMissionForm.get('idActivite');
    }

  set sortedActivities(value: Activite[]) {
        this._sortedActivities = value;
    }

    formattedDate(theDate:  Date): String {
        let month = String(theDate.getMonth() + 1);
        let day = String(theDate.getDate());
        const year = String(theDate.getFullYear());

        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }
        return `${year}-${month}-${day}`;
  }
  onSubmit() {
      this.submitted = true;
    // stop here if form is invalid
    if (this.postMissionForm.invalid) {
      return;
    } else {
      this.post();
    }
  }
}
