import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthUOGuardService {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles = localStorage.getItem('roles');
    if (roles != null && (roles.split(',').includes('Ref_UO_RW') || roles.split(',').includes('Ref_Admin'))) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}
