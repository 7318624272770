import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { Relation } from '../models/relation.model';

@Injectable({
  providedIn: 'root'
})
export class RelationUOService extends GetAndPost {
  /**
   * Constructor of our service
   * @param httpClient
   */
  constructor(httpClient: HttpClient) {
    super('uo/relationUO', httpClient);
  }

  /**
   * Methode qui sert à la création d'un élément
   * @param e
   */
  newElement(e: any) {
    if (e === null) {
      return new Relation(null);
    }
    return new Relation(e.libelle);
  }
}
