import { Injectable } from '@angular/core';
import { ErrordialogService } from '../services/errordialog.service';
import { RouterModule, Routes, Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
// Cette classe est appelée à chaque fois qu'une requête http est envoyée
@Injectable() export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Si un token existe déjà on l'inclue dans le header de la reqûete
    if (localStorage.getItem('token')) {
      request = request.clone({ headers: request.headers.set('Authorization', localStorage.getItem('token')) });
    }
    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Si dans la reponse de la requête il y'a un champs Authorization on le stock dans la session (localstorage sous la clé token)
          if (event['headers'].get('Authorization') != null) {
            localStorage.setItem('token', event['headers'].get('Authorization'));
            // informations of the connected user
            localStorage.setItem('roles', event['body']['roles']);
            localStorage.setItem('nom', event['body']['nom']);
            localStorage.setItem('id', event['body']['id']);
            localStorage.setItem('prenom', event['body']['prenom']);
            localStorage.setItem('missions', JSON.stringify(event['body']['missions']));
          }
        }
        return event;
      }));
  }
}
