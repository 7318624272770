import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Page } from '../models/data.model';
import { ValueTransformer } from '@angular/compiler/src/util';



@Injectable({
  providedIn: 'root'
})
export class SaveLastValueService {
  // searched value

  getSearchedValue(key: Page): string {
    return localStorage.getItem('lastSearchedValue_' + key) || '';
  }

  deleteSearchedValue(key: Page): void {
    localStorage.removeItem('lastSearchedValue_' + key);
  }

  insertSearchValue(key: Page, value: any): void {
    if (value !== '') {
      localStorage.setItem('lastSearchedValue_' + key, value);
    }
  }

  // selected tab

  getselectedTab(key: Page): string {
    return localStorage.getItem('lastSelectedTab_' + key) || '-1';
  }

  deleteSelectedTab(key: Page) {
    return localStorage.removeItem('lastSelectedTab_' + key);
  }

  insertSelectedTab(key: Page, value: any): void {
    if (value !== '') {
      localStorage.setItem('lastSelectedTab_' + key, value);
    }
  }
  // selected filter

  getselectedFilter(key: Page): string {
    return localStorage.getItem('lastSelectedFilter_' + key) || '';
  }

  deleteSelectedFilter(key: Page) {
    return localStorage.removeItem('lastSelectedFilter_' + key);
  }

  insertSelectedFilter(key: Page, value: any): void {
    if (value !== '') {
      localStorage.setItem('lastSelectedFilter_' + key, value);
    }
  }
  // sort Order
  getSortOrder(key: Page): string {
    return localStorage.getItem('lastSortOrder_' + key) || '';
  }

  deleteSortOrder(key: Page) {
    return localStorage.removeItem('lastSortOrder_' + key);
  }

  insertSortOrder(key: Page, value: any): void {
    if (value !== '') {
      localStorage.setItem('lastSortOrder_' + key, value);
    }

  }
}
