export class MissionType {
  constructor(
    public idMissionType: number,
    public libelleMissionType: string,
    public dateDebut: Date,
    public dateFin: Date,
  ) { }

  getElementFromString(str : string) : any {
    switch(str) {
      case 'idMissionType':
        return this.idMissionType;
      case 'libelleMissionType':
        return this.libelleMissionType;
      case 'dateDebut':
        return this.dateDebut;
      case 'dateFin':
        return this.dateFin;
    }
  }
  
} 