import {Injectable} from '@angular/core';
import {UO} from '../../models/UO.model';
import { ERoles } from 'src/app/models/ERoles.model';
import {TypeUO} from "../../models/TypeUO.model";

const ALL_VISIBILITY = 'ALL';
const ANT_VISIBILITY = 'ANT';
const AD_VISIBILITY = 'AD';
const OTHER_VISIBILITY = 'OTHER';

@Injectable({
    providedIn: 'root'
})
export class ConnexionDroitService {

    constructor() {}

    isAdmin() {
        return localStorage.getItem('roles').split(',').includes(ERoles.REF_ADMIN);
    }

    getUoAutorises(): number[] {
        const uoAutorises: number[] = [];
        if (this.isAdmin()) {
            return null;
        }
         return [0];
    }

    // 4 visibilityUO
        // All
        // Antenne
        // AD
        // Autre
    getTypeUO() {
        const jsonMissions = JSON.parse(localStorage.getItem('missions'));
        switch (jsonMissions[localStorage.getItem('missionId')]['typoUO']) {
            case 'AN':
                return ALL_VISIBILITY;
                break;
            case 'Siège':
                return ALL_VISIBILITY;
                break;
            case 'Pôle':
                return ALL_VISIBILITY;
                break;
            case 'Service':
                return ALL_VISIBILITY;
                break;
            case 'Réseau':
                return ALL_VISIBILITY;
                break;
            case 'Comité':
                return ALL_VISIBILITY;
                break;
            case 'Présidence':
                return ALL_VISIBILITY;
                break;
            case 'Antenne':
                return ANT_VISIBILITY;
                break;
            case 'AD':
                return AD_VISIBILITY;
                break;
            case 'Association Intermédiaire':
                return AD_VISIBILITY;
                break;
            default:
                return OTHER_VISIBILITY;
                break;
        }
    }

    isVisibleForUser(e: UO) {
        const typeUO: string = this.getTypeUO();
        return typeUO === ALL_VISIBILITY || (typeUO === AD_VISIBILITY && e.typologies.some(elt => elt.libelleCourtTUO === 'AD'));
    }

    isUORW() {
        return localStorage.getItem('roles').split(',').includes(ERoles.REF_UO_RW) && !this.isAdmin();
    }

    isUORO() {
        return localStorage.getItem('roles').split(',').includes(ERoles.REF_UO_RO) && !this.isAdmin();
    }

    isMISRW() {
        return localStorage.getItem('roles').split(',').includes(ERoles.REF_MISSION_RW) && !this.isAdmin();
    }

  isUO_AN(): boolean {
    const missionId = localStorage.getItem('missionId');
    return localStorage.getItem('missions') !== 'undefined' &&
      JSON.parse(localStorage.getItem('missions'))[missionId]['typoUO'] === TypeUO.AN;
  }

  isUO_AD(): boolean {
    const missionId = localStorage.getItem('missionId');
    return localStorage.getItem('missions') !== 'undefined' &&
      JSON.parse(localStorage.getItem('missions'))[missionId]['typoUO'] === TypeUO.AD;
  }

  isUO_ANT(): boolean {
    const missionId = localStorage.getItem('missionId');
    return localStorage.getItem('missions') !== 'undefined' &&
      JSON.parse(localStorage.getItem('missions'))[missionId]['typoUO'] === TypeUO.ANT;
  }
}
