import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UrlEnvironmentService } from './url-environment-service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  protected urlEnvironmentService:UrlEnvironmentService;
  protected hostname;
  constructor(private http: HttpClient) {
    this.urlEnvironmentService=UrlEnvironmentService.injector.get(UrlEnvironmentService);
    this.hostname = this.urlEnvironmentService.getHostname();
   }

  // Access-Control-Allow-Origin // deux noms de domaine différents
  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });

  /*data = {
    "username": "user",
    "password": "12345"
  };*/

  login(data, body) {
    if (environment.production){
      return this.http.post<any[]>(this.hostname + 'login', body);
    } else {
      return this.http.post<any[]>(environment.baseUrl + 'login', body);
    }
  }
}


