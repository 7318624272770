<div class="container-fluid margin-left padding" ngxUiLoaderBlurred>
    <h3 class="margin-top-bottom" *ngIf="this.renouvellement === false">Modification mission possible</h3>
    <h3 class="margin-top-bottom" *ngIf="this.renouvellement === true">Réouverture mission possible</h3>
    <div>
      <form *ngIf="this.missionPossibleSelected != undefined">
        <!-- Formulaire post mission possible -->
        <div class="static">
          <div class="row">
            <div class="col">
              <div class="form-inline margin-bottom">
                <label class="col-sm-6">Libellé mission possible</label>
                <input class="form-control width_100 col-sm-6" type="text" name="libelleMissionPossible"
                       [(ngModel)]="this.missionPossibleSelected.libelleMissionPossible" [disabled]="this.renouvellement === true">
              </div>
              <div class="form-inline margin-bottom">
                <label class="col-sm-6">Mission type</label>
                <select class="form-control col-sm-6" name="idMissionType"
                        [(ngModel)]="this.missionPossibleSelected.idMissionType" disabled>
                  <option *ngFor="let mission of distinctMissionType" [value]="mission.idMissionType">
                    {{mission.libelleMissionType}}
                  </option>
                </select>
              </div>
              <div class="form-inline margin-bottom">
                <label class="col-sm-6">Typologie UO</label>
                <select class="form-control col-sm-6" name="idTypologieUO"
                        [(ngModel)]="this.missionPossibleSelected.idTypologieUO" disabled>
                  <option *ngFor="let typologieUo of typologiesUo" [value]="typologieUo.idTypologieUo">
                    {{typologieUo.libelleLongTUO}}
                  </option>
                </select>
              </div>
              <div class="form-inline margin-bottom">
                <label class="col-sm-6">Activité</label>
                <select class="form-control col-sm-6" name="act"
                        [(ngModel)]="this.missionPossibleSelected.idActivite" disabled>
                  <ng-container *ngFor="let activite of sortedActivities; let index=index;">
                    <!-- this container is the beginning of the recursive display-->
                    <ng-container>
                      <!-- our element is a leaf -->
                      <ng-container *ngIf="activite.sonsActivity.length === 0; else optgroupAct">
                        <!-- we just display it -->
                        <option value="{{activite.idActivite}}">{{activite.libelleLong}}
                        </option>
                      </ng-container>

                      <ng-template #optgroupAct>
                        <!-- our element is a branch -->
                        <option value="{{activite.idActivite}}">{{activite.libelleLong}}</option>
                        <!-- recursive call -->
                        <ng-container
                                *ngTemplateOutlet="recursiveList; context:{ $implicit: activite.sonsActivity, index: 1}">
                        </ng-container>
                      </ng-template>
                    </ng-container>

                    <!-- Recursive content-->
                    <ng-template #recursiveList let-list let-index="index">
                      <ng-container>
                        <!-- foreach sons -->
                        <ng-container *ngFor="let item of list">
                          <!-- if it contains or one of it sons can be mapped with the activity-->
                          <ng-container>
                            <!-- the element contains childs -->
                            <ng-container
                                    *ngIf="item.sonsActivity.length !== 0; else optgroupActSon">
                              <option value="{{item.idActivite}}">{{getTab(index) + item.libelleLong}}</option>
                              <ng-container
                                      *ngTemplateOutlet="recursiveList; context:{ $implicit: item.sonsActivity, index: index + 1 }"></ng-container>
                            </ng-container>
                            <ng-template #optgroupActSon>
                              <option value="{{item.idActivite}}">
                                {{ getTab(index) + item.libelleLong }}
                              </option>
                            </ng-template>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </ng-container>
                </select>
              </div>
              <div class="form-inline margin-bottom">
                <label class="col-sm-6">Date de début</label>
                <input class="form-control col-sm-6" type="date" name="dateDebut"
                       [(ngModel)]="this.missionPossibleSelected.dateDebut" [disabled]="true">
              </div>
              <div class="form-inline margin-bottom">
                <label class="col-sm-6">Date de fin</label>
                <input class="form-control col-sm-6" type="date" name="dateFin"
                       [(ngModel)]="this.missionPossibleSelected.dateFin">
                <ng-container *ngIf="!dateIsValid(this.missionPossibleSelected.dateDebut, this.missionPossibleSelected.dateFin)">
                  <br/><font style="color:red;">La date de fin ne peut pas être antérieure à la date du jour !</font>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-success pull-right margin-top margin-bottom-btn width_200px"
                [disabled]="this.missionPossibleSelected.dateDebut === null
                                || this.missionPossibleSelected.idActivite === null
                                || this.missionPossibleSelected.idTypologieUO === null
                                || this.missionPossibleSelected.idMissionType === null
                                || (!dateIsValid(this.missionPossibleSelected.dateDebut, this.missionPossibleSelected.dateFin))" (click)="put()">
          Modifier
        </button>
        <div class="error">
          <h5>{{ errorMessage }}</h5>
        </div>
      </form>
    </div>
</div>
