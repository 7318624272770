export class SavedPage {
  constructor(
      private _lastSort: string,
      private _lastSortOrder: string,
      private _lastPage: string,
      private _lastSelectedElement: number
  ) {
  }
  /* GETTERS AND SETTERS */
  get lastSort(): string {
    return this._lastSort;
  }

  set lastSort(value: string) {
    this._lastSort = value;
  }

  get lastSortOrder(): string {
    return this._lastSortOrder;
  }

  set lastSortOrder(value: string) {
    this._lastSortOrder = value;
  }

  get lastPage(): string {
    return this._lastPage;
  }

  set lastPage(value: string) {
    this._lastPage = value;
  }

  get lastSelectedElement(): number {
    return this._lastSelectedElement;
  }

  set lastSelectedElement(value: number) {
    this._lastSelectedElement = value;
  }

  clone(): SavedPage {
    return new SavedPage(this._lastSort, this._lastSortOrder, this._lastPage, this._lastSelectedElement);
}
}
