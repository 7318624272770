import { Mission } from './Mission.model';
export class Personne {
  constructor(public idPersonne: number,
    public identifiantSSO: string,
    public nom: string,
    public prenom: string,
    public adresseMail: string,
    public dateDebut: Date,
    public missions: Mission[],
    public dateFin?: Date,
  ) { }
} 