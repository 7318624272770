import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ConnexionDroitService} from '../connexion/connexion-droit.service';
import { ERoles } from 'src/app/models/ERoles.model';

@Injectable({
  providedIn: 'root'
})
export class AuthMSGuardService {

  constructor(private router: Router, private connexionDroitService: ConnexionDroitService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles = localStorage.getItem('roles');
    if (roles != null && ((roles.split(',').includes(ERoles.REF_MISSION_RW) &&
     ['ALL', 'AD', 'OTHER'].includes(this.connexionDroitService.getTypeUO())) || roles.split(',').includes(ERoles.REF_ADMIN))) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
}
