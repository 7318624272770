import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { Contact } from '../models/Contact.model';
@Injectable({
  providedIn: 'root'
})
export class ContactService extends GetAndPost {

  constructor(httpClient: HttpClient) {
    super('contact', httpClient);
  }
  newElement(e: any): any {
    if (e === null) {
      return new Contact(null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null);
    }
    return new Contact(e.valeur,
        e.dateDebut,
        e.libelleCourtTypologieMoyenContact,
        e.libelleLongTypologieMoyenContact,
        e.idUOContact,
        e.idTypoContact,
        e.dateDebutTMC,
        e.dateFinTMC,
        e.dateFin);
  }
}
