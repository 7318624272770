<!-- <nav class="navigation">
  <ul>
    <li [class.active]="isActive('uo')">
      <a routerLink="uo" routerLinkActive="on">Réferentiel UO</a>
    </li>
    <li [class.active]="isActive('mission')">
      <a routerLink="mission" routerLinkActive="on">Réferentiel mission type</a>
    </li>
    <li [class.active]="isActive('missionPossible')">
      <a routerLink="missionPossible" routerLinkActive="on">Réferentiel missions possible</a>
    </li>
    <li [class.active]="isActive('demande')">
      <a routerLink="construct" routerLinkActive="on">Suivi des demandes</a>
    </li>
    <li [class.active]="isActive('referentiel')">
      <a routerLink="referentiel" routerLinkActive="on">Administration Référentiel</a>
    </li>
  </ul>
</nav> -->

<nav class="navbar navbar-expand-sm navbar-dark bg-company">
  <!-- Links -->
  <ul class="navbar-nav">
        <li  *ngIf="isRefVisible('uo')" [class.active]="isActive('uo')" class="nav-item">
          <a class="nav-link" routerLink="uo" routerLinkActive="on">Réferentiel UO</a>
        </li>
        <li *ngIf="isRefVisible('mission')" [class.active]="isActive('mission')" class="nav-item">
          <a *ngIf="missionIsActive('mission')" class="nav-link" routerLink="mission" routerLinkActive="on">Réferentiel mission type</a>
          <a *ngIf="!missionIsActive('mission')" class="nav-link noRightLink" (click)="noRight()">Réferentiel mission type</a>
        </li>
        <li *ngIf="isRefVisible('missionPossible')" [class.active]="isActive('missionPossible')" class="nav-item">
          <a *ngIf="missionIsActive('missionPossible')" class="nav-link" routerLink="missionPossible" routerLinkActive="on">Réferentiel missions possible</a>
          <a *ngIf="!missionIsActive('missionPossible')" class="nav-link noRightLink" (click)="noRight()">Réferentiel mission possible</a>
        </li>
        <li  *ngIf="isRefVisible('demande')" [class.active]="isActive('demande')" class="nav-item">
          <a class="nav-link" routerLink="construct" routerLinkActive="on">Suivi des demandes</a>
        </li>
        <li  *ngIf="isRefVisible('referentiel')" [class.active]="isActive('referentiel')" class="nav-item">
          <a class="nav-link" routerLink="referentiel" routerLinkActive="on">Administration Référentiel</a>
        </li>
 </ul>
</nav>
