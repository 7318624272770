import { Injectable, Inject,Injector } from '@angular/core';
import { WINDOW } from '../providers/window.provider';
import {environment} from '../../environments/environment';
import { from } from 'rxjs';

@Injectable()
export class UrlEnvironmentService{
  static injector: Injector;
  private host: string;

  constructor(@Inject(WINDOW) private window: Window) {
    this.host = this.window.location.hostname;
  }
  private buildUrl() {
    const hostSplit = this.host.split('.');
    const prefix = hostSplit[0].split('-');
    let newSubDomain = prefix[0] + '-' + 'api' + '-' + prefix[1];
    if (prefix.length === 1) {
      newSubDomain = prefix[0] + '-' + 'api';
    }
    return newSubDomain + '.' + hostSplit[1] + '.' + hostSplit[2];
  }
  getHostname(): string {
    if (this.host !== 'localhost') {
      return 'https://' + this.buildUrl() + '/referentiels/api/1.0/';
    } else {
      return environment.hostname;
    }
  }
}
