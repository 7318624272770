<div class="tableauRecherche container-fluid">
  <h3>Administration référentiel :</h3>
  <div class="rechercher">
    <form>
      <div class="formUO">
        <div class="col1 static">
          <label class="color-titre">Moyens de contact</label><br>
          <label><input type="checkbox"> Fax</label>
          <label><input type="checkbox"> Mail</label>
          <label><input type="checkbox"> Téléphone</label>
          <label><input type="checkbox"> Courrier</label>
          <div class="boutonsListe">
            <button class="btn btn-info mr-sm-3"><span class="fa fa-plus"></span>
              Ajouter</button>
              <button class="btn btn-danger" (click)="supprimerTypoContact()"><span class="fa fa-remove"></span>
              Supprimer</button></div>
        </div>
        <div class="col2 static">
          <label class="color-titre">Entité</label><br>
          <label><input type="checkbox"> Livraison </label>
          <label><input type="checkbox"> AN </label>
          <label><input type="checkbox"> Dépôt</label>
          <label><input type="checkbox"> Centre activité</label>
          <div class="boutonsListe"><button class="btn btn-info mr-sm-3"><span class="fa fa-plus"></span>
              Ajouter</button><button class="btn btn-danger" (click)="supprimerTypoUO()"><span class="fa fa-remove"></span>
              Supprimer</button></div>
        </div>
        <div class="col3 static">
          <label class="color-titre">Adresse</label><br>
          <label><input type="checkbox"> Définitive</label>
          <label><input type="checkbox"> Adresse principale</label>
          <label><input type="checkbox"> Temporaire</label>
          <label><input type="checkbox"> Déménagement</label>
          <div class="boutonsListe"><button class="btn btn-info mr-sm-3"><span class="fa fa-plus"></span>
              Ajouter</button><button class="btn btn-danger" (click)="supprimerTypoAdresse()"><span class="fa fa-remove"></span>
              Supprimer</button></div>
        </div>
      </div>
      <button type="submit" class="btn btn-success mb-2 margin-top-bottom" (click)="activer()"><span class="fa fa-check"></span> Activer les cases
        sélectionnées</button>


    </form>
  </div>
