<ngx-ui-loader [fgsColor]="config.fgsColor" [fgsPosition]="config.fgsPosition" [fgsSize]="config.fgsSize"
  [fgsType]="config.fgsType" [bgsColor]="config.bgsColor" [bgsOpacity]="config.bgsOpacity"
  [bgsPosition]="config.bgsPosition" [bgsSize]="config.bgsSize" [bgsType]="config.bgsType" [gap]="config.gap"
  [logoPosition]="config.logoPosition" [logoSize]="config.logoSize" [logoUrl]="config.logoUrl"
  [overlayColor]="config.overlayColor" [pbColor]="config.pbColor" [pbDirection]="config.pbDirection"
  [pbThickness]="config.pbThickness" [hasProgressBar]="config.hasProgressBar" [text]="config.text"
  [textColor]="config.textColor" [textPosition]="config.textPosition"></ngx-ui-loader>

<div class="error">
  <div class="d-flex justify-content-center div_top_25px">
    <label class="text-danger">{{this.dataUO.erreurChargement}}</label>
  </div>
</div>

<div class="interfacePrincipale">
  <ng-container *ngIf="isPageUO() && isNotErreurUO() else notUO">
    <div class="col-5" *ngIf="isModeLecture()">
      <app-tableau [dataUO]="dataUO" [dataMission]="dataMission" [dataMissionPossible]="dataMissionPossible"
        [page]="page" [tableau]="tableau"></app-tableau>
    </div>

    <div [ngClass]="!isModeLecture() ? 'col-12' : 'col-7'" >
      <app-details-entite [hidden]="
          router.url.startsWith('/modificationUO') ||
          router.url.startsWith('/affichageUO') ||
          router.url.startsWith('/creationUO') ||
          router.url.startsWith('/modificationMissionType') ||
          router.url.startsWith('/creationMissionType') ||
          router.url.startsWith('/creationMissionPossible')
        " [dataUO]="dataUO" [dataMission]="dataMission" [dataMissionPossible]="dataMissionPossible" [page]="page"
        #tableau></app-details-entite>
      <app-modification-uo *ngIf="
          router.url.startsWith('/modificationUO') ||
          router.url.startsWith('/creationUO')
        ">
      </app-modification-uo>
      <app-affichage-uo *ngIf="router.url.startsWith('/affichageUO')"></app-affichage-uo>
      <app-interface-modification *ngIf="router.url.startsWith('/modificationMissionType')">
      </app-interface-modification>
      <app-interface-creation *ngIf="router.url.startsWith('/creationMissionType')"></app-interface-creation>
      <app-creation-mission-possible *ngIf="router.url.startsWith('/creationMissionPossible')">
      </app-creation-mission-possible>
      <app-modification-mission-possible *ngIf="router.url.startsWith('/modificationMissionPossible')">
      </app-modification-mission-possible>
    </div>
  </ng-container>

  <ng-template #notUO>
    <div class="col-6" *ngIf="isModeLecture() && isNotErreurUO()">
      <app-tableau [dataUO]="dataUO" [dataMission]="dataMission" [dataMissionPossible]="dataMissionPossible"
        [page]="page" [tableau]="tableau"></app-tableau>
    </div>
    <div [ngClass]="isModeLecture() ? 'col-6' : 'col-12'" *ngIf="isNotErreurUO()">
      <app-details-entite [hidden]="
        router.url.startsWith('/modificationUO') ||
        router.url.startsWith('/affichageUO') ||
        router.url.startsWith('/creationUO') ||
        router.url.startsWith('/modificationMissionType') ||
        router.url.startsWith('/creationMissionType') ||
        router.url.startsWith('/creationMissionPossible') ||
        router.url.startsWith('/modificationMissionPossible')
        " [dataUO]="dataUO" [dataMission]="dataMission" [dataMissionPossible]="dataMissionPossible" [page]="page"
        #tableau>
      </app-details-entite>

      <app-modification-uo *ngIf="
        router.url.startsWith('/modificationUO') ||
        router.url.startsWith('/creationUO')
      ">
      </app-modification-uo>

      <app-affichage-uo *ngIf="router.url.startsWith('/affichageUO')">
      </app-affichage-uo>

      <app-interface-modification *ngIf="router.url.startsWith('/modificationMissionType')">
      </app-interface-modification>

      <app-interface-creation *ngIf="router.url.startsWith('/creationMissionType')">
      </app-interface-creation>

      <app-creation-mission-possible *ngIf="router.url.startsWith('/creationMissionPossible')">
      </app-creation-mission-possible>

      <app-modification-mission-possible *ngIf="router.url.startsWith('/modificationMissionPossible')">
      </app-modification-mission-possible>
    </div>
  </ng-template>
</div>

