export class Mission {
  public constructor(public idMission: number,
    public dateDebutMission: Date,
    public idMissionType: number,
    public libelleMissionType: string,
    public dateDebutMT: Date,
    public idEquipe: number,
    public dateDebutEq: number,
    public idUO: number,
    public dateFinEQ?: Date,
    public dateFinMission?: Date,
  ) { }
}
