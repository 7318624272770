import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Subject, throwError, from} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {UrlEnvironmentService} from './url-environment-service';

export abstract class GetAndPost {

    // Notifie de la mise en cache de données
    public dataSubject = new Subject<any[]>();
    // Données mises en cache
    protected loadedData = new Array<any>();
    // DataError
    public dataError: string;
    private requested = false;
    protected baseUrl;
    protected urlEnvironmentService: UrlEnvironmentService;

    // a comme entree le string pour url et hhtpclient
    constructor(
        protected service: string,
        protected httpClient: HttpClient) {
        this.urlEnvironmentService = UrlEnvironmentService.injector.get(UrlEnvironmentService);
        this.baseUrl = this.urlEnvironmentService.getHostname() || environment.baseUrl;
    }

    /*
    * Sérialise une donnée reçu.
    * param: e la donnée reçu.
    * return: une instance d'un model correspondant à e.
    */
    abstract newElement(e: any): any;

    /*
    * Notifie du changement des données en cache.
    */
    emitSubject() {
        this.dataSubject.next(this.get());
    }

    add(elem: any) {
        const obj = this.newElement(elem);
        this.loadedData.push(obj);
    }

    get() {
        return this.loadedData.slice();
    }

    getDatas(url: string): void {
      this.loadedData = [];
      this.dataError = null;  
      console.log(url)
      this.httpClient.get<any>(url).toPromise().then(
        data => {
          if (data['results'] != null) {
            data['results'].forEach(elt => {
              this.add(elt);
            });
          }
          this.emitSubject();
        }
      ).catch(
        err => {
          if (err.status === undefined) {
            this.dataError = err.name + ": " + err.message;
          }
          else {
            this.dataError = "ERREUR " + err.status + ": " + err.error.message;
          }
          this.emitSubject();
        }
      );
    }

    getDatasUOActive(url: string): void {
        this.loadedData = [];
        this.dataError = null;  
        console.log(url)
        this.httpClient.get<any>(url).toPromise().then(
          data => {
            if (data['results'] != null) {
              data['results'].forEach(elt => {
                  if(elt.estActive === 1)
                  {
                    this.add(elt);
                  }
                
              });
            }
            this.emitSubject();
          }
        ).catch(
          err => {
            if (err.status === undefined) {
              this.dataError = err.name + ": " + err.message;
            }
            else {
              this.dataError = "ERREUR " + err.status + ": " + err.error.message;
            }
            this.emitSubject();
          }
        );
      }

    getWithId(id: number) {
        const url = this.baseUrl + this.service + '/' + id;
        this.getDatas(url);
    }


    getWithValue(parameter?: string,
                 value?: string,
                 parameter2?: string,
                 value2?: string,
                 parameter3?: string,
                 value3?: string,
                 parameter4?: string,
                 value4?: string) {
        this.loadedData = [];
        let url = this.baseUrl + this.service;
        if (parameter && value) {
            url = url + '?' + parameter + '=' + value;
        }
        if (parameter2 && value2) {
            url = url + '&' + parameter2 + '=' + value2;
        }
        if (parameter3 && value3) {
            url = url + '&' + parameter3 + '=' + value3;
        }
        if (parameter4 && value4) {
            url = url + '&' + parameter4 + '=' + value4;
        }
        // requetage http si rien en cache
        this.requested = true;
        this.getDatas(url);
    }

    getWithValueUOActive(parameter?: string,
      value?: string,
      parameter2?: string,
      value2?: string,
      parameter3?: string,
      value3?: string,
      parameter4?: string,
      value4?: string) {
      this.loadedData = [];
      let url = this.baseUrl + this.service;
      if (parameter && value) {
      url = url + '?' + parameter + '=' + value;
      }
      if (parameter2 && value2) {
      url = url + '&' + parameter2 + '=' + value2;
      }
      if (parameter3 && value3) {
      url = url + '&' + parameter3 + '=' + value3;
      }
      if (parameter4 && value4) {
      url = url + '&' + parameter4 + '=' + value4;
      }
      // requetage http si rien en cache
      this.requested = true;
      this.getDatasUOActive(url); 
    }


    post(parameter: string,
         value: string,
         parameter2?: string,
         value2?: string,
         parameter3?: string,
         value3?: string,
         parameter4?: string,
         value4?: string) {
        this.loadedData = [];
        let url = this.baseUrl + this.service + '?' + parameter + '=' + value;
        if (parameter2 && value2) {
            url = url + '&' + parameter2 + '=' + value2;
        }
        if (parameter3 && value3) {
            url = url + '&' + parameter3 + '=' + value3;
        }
        if (parameter4 && value4) {
            url = url + '&' + parameter4 + '=' + value4;
        }
        // requetage http si rien en cache
        this.httpClient.post<any[]>(url, this.loadedData).subscribe(r => console.log('POST OK', r), err => console.log('POST ERROR', err));
        // on recherche toutes les elements avec l'api

    }

    postWithBody(data) {
        const parsedData = JSON.stringify(data);
        return this.httpClient.post<any[]>(this.baseUrl + this.service, parsedData);
    }

    put(data, idElementToChange) {
        const parsedData = JSON.stringify(data);
        return this.httpClient.put<any[]>(this.baseUrl + this.service + '/' + idElementToChange, parsedData);
    }

    putWithJSON(parsedData, idElementToChange) {
        const url = this.baseUrl + this.service + '/' + idElementToChange;
        return this.httpClient.put<any[]>(
            url,
            parsedData).pipe(
            catchError(this.handleError)
        );
    }

    postWithJSON(parsedData, idElementToChange) {
        const url = this.baseUrl + this.service;
        return this.httpClient.post<any[]>(
            url,
            parsedData).pipe(
            catchError(this.handleError)
        );
    }


    handleError(error: HttpErrorResponse) {

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');

    }

}
