import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { RouterModule, Routes, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  closeResult: string;
  private missions: any;

  constructor(private loginService: LoginService, private router: Router, private modalService: NgbModal) {
  }

  formLogin: FormGroup;
  uploadData: FormData;
  roles: string[];
  errorLogin: string;

  ngOnInit() {
    this.formLogin = new FormGroup(
      {
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required])
      }
    )
    this.uploadData = new FormData();
  }

  login(content: any) {
    this.errorLogin = '';
    if (this.formLogin.valid) {
      this.uploadData.append('username', this.formLogin.value.username);
      this.uploadData.append('password', this.formLogin.value.password);
      const str: string
          = 'username=' + this.formLogin.value.username + '&password=' + this.formLogin.value.password;
      const jsonQuery = '{\'username\':\'' + this.formLogin.value.username + '\',\'password\':\'' + this.formLogin.value.password + '\'}';
      this.loginService.login(str, jsonQuery).subscribe(
        res => {
          localStorage.setItem('logged', 'true');
          this.roles = localStorage.getItem('roles').split(',');
          if (!this.roles.includes('Ref_Admin')) {
            this.missions = JSON.parse(localStorage.getItem('missions'));
            if (this.missions.length > 1) {
              this.open(content);
            } else {
              localStorage.setItem('missionId', '0');
              if (this.missions.length <= 0) {
                // Absence de mission en base !
                this.errorLogin = 'Erreur de sécurité : aucune mission trouvée dans le Référentiel ! [' + res + ']';
                localStorage.setItem('logged', 'false');
                this.router.navigate(['/']);
              } else {
                this.router.navigate(['uo']);
              }
            }
          } else {
            localStorage.setItem('missionId', '0');
            this.router.navigate(['uo']);
          }
        },
        err => {
          console.log(err);
          localStorage.setItem('logged', 'false');
          if (err.error !== undefined && err.error.message !== undefined) {
            this.errorLogin = err.error.message;
          } else {
            this.errorLogin = 'Erreur serveur : ' + err.status;
          }

          this.router.navigate(['/']);
        }
      );
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.missions = this.missions[result];
      localStorage.setItem('missionId', result);
      this.router.navigate(['uo']);
    }, (reason) => {
      if (reason === 'Cross click') {
        localStorage.clear();
      } else {
        this.open(content);
      }
    });
  }
}
