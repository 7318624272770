import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { MissionTypePossible } from '../models/MissionTypePossible.model';

@Injectable({
  providedIn: 'root'
})

export class MissionTypePossibleService extends GetAndPost {

  constructor(httpClient: HttpClient) {
    super('missionPossible', httpClient);
  }

  newElement(e: any): any {
    return new MissionTypePossible(
      e.idMissionPossible, 
      e.libelleMissionPossible, 
      e.idMissionType, 
      e.libelleMissionType, 
      e.idTypologieUO, 
      e.libelleTypologieUOLong,
	  e.libelleTypologieUOCourt,  
      e.idActivite, 
      e.dateDebut, 
      e.libelleActivite, 
      e.dateFin);
  }

  putMissionTypePossible(idMissionPossible: string, libelleMissionPossible: string, idMissionType: string, idTypologieUO : string, idActivite: string, dateDebut: string, dateFin: string) {
    this.loadedData = [];
    let url = this.baseUrl + this.service + "?" + "idMissionPossible" + "=" + idMissionPossible;
    if (libelleMissionPossible) {
      url = url + "&" + "libelleMissionPossible" + "=" + libelleMissionPossible;
    }
    if (idMissionType){
      url = url + "&" + "idMissionType" + "=" + idMissionType;
    }
    if(idTypologieUO){
      url = url + "&" + "idTypologieUO" + "=" + idTypologieUO;
    }
    if(idActivite){
      url = url + "&" + "idActivite" + "=" + idActivite;
    }
    if (dateDebut) {
      url = url + "&" + "dateDebut" + "=" + dateDebut;
    }
    if (dateFin) {
      url = url + "&" + "dateFin" + "=" + dateFin;
    }
    return this.httpClient.put<any[]>(url, this.loadedData);    
  }
  
}
