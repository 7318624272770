export class ParentUo {
  public isDeleted: boolean;
  public isNewFather: boolean;
  public isNewRelation: boolean;
  public dateFin: Date;
  // constructor
  constructor(
    public idUO: number,
    public nomLong: string,
    public typeRelation: string,
    public nomCourt: string,
    public typo: string,
    public idUoUo: number,
    public dateFinOtherUO?: Date,
  ) {
    this.isDeleted = false;
    this.dateFin = null;
    this.isNewRelation = false;
    this.isNewFather = false;
  }
}
