import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

import { Page, DataUO, DataMission, DataMissionPossible } from '../../../models/data.model';
import { UO } from '../../../models/UO.model';
import { UOService } from 'src/app/services/uo.service';
import { ActivitesParUO } from '../../../models/ActivitesParUO.model';
import { ActiviteService } from 'src/app/services/activite.service';
import { Activite } from 'src/app/models/Activite.model';
import { Contact } from 'src/app/models/Contact.model';
import { Adresse } from 'src/app/models/Adresse.model';
import { ParentUo } from 'src/app/models/ParentUo.model';
import { Relation } from '../../../models/relation.model';
import { Router, ActivatedRoute } from '@angular/router';
import { StackService } from '../../../services/storage/stack.service';
import { UoEnfantsService } from '../../../services/uo-enfant.service';
import { UoParentService } from '../../../services/uo-parent.service';
import { ContactService } from '../../../services/contact.service';
import { AdresseService } from '../../../services/adresse.service';
import { ConnexionDroitService } from '../../../services/connexion/connexion-droit.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ERoles } from 'src/app/models/ERoles.model';
@Component({
  selector: 'app-details-entite',
  templateUrl: './details-entite.component.html',
  styleUrls: ['./details-entite.component.css']
})
export class DetailsEntiteComponent implements OnInit, AfterViewInit {
  // typeDef
  Page = Page;
  // attributes
  rechercherValue: string;

  @Input()
  page: Page; // default value for testing, set with route
  _dataUO: DataUO;
  _dataMission: DataMission;
  _dataMissionPossible: DataMissionPossible;
  listeRelationFille: Relation[];
  private _uoAutorises: number[];
  private config: any;

  @Input()
  set dataUO(dataUO: DataUO) {
    this._dataUO = dataUO;
  }
  get dataUO(): DataUO {
    return this._dataUO;
  }

  @Input()
  set dataMission(dataMission: DataMission) {
    this._dataMission = dataMission;
  }
  get dataMission(): DataMission {
    return this._dataMission;
  }
  @Input()
  set dataMissionPossible(dataMissionPossible: DataMissionPossible) {
    this._dataMissionPossible = dataMissionPossible;
  }
  get dataMissionPossible(): DataMissionPossible {
    return this._dataMissionPossible;
  }

  set uoAutorises(uoAutorises:number[]){
    this._uoAutorises=uoAutorises;
  }

  get uoAutorises(){
    return this._uoAutorises;
  }
  uoFillesDetails: UO[];
  uoParentsDetails: ParentUo[];
  uoFilles: ParentUo[];
  activitesDetails: Activite[];
  activitesParUO: ActivitesParUO[];
  contacts: Contact[];
  adresses: Adresse[];

  constructor(private uoService: UOService,
              private activiteService: ActiviteService,
              private router: Router,
              private stackService: StackService,
              private uoEnfantsService: UoEnfantsService,
              private uoParentService: UoParentService,
              private contactService: ContactService,
              private adresseService: AdresseService,
              private ngxUiLoaderService: NgxUiLoaderService,
              private route: ActivatedRoute,
              private connexionDroitService: ConnexionDroitService) {
    this.rechercherValue = '';
    this.uoParentsDetails = [];
    this.listeRelationFille = [];
    this.uoFilles = [];
    this.contacts = [];
    this.adresses = [];
    this._uoAutorises = this.connexionDroitService.getUoAutorises();
    this.config = {
      'bgsColor': '#47BB9C',
      'bgsOpacity': 10,
      'bgsPosition': 'bottom-right',
      'bgsSize': 60,
      'bgsType': 'circle',
      'blur': 100,
      'fgsColor': '#47BB9C',
      'fgsPosition': 'center-center',
      'fgsSize': 90,
      'fgsType': 'three-strings',
      'gap': 47,
      'logoPosition': 'center-center',
      'logoSize': 400,
      'logoUrl': '',
      'masterLoaderId': 'master',
      'overlayBorderRadius': '0',
      'overlayColor': 'rgba(40, 40, 40, 0.8)',
      'pbColor': '#47BB9C',
      'pbDirection': 'ltr',
      'pbThickness': 4,
      'hasProgressBar': true,
      'text': 'Chargement des informations',
      'textColor': '#47BB9C',
      'textPosition': 'center-center',
      'threshold': 500
    };
  }

  ngOnInit() {
    /*console.log('yoyo');
    try {
      this.ngxUiLoaderService.startLoader(this.config.masterLoaderId, '1');
    } catch (e) {
      console.error(e);
    }*/
    if (this.uoParentsDetails.length === 0 && this.uoFilles.length === 0 && this.adresses.length === 0 && this.contacts.length === 0) {
      this.dataUO.tab.forEach((e, i) => {
        if (e.idUniteOrganisationnelle === Number(this.route.snapshot.paramMap.get('id'))) {
          this.dataUO.idx = i;
        }
      });
    }
  }

  dateIsValid(dateDebut: any, dateFin: any): boolean {
    if (dateFin == null) {
      return true;
    }
    if (dateDebut === null) {
      dateDebut = new Date();
    }
    const currentDate = new Date();
    return dateFin === '' || new Date(dateDebut) < currentDate && new Date(dateFin) > currentDate;
  }

  isAutorised(idUniteOrganisationnelle: number) {
    let i = 0;
    let response = false;
    while (i < this._uoAutorises.length || ! response) {
      response = this._uoAutorises[i] === idUniteOrganisationnelle;
      i++;
    }
    return response;
  }

  getMessage(dateFinActivite: any): any {
    return dateFinActivite === null ? 'Active' : 'Inactive';
  }

  checkUoParent() {
    return this.uoParentsDetails.some(e => e.typeRelation === 'Organisationnelle');
  }

  checkUoEnfant() {
    return this.uoFilles.some(e => e.typeRelation === 'Organisationnelle');
  }

  checkOtherRelations() {
    return this.uoParentsDetails.some(e => e.typeRelation !== 'Organisationnelle') ||
      this.uoParentsDetails.some(e => e.typeRelation !== 'Organisationnelle');
  }

  checkRights() {
    const role = localStorage.getItem('roles').split(',');
    return !(role.includes(ERoles.REF_UO_RO));
  }

  isUO_RW(): boolean {
    const role = localStorage.getItem('roles').split(',');
    return  !(role.includes(ERoles.REF_UO_RW));
  }
  isUO_AD(): boolean {
    return this.connexionDroitService.isUO_AD();
  }

  isAdmin() {
    return localStorage.getItem('roles').split(',').includes('Ref_Admin');
  }
  checkCreationRights(): boolean {
    const role = localStorage.getItem('roles').split(',');
    return  (role.includes(ERoles.REF_ADMIN));
  }

  checkRightsMission() {
    const role = localStorage.getItem('roles').split(',');
    return (role.includes(ERoles.REF_MISSION_RW) || role.includes(ERoles.REF_ADMIN));

  }

  getCorrectUO(idUO: number) {
    try {
      this.ngxUiLoaderService.startLoader(this.config.masterLoaderId, '1');
    } catch (e) {
      console.error(e);
    }

    this.dataUO.tab.forEach((e, i) => {
      if (e.idUniteOrganisationnelle === idUO) {
        this.dataUO.idx = i;
      }
    });
    try {
      this.ngxUiLoaderService.stopLoader(this.config.masterLoaderId, '1');
    } catch (e) {
      console.error(e);
    }
  }

  ngAfterViewInit(): void {}
}
