import { TypologieUO } from './TypologieUO.model';
export class UO {
  //public estActive: any;
  constructor(
    public idUniteOrganisationnelle: number,
    public nomCourt: string,
    public nomLong: string,
    public idStatus: number,
    public libelleStatus: string,
    public typologies: TypologieUO[],
    public dateDebut: Date,
    public siret?: string,
    public dateFin?: Date,
    public estActive?: number,
    public statutValidation?: number
  ) {}

  getElementFromString(str : string) : any {
    switch (str) {
      case 'idUniteOrganisationnelle':
        return this.idUniteOrganisationnelle;
      case 'nomCourt':
        return this.nomCourt;
      case 'nomLong':
        return this.nomLong;
      case 'idStatus':
        return this.idStatus;
      case 'libelleStatus':
        return this.libelleStatus;
      case 'typologies':
        return this.typologies;
      case 'siret':
        return this.siret;
      case 'dateDebut':
        return this.dateDebut;
      case 'dateFin':
        return this.dateFin;
      case 'typologies.libelleCourtUO':
        return this.typologies[0].libelleCourtTUO
    }
  }
}
