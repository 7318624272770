import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { Personne } from '../models/Personne.model';
import { Mission } from '../models/Mission.model';
@Injectable({
  providedIn: 'root'
})
export class PersonneService extends GetAndPost {
  missions: Mission[] = [];
  constructor(httpClient: HttpClient) {
    super('personne', httpClient);
  }
  newElement(e: any): any {

    e.missions.forEach(element => {
      this.missions.push(new Mission(element.idMission, element.dateDebutMission, element.idMissionType, element.libelleMissionType, element.dateDebutMT, element.idEquipe, element.dateDebutEq, element.idUO, element.dateFinEQ, element.dateFinMission));
    });
    return new Personne(e.idPersonne, e.identifiantSSO, e.nom, e.prenom, e.adresseMail, e.dateDebut, this.missions, e.dateFin);
  }
}
