import { Component, OnInit, ComponentFactoryResolver} from '@angular/core';
import { MissionType } from '../../models/MissionType.model';
import { MissionTypeService } from '../../services/mission-type.service';
import { ActiviteService } from 'src/app/services/activite.service';
import { ContactService } from 'src/app/services/contact.service';
import { PersonneService } from 'src/app/services/personne.service';
import { AdresseService } from 'src/app/services/adresse.service';
import { MissionTypePossible } from '../../models/MissionTypePossible.model';
import { MissionTypePossibleService } from 'src/app/services/mission-type-possible.service';
import { UOService } from 'src/app/services/uo.service';
import { UO } from '../../models/UO.model';
import { Page, DataUO, DataMission, DataMissionPossible } from '../../models/data.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UOAllService } from 'src/app/services/uoall.service';
import { CacheService } from '../../services/storage/cache.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-interface-principale',
  templateUrl: './interface-principale.component.html',
  styleUrls: ['./interface-principale.component.css']
})
export class InterfacePrincipaleComponent implements OnInit {
  page: Page = Page.mission;
  dataUO: DataUO;
  dataMission: DataMission;
  dataMissionPossible: DataMissionPossible;
  private _config: any;
  UniteOrganisationnelleOnlyActive : UO[];

  constructor(private missionTypeService: MissionTypeService,
    private activiteService: ActiviteService,
    private contactService: ContactService,
    private personneService: PersonneService,
    private route: ActivatedRoute,
    private adresseService: AdresseService,
    private missionTypePossibleService: MissionTypePossibleService,
    private uoService: UOService,
    private uoAllService: UOAllService,
    private ngxUiLoaderService: NgxUiLoaderService,
    protected router: Router,
    private cache: CacheService) {

    this.dataUO = new DataUO();
    this.dataMission = new DataMission();
    this.dataMissionPossible = new DataMissionPossible();
    this.UniteOrganisationnelleOnlyActive = new Array<UO>();
    this._config = {
      'bgsColor': '#47BB9C',
      'bgsOpacity': 10,
      'bgsPosition': 'bottom-right',
      'bgsSize': 60,
      'bgsType': 'circle',
      'blur': 100,
      'fgsColor': '#47BB9C',
      'fgsPosition': 'center-center',
      'fgsSize': 90,
      'fgsType': 'three-strings',
      'gap': 47,
      'logoPosition': 'center-center',
      'logoSize': 400,
      'logoUrl': '',
      'masterLoaderId': 'master',
      'overlayBorderRadius': '0',
      'overlayColor': 'rgba(40, 40, 40, 0.8)',
      'pbColor': '#47BB9C',
      'pbDirection': 'ltr',
      'pbThickness': 4,
      'hasProgressBar': true,
      'text': 'Chargement des informations',
      'textColor': '#47BB9C',
      'textPosition': 'center-center',
      'threshold': 500
    };
  }

  isPageUO() {
    return this.page === Page.UO;
  }

  isNotErreurUO() {
    return !this.dataUO || null === this.dataUO.erreurChargement;
  }

  isModeLecture() {
    return this.router.url.startsWith('/uo') ||
      this.router.url.startsWith('/mission') ||
      this.router.url.startsWith('/missionPossible') ||
      this.router.url.startsWith('/referentiel') ||
      this.router.url.startsWith('/demande');
  }

  ngOnInit() {
    const idParam: number = Number(this.route.snapshot.paramMap.get('id'));
    if (this.router.url === '/mission' ||
      this.router.url === '/creationMissionType' ||
      this.router.url === '/modificationMissionType/' + idParam ||
      this.router.url.startsWith('/mission/details')) {
      this.handleMissionPages();
    } else if (this.router.url.startsWith('/missionPossible') ||
      this.router.url === '/creationMissionPossible' ||
      this.router.url === '/modificationMissionPossible/' + idParam) {
      this.handleMissionPossiblePages();
    } else {
      this.handleUoPages();
    }
  }

  private handleMissionPages() {
    this.dataMission.tabFiltre = [];
    this.dataMission.tab = [];
    // get the element from the cache
    const missionsCached: any[] = JSON.parse(localStorage.getItem('missionsTypes'));
    // set the current page
    this.page = Page.mission;
    // if it doesn't exists
    if (missionsCached == null) {
      this.ngxUiLoaderService.startLoader(this._config.masterLoaderId, 'ipmt');
      this.missionTypeService.dataSubject.subscribe((missions: MissionType[]) => {
        missions.forEach(e => {
          this.dataMission.tab.push(new MissionType(e.idMissionType, e.libelleMissionType, e.dateDebut, e.dateFin));
        });
        this.dataMission.tabFiltre = this.dataMission.tab.slice();
        // set the element to our cache
        localStorage.setItem('missionsTypes', JSON.stringify(this.dataMission.tabFiltre));
        this.ngxUiLoaderService.stopLoader(this._config.masterLoaderId, 'ipmt');
      });
      this.missionTypeService.getWithValue('actif', 'false');
      // the element exists
    } else {
      // get a copy of the cached array
      missionsCached.forEach(e => {
        this.dataMission.tab.push(new MissionType(e.idMissionType, e.libelleMissionType, e.dateDebut, e.dateFin));
      });
      this.dataMission.tabFiltre = [];
      this.dataMission.tabFiltre = this.dataMission.tab.slice();
    }
  }

  private handleUoPages() {
    const uoCached: any[] = JSON.parse(localStorage.getItem('uo'));
    this.dataUO.erreurChargement = localStorage.getItem('uoError');
    this.page = Page.UO;
    if (uoCached == null || !this.isNotErreurUO()) {
      this.dataUO.tab = [];
      this.ngxUiLoaderService.startLoader(this._config.masterLoaderId, 'ipuo');
      localStorage.removeItem('uoError');
      this.uoAllService.dataSubject.subscribe((unitesOrganisationnelles: UO[]) => {
        if (null != this.uoAllService.dataError) {
          localStorage.setItem('uoError', this.dataUO.erreurChargement = this.uoAllService.dataError);
        }
        this.dataUO.tab = unitesOrganisationnelles.slice();
        this.dataUO.tabFiltre = this.dataUO.tab.slice();

        unitesOrganisationnelles = this.dataUO.tab.slice(0,1);
        this.dataUO.tab.forEach(e => {
           if(e.estActive === 1){
             this.UniteOrganisationnelleOnlyActive.push(
              new UO(
                e.idUniteOrganisationnelle,
                e.nomCourt,
                e.nomLong,
                e.idStatus,
                e.libelleStatus,
                e.typologies,
                e.dateDebut,
                e.siret,
                e.dateFin,
                e.estActive,
                e.statutValidation)
             );
           }
        })

        localStorage.setItem('uo', JSON.stringify(this.UniteOrganisationnelleOnlyActive));
        this.ngxUiLoaderService.stopLoader(this._config.masterLoaderId, 'ipuo');
      });
      this.uoAllService.getWithValueAllUO();
      // the element exists
    } else {
      uoCached.forEach(e => {
        if(e.estActive === 1){
        this.dataUO.tab.push(
          new UO(
            e.idUniteOrganisationnelle,
            e.nomCourt,
            e.nomLong,
            e.idStatus,
            e.libelleStatus,
            e.typologies,
            e.dateDebut,
            e.siret,
            e.dateFin));
          }
      });
      this.dataUO.tabFiltre = [];
      this.dataUO.tabFiltre = this.dataUO.tab.slice();
    }
  }

  private handleMissionPossiblePages() {

    const mpCached: any[] = JSON.parse(localStorage.getItem('missionsPossibles'));
    this.page = Page.missionPossible;
    if (mpCached == null) {
      this.ngxUiLoaderService.startLoader(this._config.masterLoaderId, 'ipmp');
      this.missionTypePossibleService.dataSubject.subscribe((missionPossibles: MissionTypePossible[]) => {
        this.dataMissionPossible.tab = missionPossibles.slice();
        this.dataMissionPossible.tabFiltre = this.dataMissionPossible.tab.slice();
        localStorage.setItem('missionsPossibles', JSON.stringify(missionPossibles));
        this.ngxUiLoaderService.stopLoader(this._config.masterLoaderId, 'ipmp');
      });
      this.missionTypePossibleService.getWithValue('actif', 'false');
      // the element exists
    } else {
      mpCached.forEach(e => {
        // Add mission in the mission possible sorted array
        this.dataMissionPossible.tab.push(new MissionTypePossible(
          e.idMissionPossible,
          e.libelleMissionPossible,
          e.idMissionType,
          e.libelleMissionType,
          e.idTypologieUO,
          e.libelleTypologieUOLong,
          e.libelleTypologieUOCourt,
          e.idActivite,
          e.dateDebut,
          e.libelleActivite,
          e.dateFin));
      });
      this.dataMissionPossible.tabFiltre = [];
      this.dataMissionPossible.tabFiltre = this.dataMissionPossible.tab.slice();
    }
  }


  get config(): any {
    return this._config;
  }

  set config(value: any) {
    this._config = value;
  }
}
