import {Component, OnInit} from '@angular/core';
import {version} from 'package.json';
import { StackService } from '../../../services/storage/stack.service';

@Component({
    selector: 'app-infos-compte',
    templateUrl: './infos-compte.component.html',
    styleUrls: ['./infos-compte.component.css']
})
export class InfosCompteComponent implements OnInit {
    name: String;
    entity: String;
    mission: String;
    version: string =version;
    jsonParsedMissions: any[];
    adressip: String;

    // Instanciation des informations liées à l'utilisateur, à terme il faudra stocker ça en localstorage
    constructor(private stackService: StackService) {

        if (localStorage.getItem('nom') !== null) {
            this.name = localStorage.getItem('prenom') !== null ?  localStorage.getItem('prenom') : '';
            this.name += ' ' + localStorage.getItem('nom');
            this.adressip = localStorage.getItem('id')  !== null ?  localStorage.getItem('id') : '';
            const roles = localStorage.getItem('roles').split(',');
            const missionId = localStorage.getItem('missionId');
            if (localStorage.getItem('missions')
                && localStorage.getItem('missions') !== '[]'
                && !roles.includes('Ref_Admin') && localStorage.getItem('missionId')) {
                // get the first mission information
                this.entity = localStorage.getItem('missions') == null ? 'Non connue' :
                    JSON.parse(localStorage.getItem('missions'))[missionId]['UO'];
                this.mission = localStorage.getItem('missions') == null ? 'Non connue' :
                    JSON.parse(localStorage.getItem('missions'))[missionId]['mission'];
                this.jsonParsedMissions = JSON.parse(localStorage.getItem('missions'));
            } else {
                this.entity = '';
                this.mission = 'Admin';
                this.jsonParsedMissions = null;
            }
        }

    }

    ngOnInit() {
    }

    // On remet à 0 toutes les informations de la session lorsqu'on se deconnecte
    logOut() {
        localStorage.clear();

        // Destruction des pages stockees
        while (this.stackService.nbPageSave() > 0) {
          this.stackService.pop();
          this.stackService.clearPoppedSavedPage();
        }
    }
}
