<div class="container-fluid margin-left padding">
    <form *ngIf="missionsType" [formGroup]="missionTypeForm" (ngSubmit)="onSubmitForm()">

        <fieldset class="static">
            <legend class="padding-titre"> Modification d'une Mission Type</legend>
            <div class="form-group col-md-12">
                <div class="form-inline">
                    <label class="col-sm-6">Intitulé mission type</label>
                    <input class="form-control col-sm-6" type="text" formControlName="intitule" required>
                </div>
            </div>

            <div class="form-group col-md-12">
                <div class="form-inline">
                    <label class="col-sm-6">Date de début</label>
                    <input class="form-control col-sm-6" type="date" formControlName="dateDebut" disabled>
                </div>
            </div>

            <div class="form-group col-md-12">
                <div class="form-inline">
                    <label class="col-sm-6">Date de fin</label>
                    <input class="form-control col-sm-6" type="date" formControlName="dateFin">
                </div>
            </div>
        </fieldset>

        <!-- submit button -->
        <button type="submit" class="btn btn-success mb-2 pull-right margin-top-bottom width_200px">Modifier</button>
        <br />
        <label class="label label-danger error">{{this.error}}</label>
    </form>
</div>
