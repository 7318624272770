import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAndPost } from './get-and-post.service';
import { TypologieAdresse } from '../models/TypologieAdresse.model';

@Injectable({
  providedIn: 'root'
})
export class TypologieAdresseService extends GetAndPost {
  /**
   * Constructor of our service
   * @param httpClient
   */
  constructor(httpClient: HttpClient) {
    super('TypologieAdresse', httpClient);
  }

  /**
   * Methode qui sert à ??????????
   * @param e
   */
  newElement(e: any) {
    if ( e === null ) {
      return new TypologieAdresse(null, null);
    }
      return new TypologieAdresse(
        e.idTypoAdresse,
        e.libelleLong);
  }
}
