import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache: Map<string, any> = new Map<string, any>();

  constructor() { }

  has(key: string): boolean {
    return Array.from(this.cache.keys()).includes(key);
  }

  get(key: string): any {
    return this.cache.get(key);
  }

  delete(key: string): void {
    this.cache.delete(key);
  }

  insert(key: string, value: any): void {
    this.cache.set(key, value);
  }
}
