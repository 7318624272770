export class Adresse {
  public isDeleted: boolean;
  public isDisplayed: boolean;
  public isAdressePrincipale: boolean;
  constructor(
    public libelleCourtTypologieAdresse: string,
    public libelleLongTypologieAdresse: string,
    public dateDebut: Date,
    public idLocalisation?: number,
    public valide?: string,
    public dateDebutUOA?: Date,
    public dateFinUOA?: Date,
    public dateDebutTA?: Date,
    public DateFinTA?: Date,
    public ApptEscalier?: string,
    public ComplementGeographique?: string,
    public numVoie?: string,
    public CodePostal?: number,
    public Ville?: string,
    public dateFin?: Date,
    public estConnecte?: number,
    public nbOrdinateur?: number,
    public accesHandicape?: number,
    public pointEau?: number,
    public wc?: number,
    public localPartage?: number,
    public localPrecaire?: number,
    public localTemporaire?: number,
    public surfaceTotale?: number,
    public dateDebutAssurance?: Date,
    public dateFinAssurance?: Date,
    public dateEmmenagement?: Date,
    public dateDemenagement?: Date
  ) {
    this.isDeleted = false;
    this.isAdressePrincipale = this.libelleLongTypologieAdresse === 'Adresse principale';
  }
}
