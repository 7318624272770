<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<header class="header">
  <ul>
    <li>
      <img src="assets/img/Restos_du_coeur_Logo.svg.png" id="logo">
    </li>
    <li>
    <h1 class="margin_x" [style.color]="'#120101'">  Référentiel des Restos du Cœur </h1>
    </li>

    <li *ngIf="isLogged()">
      <app-infos-compte></app-infos-compte>
    </li>
  </ul>
</header>

<app-menu *ngIf="isLogged()"></app-menu>
