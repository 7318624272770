<div class="container-fluid margin-left padding" ngxUiLoaderBlurred>
    <h3 class="margin-top-bottom">Création mission possible</h3>
    <div class="rechercher">
        <form [formGroup]="postMissionForm"  >
            <!-- Formulaire post mission possible -->
            <div class="static">
                <div class="row">
                    <div class="col">
                        <div class="form-inline margin-bottom">
                            <label class="col-sm-6">Libellé mission possible</label>

                            <input class="form-control width_100 col-sm-6" type="text" name="libelleMissionPossible"
                                   formControlName="libelleMissionPossible" >
                        </div>
                        <div class="form-inline margin-bottom" *ngIf="libelleMissionPossible.invalid && submitted" >
                            <label *ngIf="libelleMissionPossible.errors.required" class="col-sm text-danger">&nbsp;
                              Libellé mission possible est requis
                            </label>
                        </div>
                        <div class="form-inline margin-bottom">
                            <label class="col-sm-6">Mission type</label>
                            <select class="form-control col-sm-6" name="idMissionType" formControlName="idMissionType">
                                <option *ngFor="let mission of distinctMissionType;index as i"
                                        value="{{mission.idMissionType}}">
                                    {{mission.libelleMissionType}}
                                </option>
                            </select>
                        </div>
                      <div *ngIf="idMissionType.invalid && submitted" class="form-inline margin-bottom">
                        <label  *ngIf="idMissionType.errors.required" class="col-sm text-danger" >Mission type est requis</label>
                      </div>
                        <div class="form-inline margin-bottom">
                            <label class="col-sm-6">Typologie UO</label>
                            <select class="form-control col-sm-6" name="idTypologieUO" formControlName="idTypologieUO">
                                <option *ngFor="let typologieUo of typologiesUo" [value]="typologieUo.idTypologieUo">
                                    {{typologieUo.libelleLongTUO}}
                                </option>
                            </select>
                        </div>
                      <div *ngIf="idTypologieUO.invalid && submitted" class="form-inline margin-bottom">
                        <label *ngIf="idTypologieUO.errors.required" class="col-sm text-danger">
                          Mission type est requis
                        </label>
                      </div>
                        <div class="form-inline margin-bottom">
                            <label class="col-sm-6">Activité</label>
                            <select class="form-control col-sm-6" name="act" formControlName="idActivite">
                                <ng-container *ngFor="let activite of sortedActivities; let index=index;">
                                    <!-- this container is the beginning of the recursive display-->
                                    <ng-container>
                                        <!-- our element is a leaf -->
                                        <ng-container *ngIf="activite.sonsActivity.length === 0; else optgroupAct">
                                            <!-- we just display it -->
                                            <option value="{{activite.idActivite}}"  [hidden]="activite.dateFinActivite != null">{{ activite.libelleLong}}
                                            </option>
                                        </ng-container>

                                        <ng-template #optgroupAct>
                                            <!-- our element is a branch -->
                                            <option value="{{activite.idActivite}}"  [hidden]="activite.dateFinActivite != null">{{activite.libelleLong}}</option>
                                            <!-- recursive call -->
                                            <ng-container
                                                    *ngTemplateOutlet="recursiveList; context:{ $implicit: activite.sonsActivity, index: 1}">
                                            </ng-container>
                                        </ng-template>
                                    </ng-container>

                                    <!-- Recursive content-->
                                    <ng-template #recursiveList let-list let-index="index">
                                        <ng-container>
                                            <!-- foreach sons -->
                                            <ng-container *ngFor="let item of list">
                                                <!-- if it contains or one of it sons can be mapped with the activity-->
                                                <ng-container>
                                                    <!-- the element contains childs -->
                                                    <ng-container
                                                            *ngIf="item.sonsActivity.length !== 0; else optgroupActSon">
                                                        <option value="{{item.idActivite}}">{{getTab(index) + item.libelleLong}}</option>
                                                        <ng-container
                                                                *ngTemplateOutlet="recursiveList; context:{ $implicit: item.sonsActivity, index: index + 1 }"></ng-container>
                                                    </ng-container>
                                                    <ng-template #optgroupActSon>
                                                        <option value="{{item.idActivite}}" [hidden]="item.dateFinActivite != null">
                                                            {{ getTab(index) + item.libelleLong }}
                                                        </option>
                                                    </ng-template>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                </ng-container>
                                <!--</ng-container>-->
                            </select>
                            <!--
              <select class="form-control mr-sm-2" name="idActivite" formControlName="idActivite">
                <optgroup *ngFor="let allAct of activitesAll;let index = index;" label="{{index+1}}">
                  <option *ngFor="let activite of allAct.allActivites" [value]="activite.idActivite">
                    {{activite.libelleLong}}
                  </option>
                </optgroup>
              </select>
              -->
                        </div>
                      <div *ngIf="idActivite.invalid && submitted" class="form-inline margin-bottom">
                        <label *ngIf="idActivite.errors.required" class="col-sm text-danger">
                          Activité est requis
                        </label>
                      </div>
                        <div class="form-inline margin-bottom">
                            <!--<label class="col-sm-6">Date de début</label>-->
                            <input class="form-control col-sm-6" type="hidden" name="dateDebut"
                                   formControlName="dateDebut" readonly>
                        </div>
                      <!--
                        <div class="form-inline margin-bottom">
                            <label class="col-sm-6">Date de fin</label>
                            <input class="form-control col-sm-6" type="date" name="dateFin" formControlName="dateFin">
                        </div>
                        -->
                    </div>
                </div>
            </div>
            <button type="button" [disabled]="postMissionForm.invalid && submitted" (click)="onSubmit()"
                    class="btn btn-success  margin-top-bottom width_200px">
                <span class="fa fa-plus"></span>
                Créer
            </button>
            <div class="error">
                <label class="text-danger">{{ errorMessage }}</label>
            </div>
        </form>
    </div>
</div>
