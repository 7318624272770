export class MissionTypePossible {

  constructor(  
    public idMissionPossible : number,
    public libelleMissionPossible : string,

    public idMissionType : number,
    public libelleMissionType : string,
    
    public idTypologieUO : number,
    public libelleTypologieUOLong : string,
 	public libelleTypologieUOCourt : string,   

    public idActivite : number,
    
    public dateDebut : Date,
    
    public libelleActivite? : string,
    public dateFin? : Date
  ) { }

  getElementFromString(str: string): any {
    switch (str) {
      case 'idMissionPossible':
        return this.idMissionPossible;
      case 'libelleMissionPossible':
        return this.libelleMissionPossible;
      case 'idMissionType':
        return this.idMissionType;
      case 'libelleMissionType':
        return this.libelleMissionType;
      case 'idTypologieUO':
        return this.idTypologieUO;
      case 'libelleTypologieUOLong':
        return this.libelleTypologieUOLong;
	  case 'libelleTypologieUOCourt':
        return this.libelleTypologieUOCourt;
      case 'idActivite':
        return this.idActivite;
      case 'dateDebut':
        return this.dateDebut;
      case 'libelleActivite':
        return this.libelleActivite;
      case 'dateFin':
        return this.dateFin;
    }
  }
} 
