<div class="compteInfo">
    <dl>
        <dt [ngStyle]="{'color':'#E5007D'}">Connecté en tant que :</dt>
        <dd [ngStyle]="{'color':'#1A1515'}">{{name}}</dd>
        <dt [ngStyle]="{'color':'#E5007D'}">Entité :</dt>
        <dd [ngStyle]="{'color':'#1A1515'}">{{entity}}</dd>
        <dt [ngStyle]="{'color':'#E5007D'}">Mission :</dt>
        <dd [ngStyle]="{'color':'#1A1515'}">{{mission}}</dd>
    </dl>
    <div class="margin-padding">
        <a routerLink="/" routerLinkActive="on" (click)="logOut()">
            <dt>
                Deconnexion
                <span class="fa fa-sign-out"></span>
            </dt>
        </a>
        <span class="align-content-lg-end">version: {{version}} - Serveur : {{adressip}}</span>
    </div>
</div>
